import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appElementOnscreen]'
})
export class ElementOnscreenDirective implements OnInit, OnDestroy {
  private _intersectionObserver: IntersectionObserver;

  @HostBinding('attr.isonscreen')
  private _isIntersecting: boolean = undefined;

  @HostBinding('attr.screenpos')
  private _screenPos: string;

  @Output()
  public screenposChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly elm: ElementRef<HTMLElement>,
    ngZone: NgZone,
    private readonly changeRef: ChangeDetectorRef
  ) {

  }
  ngOnDestroy(): void {
    this._intersectionObserver?.disconnect();
  }

  ngOnInit() {
    setTimeout(() => {
      this._intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        const viewArea = entries[0].rootBounds;
        const itemBounds = entries[0].boundingClientRect;
        let pos = 'on';
        if (!entries[0].isIntersecting) {
          if (itemBounds.y > viewArea.bottom) {
            pos = 'below';
          } else if (itemBounds.bottom < viewArea.top) {
            pos = 'above';
          }
        }

        this.onIntersectChange(entries[0].isIntersecting, pos);
      }, {});
      this._intersectionObserver.observe(this.elm.nativeElement);
    });
  }

  private onIntersectChange(intersects: boolean, pos: string) {
    this._isIntersecting = intersects;
    this._screenPos = pos;
    this.screenposChange.emit(pos);
    this.changeRef.markForCheck();
  }

}
