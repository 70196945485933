import { Injectable, OnDestroy } from '@angular/core';
import humanize from 'humanize-duration';
import moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ITradeShowModel } from "../data/models/ITradeShowModel";
import { TradeShowService } from './trade-show.service';

const clock = interval(1000).pipe(
  map(tick => new Date()),
  share()
)

@Injectable({
  providedIn: 'root'
})
export class TradeshowHoursService implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  private readonly _tick$ = interval(500);
  private readonly _tradeShowData$ = this.tradeShowService.tradeShow$;

  private readonly _tradeShowOverrides$ = new BehaviorSubject<Partial<ITradeShowModel>>({});

  private readonly _tradeShow$ = combineLatest([this._tradeShowData$, this._tradeShowOverrides$]).pipe(
    map(([tradeShow, overrides]) => {
      return {
        ...tradeShow,
        ...overrides
      }
    }),
    shareReplay(1)
  );

  private readonly _timings$ = combineLatest([this._tradeShow$, this._tick$]).pipe(
    map(([tradeShow, tick]) => {
      const start = moment(tradeShow.startDate);
      const end = moment(tradeShow.endDate);
      const startRewards = moment(tradeShow.rewardsOpenDate);
      const endRewards = moment(tradeShow.rewardsCloseDate);

      const timeToStart = start.diff(moment(), 'milliseconds');
      const timeToEnd = end.diff(moment(), 'milliseconds');

      const timeToStartRewards = startRewards.diff(moment(), 'milliseconds');
      const timeToEndRewards = endRewards.diff(moment(), 'milliseconds');

      const timeToStartString = humanize(timeToStart, { largest: 2, round: true });
      const timeToEndString = humanize(timeToEnd, { largest: 2, round: true });

      const timeToStartRewardsString = humanize(timeToStartRewards, { largest: 2, round: true });
      const timeToEndRewardsString = humanize(timeToEndRewards, { largest: 2, round: true });

      return {
        start,
        end,
        startRewards,
        endRewards,
        timeToStart,
        timeToEnd,
        timeToStartRewards,
        timeToEndRewards,
        timeToStartString,
        timeToEndString,
        timeToStartRewardsString,
        timeToEndRewardsString
      }
    }),
    shareReplay(1)
  );

  public readonly showOpens$ = this._timings$.pipe(
    map(timings => {
      return timings.start;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly showCloses$ = this._timings$.pipe(
    map(timings => {
      return timings.end;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly rewardsOpens$ = this._timings$.pipe(
    map(timings => {
      return timings.startRewards;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );


  public readonly rewardsCloses$ = this._timings$.pipe(
    map(timings => {
      return timings.endRewards;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );


  public readonly timeToOpen$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly timeToOpenString$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly timeToClose$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEndString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly timeToCloseString$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEndString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );


  public readonly timeToOpenRewards$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartRewardsString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly timeToOpenRewardsString$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartRewardsString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );


  public readonly timeToCloseRewards$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEndRewardsString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly timeToCloseRewardsString$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEndRewardsString;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isOpen$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStart <= 0 && timings.timeToEnd >= 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isRewardsOpen$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartRewards <= 0 && timings.timeToEndRewards >= 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isBeforeStart$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStart > 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isAfterEnd$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEnd < 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isBeforeStartRewards$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToStartRewards > 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly isAfterEndRewards$ = this._timings$.pipe(
    map(timings => {
      return timings.timeToEndRewards < 0;
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly showState$ = this._timings$.pipe(
    map(timings => {
      if (timings.timeToStart > 0) {
        return 'before' as const;
      } else if (timings.timeToEnd < (5 * 60 * 1000)) {
        return 'closing' as const;
      } else if (timings.timeToEnd < 0) {
        return 'closed' as const;
      } else {
        return 'open' as const;
      }
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  public readonly rewardsState$ = this._timings$.pipe(
    map(timings => {
      if (timings.timeToStartRewards > 0) {
        return 'before' as const;
      } else if (timings.timeToEndRewards < (5 * 60 * 1000)) {
        return 'closing' as const;
      } else if (timings.timeToEndRewards < 0) {
        return 'closed' as const;
      } else {
        return 'open' as const;
      }
    }),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntil(this._destroyed$)
  );

  constructor(
    private readonly tradeShowService: TradeShowService
  ) {
    // this._tradeShow$.subscribe();
  }


  public setMockTimings() {
    this._tradeShowOverrides$.next({
      startDate: moment().add(1, 'minute').toISOString(),
      endDate: moment().add(11, 'minutes').toISOString(),
      rewardsOpenDate: moment().add(1, 'minute').toISOString(),
      rewardsCloseDate: moment().add(11, 'minutes').toISOString()
    });
  }

  public unlockTimings() {
    this._tradeShowOverrides$.next({
      startDate: moment().subtract(10, 'minute').toISOString(),
      endDate: moment().add(50, 'hours').toISOString(),
      rewardsOpenDate: moment().subtract(10, 'minute').toISOString(),
      rewardsCloseDate: moment().add(50, 'hours').toISOString()
    });
  }

  public get showTradeshowTimingMockButton(): boolean {
    return environment.showTradeshowTimingMockButton;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
  }








  /*

    private readonly _start$ = this._tradeShow$.pipe(
      map(tradeShow => moment(tradeShow.startDate)),
      tap(start => this._start.next(start)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    private readonly _end$ = this._tradeShow$.pipe(
      map(tradeShow => moment(tradeShow.endDate)),
      tap(end => this._end.next(end)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    private readonly _startRewards$ = this._tradeShow$.pipe(
      map(tradeShow => moment(tradeShow.rewardsOpenDate)),
      tap(startRewards => this._startRewards.next(startRewards)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    private readonly _endRewards$ = this._tradeShow$.pipe(
      map(tradeShow => moment(tradeShow.rewardsCloseDate)),
      tap(endRewards => this._endRewards.next(endRewards)),
      distinctUntilChanged(),
      shareReplay(1)
    );

  */


  /*
  private readonly _timeToStart$ = this._start$.pipe(
    map(start => start.diff(moment(), 'milliseconds')),
  );

  private readonly _timeToEnd$ = this._end$.pipe(
    map(end => end.diff(moment(), 'milliseconds')),
  );

  private readonly _timeToStartRewards$ = this._startRewards$.pipe(
    map(startRewards => startRewards.diff(moment(), 'milliseconds')),
  );

  private readonly _timeToEndRewards$ = this._endRewards$.pipe(
    map(endRewards => endRewards.diff(moment(), 'milliseconds')),
  );
  */






  // private readonly _start: BehaviorSubject<Moment | undefined> = new BehaviorSubject(undefined);
  // private readonly _end: BehaviorSubject<Moment | undefined> = new BehaviorSubject(undefined);

  // private readonly _startRewards: BehaviorSubject<Moment | undefined> = new BehaviorSubject(undefined);
  // private readonly _endRewards: BehaviorSubject<Moment | undefined> = new BehaviorSubject(undefined);







  // private _start: Moment;
  // private _end: Moment;

  // private _coundownObs = interval(1000).pipe(startWith(0)).pipe(
  //   map(tick => this.msToOpen),
  //   share()
  // );

  // private _coolDownSubject: BehaviorSubject<number>;

  // constructor(
  //   private readonly currentTradeShow: CurrentTradeShowService
  // ) {

  //   this._start = moment(environment.tradeShowStarts);
  //   this._end = moment(environment.tradeShowEnds);
  //   this._coolDownSubject = new BehaviorSubject(this.msToOpen);
  //   this._coundownObs.subscribe(this._coolDownSubject);
  // }


  // private get startTime(): Moment {
  //   return this._start;
  // }

  // private get endTime(): Moment {
  //   return this._end;
  // }

  // public get isOpen(): boolean {
  //   return this.msToOpen < 0;
  // }

  // public get state(): 'before' | 'closed' | 'closesoon' | 'open' {
  //   const ms = this.msToOpen;
  //   if (ms > 0) {
  //     return 'before';
  //   } else if (ms === 0) {
  //     return 'closed';
  //   } else if (ms > (-5 * 60 * 1000)) {
  //     return 'closesoon';
  //   }
  //   return 'open';
  // }

  // public get msToOpen(): number {
  //   const now = moment();
  //   if (now.isBefore(this.startTime)) {
  //     return this.startTime.diff(now, 'milliseconds');
  //   } else if (now.isAfter(this.endTime)) {
  //     return 0;
  //   } else {
  //     return now.diff(this.endTime, 'milliseconds');
  //   }
  // }

  // public get timeToOpen(): string {
  //   return humanize(this.msToOpen, { maxDecimalPoints: 0, largest: 2 });
  // }

  // public get countdown(): Observable<number> {
  //   return this._coolDownSubject;
  // }

  // public setMockTimings() {
  //   this._start = moment().add(1, 'minute');
  //   this._end = moment().add(7, 'minutes');
  // }

  // public unlockTimings() {
  //   this._start = moment().subtract(10, 'minute');
  //   this._end = moment().add(50, 'hours');
  // }



}
