import {
  Directive, TemplateRef, ViewContainerRef, OnInit, Renderer2,
  Input, ElementRef, EventEmitter, Output, OnDestroy
} from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';


export interface DialogOptions {
  closeOnClickOutside?: boolean;
  createOnInit?: boolean;
  createOnAttach?: boolean;
  show?: boolean;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

@Directive({
  selector: '[ngwcDialog]',
  exportAs: 'dialog'
})
export class DialogDirective implements OnInit, OnDestroy {




  private _panelRef: OverlayRef;
  private _isOpen: boolean = false;
  private _attachTo: ElementRef | HTMLElement;
  // tslint:disable-next-line:no-input-rename
  @Input('ngwcDialog')
  private dialogOptions: DialogOptions;

  @Input()
  private get attachTo(): ElementRef | HTMLElement {
    return this._attachTo;
  }
  private set attachTo(value: ElementRef | HTMLElement) {
    this._attachTo = value;
    if (value && this.dialogOptions.createOnAttach) {
      this.create(value);
    }
  }

  @Output()
  public openChange: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private overlayService: Overlay,
    private container: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private renderer: Renderer2
  ) {
    document.addEventListener('click', event => {
      this.onClickOutside(event);
    });

  }

  private get attachElm(): HTMLElement {
    if (!this.attachTo) {
      return undefined;
    }
    if (this.attachTo instanceof HTMLElement) {
      return this.attachTo;
    }
    return this.attachTo.nativeElement;
  }

  public onClickOutside(event: MouseEvent | TouchEvent) {
    if (this._isOpen && this.dialogOptions.closeOnClickOutside !== false) {
      if (!this._panelRef.overlayElement.contains(<HTMLElement>event.target)) {
        if (!this.attachElm || !this.attachElm.contains(<HTMLElement>event.target)) {
          this.close();
        }
      }
    }
  }
  ngOnInit(): void {
    const opts = this.dialogOptions;
    if (opts.createOnInit) {
      this.create();
    }

  }

  ngOnDestroy(): void {
    this.openChange.emit(false);
    if (this._panelRef) {
      this._panelRef.dispose();
    }
  }

  private create(attach?: ElementRef | HTMLElement): void {
    if (!this._panelRef) {
      const opts = this.dialogOptions;
      let positionStrategy: PositionStrategy;
      if (!attach) {
        positionStrategy = this.overlayService.position().global().centerHorizontally().centerVertically();
      } else {
        positionStrategy = this.overlayService.position().flexibleConnectedTo(attach).withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            weight: 10
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            weight: 9
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            weight: 8
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            weight: 7
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            weight: 6
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            weight: 5
          }
        ]);
      }
      const cfg: OverlayConfig = Object.assign(<OverlayConfig>{
      }, this.dialogOptions || {}, <OverlayConfig>{
        positionStrategy: positionStrategy,
        width: 'auto'
      });

      this._panelRef = this.overlayService.create(cfg);
      const portal = new TemplatePortal(this.templateRef, this.container, { $implicit: {} });
      this._panelRef.attach(portal);
      this._panelRef.overlayElement.style.visibility = 'hidden';
      if (opts.show) {
        this.open();
      }
    }
  }

  public open(): void {
    this.create();
    if (!this._isOpen) {
      this._panelRef.overlayElement.style.visibility = 'visible';
      this._panelRef.updatePosition();
      setTimeout(() => {
        console.log('this._panelRef.overlayElement', this._panelRef.hostElement);
        this._panelRef.hostElement.style.width = 'auto';
      }, 1);
      //console.log(this._panelRef.overlayElement, this._panelRef.hostElement)
      this._isOpen = true;
      this.openChange.emit(true);
    }
  }

  public close(): void {
    if (this._isOpen) {
      if (this._panelRef) {
        this._panelRef.overlayElement.style.visibility = 'hidden';
      }
      this._isOpen = false;
      this.openChange.emit(false);
    }
  }

  public toggle(): void {
    if (this._isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}
