import { ISimpleTypeConverter } from '../ISimpleTypeConverter';

class SimpleStringTypeConverterUtil {
    private static isFalsy(source: any): boolean {
        return !source;
    }

    public static toNumber(source: string | number): number {
        if (SimpleStringTypeConverterUtil.isFalsy(source)) {
            return 0;
        }
        return parseFloat(SimpleStringTypeConverterUtil.toString(source));
    }

    public static toInteger(source: string | number): number {
        if (SimpleStringTypeConverterUtil.isFalsy(source)) {
            return 0;
        }
        return parseInt(SimpleStringTypeConverterUtil.toString(source), 10);
    }

    public static toBoolean(source: string | boolean): boolean {
        if (SimpleStringTypeConverterUtil.isFalsy(source)) {
            return false;
        }
        source = SimpleStringTypeConverterUtil.toString(source).toLowerCase();
        switch (source) {
            case '':
            case '0':
            case 'false':
                return false;
            default:
                return true;
        }
    }

    public static toString(source: string | any): string {
        if (source === undefined || source === null) {
            return '';
        }
        return '' + source;
    }

}

export const SimpleStringTypeConverter: ISimpleTypeConverter<string> = SimpleStringTypeConverterUtil;
