import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthenticationService } from '../service/index';
import { NotAuthenticatedGuard } from './not-authenticated.guard';
import { DEFAULT_PAGE } from '../token';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanLoad, CanActivateChild {

    constructor(
        private isNotAuthenticatedGuard: NotAuthenticatedGuard,
        private authService: AuthenticationService,
        private router: Router,
        @Inject(DEFAULT_PAGE) private readonly defaultPage
    ) {

    }
    public async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (await this.isNotAuthenticatedGuard.canActivate(next, state)) {
            return true;
        }
        this.redirect();
        return false;
    }

    public async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (await this.isNotAuthenticatedGuard.canActivateChild(childRoute, state)) {
            return true;
        }
        this.redirect();
        return false;
    }

    public async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        if (await this.isNotAuthenticatedGuard.canLoad(route)) {
            return true;
        }
        this.redirect();
        return false;
    }

    private redirect(): void {
        const nav = this.router.getCurrentNavigation();
        const redirect = nav?.extractedUrl.queryParams.redirect || this.defaultPage;
        const currentUrl = this.router.getCurrentNavigation().initialUrl.toString()
        if (redirect !== currentUrl) {
            this.router.navigateByUrl(redirect);
        }
    }

}
