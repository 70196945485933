
import { Input, HostBinding, Output, ElementRef, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, Renderer2, RendererStyleFlags2, ViewChildren, QueryList, Directive } from '@angular/core';
import { BooleanValue } from '../../tscore/type-conversion/string/decorators';
import { ResizeSensor } from 'css-element-queries';


interface IResizeDetect {
    sensor: ResizeSensor;
    elm: ElementRef<HTMLElement>;
    detector: HTMLElement;
}

export declare type ConstraintAlignment = 'default' | 'true' | 'start' | 'center' | 'end' | 'none';
export enum ConstraintAlignments {
    DEFAULT = 'default',
    START = 'start',
    CENTER = 'center',
    END = 'end'
}
@Directive()
export abstract class ContainerComponentBase {

    private _showHeader: boolean = true;
    private _stickyHeader: boolean = false;
    private _showFooter: boolean = false;
    private _stickyFooter: boolean = false;
    private _constraintAlign: ConstraintAlignment;
    private _constrainHeader: boolean = false;
    private _constrainFooter: boolean = false;

    @Input()
    @HostBinding('attr.showHeader')
    @BooleanValue(true)
    public get showHeader(): boolean {
        return this._showHeader;
    }
    public set showHeader(value: boolean) {
        this._showHeader = value;
    }

    @Input()
    @BooleanValue(true)
    @HostBinding('attr.stickyHeader')
    public get stickyHeader(): boolean {
        return this._stickyHeader;
    }
    public set stickyHeader(value: boolean) {
        this._stickyHeader = value;
    }

    @Input()
    @HostBinding('attr.showFooter')
    @BooleanValue(true)
    public get showFooter(): boolean {
        return this._showFooter;
    }
    public set showFooter(value: boolean) {
        this._showFooter = value;
    }

    @Input()
    @HostBinding('attr.stickyFooter')
    @BooleanValue(true)
    public get stickyFooter(): boolean {
        return this._stickyFooter;
    }
    public set stickyFooter(value: boolean) {
        this._stickyFooter = value;
    }

    @Input()
    @HostBinding('attr.constrainHeader')
    @BooleanValue(true)
    public get constrainHeader(): boolean {
        return this._constrainHeader;
    }
    public set constrainHeader(value: boolean) {
        this._constrainHeader = value;
    }

    @Input()
    @HostBinding('attr.constrainFooter')
    @BooleanValue(true)
    public get constrainFooter(): boolean {
        return this._constrainFooter;
    }
    public set constrainFooter(value: boolean) {
        this._constrainFooter = value;
    }

    @Input()
    @HostBinding('attr.constraintAlign')
    public set constraintAlign(value: ConstraintAlignment) {
        this._constraintAlign = value;
    }

    public get constraintAlign(): ConstraintAlignment {
        if (!this._constraintAlign && this.parentContainer) {
            return this.parentContainer.constraintAlign;
        }
        return this._constraintAlign;
    }

    public constructor(
        protected elm: ElementRef<HTMLElement>,
        protected renderer: Renderer2,
        protected parentContainer: ContainerComponentBase,
    ) {
        const parent = this.renderer.parentNode(this.elm.nativeElement);
        this.renderer.setStyle(
            parent,
            'flex',
            '1 0 auto',
            RendererStyleFlags2.Important
        );
        this.renderer.setStyle(
            parent,
            'display',
            'flex',
            RendererStyleFlags2.Important
        );
        this.renderer.setStyle(
            parent,
            'flex-direction',
            'column',
            RendererStyleFlags2.Important
        );
    }

}

