import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BreadCrumbNavModule, MenuModule, PageModule } from '@nfc-authority/angular-web-components';
import { ElementOnscreenDirective } from './element-onscreen/element-onscreen.directive';
import { ImageCardComponent } from './image-card/image-card.component';
import { ScrollAnchorContainerDirective } from './scroll-anchor-container/scroll-anchor-container.directive';
import { ScrollAnchorDirective } from './scroll-anchor/scroll-anchor.directive';
import { TradeshowStatusComponent } from './tradeshow-status/tradeshow-status.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTabsModule,
    PageModule,
    MenuModule,
    BreadCrumbNavModule,
    MatBadgeModule,
    MatSelectModule,
    ObserversModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTabsModule,
    PageModule,
    MenuModule,
    BreadCrumbNavModule,
    MatBadgeModule,
    ElementOnscreenDirective,
    MatSelectModule,
    ImageCardComponent,
    ScrollAnchorContainerDirective,
    ScrollAnchorDirective,
    TradeshowStatusComponent,
  ],
  declarations: [ElementOnscreenDirective, ImageCardComponent, ScrollAnchorContainerDirective, ScrollAnchorDirective, TradeshowStatusComponent]
})
export class SharedModule { }
