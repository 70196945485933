
import { IAuthenticationProvider } from '../IAuthenicationProvider';
import { CancelablePromise, CancelationToken } from '@nfc-authority/ts-core';
import { IAuthenticatedUser } from '../model/IAuthenticatedUser';

export abstract class BaseAuthenticationProvider<TUser extends IAuthenticatedUser> implements IAuthenticationProvider<TUser> {
    abstract providerId: string;
    abstract providerName: string;
    abstract type: string;
    abstract config: any;
    private _authenticatedUser: TUser;



    private _authPromise: CancelablePromise<void>;
    private _authCancel: CancelationToken;
    private _authResolve: () => void;
    private _authReject: (err: any) => void;

    public get authenticatedUser(): TUser {
        return this._authenticatedUser;
    }

    protected setAuthenticatedUser(user: TUser) {
        this._authenticatedUser = user;
        if (this._authPromise) {
            this._authResolve();
            this._authPromise = undefined;
            this._authResolve = undefined;
            this._authReject = undefined;
        }
    }

    protected get authCancelToken(): CancelationToken {
        return this._authCancel;
    }

    authenticate(cancelationToken: CancelationToken): CancelablePromise<void> {
        if (!this._authPromise) {
            this._authCancel = cancelationToken;
            this._authPromise = new CancelablePromise((res, rej) => {
                this._authResolve = res;
                this._authReject = rej;
                return () => {
                    this._authPromise = undefined;
                    this._authResolve = undefined;
                    this._authReject = undefined;
                };
            }, cancelationToken);
        }
        return this._authPromise;
    }



    logout(): Promise<void> {
        throw new Error('Method not implemented.');
    }
}
