import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TradeshowHoursService } from '../tradeshow-hours.service';

@Component({
  selector: 'app-tradeshow-status',
  templateUrl: './tradeshow-status.component.html',
  styleUrls: ['./tradeshow-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TradeshowStatusComponent {
  public readonly state$ = this.tradeshowHoursService.showState$;
  public readonly timeToOpenString$ = this.tradeshowHoursService.timeToOpenString$;
  public readonly timeToCloseString$ = this.tradeshowHoursService.timeToCloseString$;

  constructor(
    private readonly tradeshowHoursService: TradeshowHoursService
  ) { }
}
