import { Component, OnInit, Input } from '@angular/core';


export interface IBreadcrumNavItem{
  routerLink: string | string[];
  icon?: string;
  label?: string;
}

@Component({
  selector: 'ngwc-bread-crumb-nav',
  templateUrl: './bread-crumb-nav.component.html',
  styleUrls: ['./bread-crumb-nav.component.scss']
})
export class BreadCrumbNavComponent {

  @Input()
  public items: IBreadcrumNavItem[] = [];

  constructor() { }

}
