import { Directive, Input, ElementRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { StickyService } from './sticky.service';
import { ElementResizeObservable } from '../resize/ElementResizeObservable';
import { Subscription } from 'rxjs';

export enum StickyLocation {
  NONE = 'none',
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

@Directive({
  selector: '[ngwcSticky]',
  exportAs: 'ngwcSticky'
})
export class StickyDirective implements OnInit, OnDestroy {

  private _stickyLocation: StickyLocation = StickyLocation.NONE;
  private _stickyChildren: boolean = false;
  private _stickyIndex: number = 0;
  private _stickyOffset: number = 0;
  private _sizeChangedSub: Subscription;
  private _stickyIgnoreSize: boolean = false;

  @Output()
  public ngwcStickyChange: EventEmitter<StickyLocation> = new EventEmitter();
  @Input()
  public get ngwcSticky(): StickyLocation {
    return this._stickyLocation;
  }
  public set ngwcSticky(value: StickyLocation) {
    if (this._stickyLocation !== value) {
      this._stickyLocation = value;
      this.ngwcStickyChange.emit(value);
    }
  }

  @Output()
  public stickyChildrenChange: EventEmitter<boolean> = new EventEmitter();
  @Input()
  public get stickyChildren(): boolean {
    return this._stickyChildren;
  }
  public set stickyChildren(value: boolean) {
    if (this._stickyChildren !== value) {
      this._stickyChildren = value;
      this.stickyChildrenChange.emit(value);
    }
  }

  @Output()
  public stickyIndexChange: EventEmitter<number> = new EventEmitter();
  @Input()
  public get stickyIndex(): number {
    return this._stickyIndex;
  }
  public set stickyIndex(value: number) {
    if (this._stickyIndex !== value) {
      this._stickyIndex = value;
      this.stickyIndexChange.emit(value);
    }
  }

  @Output()
  public stickySizeChange: EventEmitter<{ width: number, height: number }> = new EventEmitter();

  public get size(): { width: number, height: number } {
    if (this.stickyIgnoreSize) {
      return { width: 0, height: 0 };
    }
    return {
      width: this.elementRef.nativeElement.offsetWidth,
      height: this.elementRef.nativeElement.offsetHeight
    };
  }

  @Output()
  public stickyOffsetChange: EventEmitter<number> = new EventEmitter();
  @Input()
  public get stickyOffset(): number {
    return this._stickyOffset;
  }
  public set stickyOffset(value: number) {
    if (this._stickyOffset !== value) {
      this._stickyOffset = value;
      this.stickyOffsetChange.emit(value);
    }
  }

  @Output()
  public stickyIgnoreSizeChange: EventEmitter<boolean> = new EventEmitter();
  @Input()
  public get stickyIgnoreSize(): boolean {
    return this._stickyIgnoreSize;
  }
  public set stickyIgnoreSize(value: boolean) {
    if (this._stickyIgnoreSize !== value) {
      this._stickyIgnoreSize = value;
      this.stickyIgnoreSizeChange.emit(value);
    }
  }
  constructor(
    private stickyService: StickyService,
    public elementRef: ElementRef<HTMLElement>
  ) {


  }

  public applyStickyValue(value: number) {
    if (!this.stickyChildren) {
      this.elementRef.nativeElement.style.position = 'sticky';
      this.elementRef.nativeElement.style[this.ngwcSticky] = value + 'px';
    } else {
      for (let i = 0; i < this.elementRef.nativeElement.childElementCount; i++) {
        const element = <HTMLElement>this.elementRef.nativeElement.children.item(i);
        element.style.position = 'sticky';
        element.style[this.ngwcSticky] = value + 'px';
      }
    }
  }

  ngOnInit(): void {
    this.stickyService.addSticky(this);
    this._sizeChangedSub = new ElementResizeObservable(this.elementRef).subscribe(() => {
      if (!this.stickyIgnoreSize) {
        this.stickySizeChange.emit(this.size);
      }
    });
  }

  ngOnDestroy(): void {
    this.stickyService.removeSticky(this);
    if (this._sizeChangedSub) {
      this._sizeChangedSub.unsubscribe();
    }
  }

}
