import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAlertData } from '../../data/index';
import { NgForm, FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'ngwc-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
    public constructor(
        private dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAlertData
    ) {

    }

    public get title(): string {
        return this.data.title;
    }

    public get message(): string {
        return this.data.message;
    }

    public get options(): any {
        return this.data.options;
    }

    public onSubmit(id: string) {
        this.dialogRef.close(id);
    }

}
