import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCardComponent implements OnChanges, AfterViewInit {

  private _onscreen: boolean = false;


  @ViewChild('img', {static: true})
  private _image: ElementRef<HTMLImageElement>;

  public get image(): HTMLImageElement {
    return this._image?.nativeElement;
  }

  @Input()
  public imageUrl: string;


  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageUrl) {
      this.setImageSrc();
    }
  }

  ngAfterViewInit(){
    this.setImageSrc();
  }

  screenPosChange(pos: string) {
    this._onscreen = pos === 'on';
    this.setImageSrc();
  }

  private setImageSrc() {
    if (this.image && this._onscreen) {
      if (this.image.src !== this.imageUrl) {
        this.image.src = this.imageUrl;
      }
    }
  }


}

