import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('Tradeshow Auth Base URL', {
  providedIn: 'root',
  factory: () => ' https://webservices-dev.idealsupply.com/vendor/etradeshow'
});


export const COLLECTION_FORMATS = {
    'csv': ',',
    'tsv': '   ',
    'ssv': ' ',
    'pipes': '|'
}
