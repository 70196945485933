import { Injectable } from '@angular/core';

import { AuthenticationService } from '@nfc-authority/angular-auth-core';
import { BehaviorSubject, Observable, Subscriber, Subscription, interval } from 'rxjs';
import { share, startWith, switchMap } from 'rxjs/operators';
import { IRewardItem, IRewardPointsModel, TradeShowApi } from '../../data';
import { TradeShowService } from 'src/app/shared/trade-show.service';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {
  private _rewardPointSubject: BehaviorSubject<IRewardPointsModel> = new BehaviorSubject(undefined);
  private _rewardPointChangeObs: Observable<IRewardPointsModel>;

  private _rewardsSubject: BehaviorSubject<IRewardItem[]> = new BehaviorSubject(undefined);
  private _rewardsChangeObs: Observable<IRewardItem[]>;

  constructor(
    private readonly tradeshowApi: TradeShowApi,
    private readonly tradeshowService: TradeShowService,
    private readonly authService: AuthenticationService
  ) {
    this._rewardPointChangeObs = new Observable((substriber: Subscriber<IRewardPointsModel>) => {
      let currentRequest: Subscription;
      // let intervalId: any;
      let unsubbed = false;

      currentRequest = interval(5000).pipe(startWith(0)).pipe(switchMap((v, i) => {
        if (!!this.authService.currentUser && !!this.tradeshowService.tradeShowId) {
          return tradeshowApi.getRewardsPoints(i > 0, this.tradeshowService.tradeShowId);
        }
        return Promise.resolve(null);
      })).subscribe(points => {
        this.updatePoints(points || { earned: 0, spent: 0, wishlistPoints: 0 });
      });
      return () => {
        unsubbed = true;
        if (currentRequest) {
          currentRequest.unsubscribe();
        }
      };
    }).pipe(share());

    this._rewardsChangeObs = new Observable((substriber: Subscriber<IRewardItem[]>) => {
      let currentRequest: Subscription;
      let intervalId: any;
      let unsubbed = false;
      currentRequest = tradeshowApi.getRewardsList(false, this.tradeshowService.tradeShowId).subscribe(r => {
        if (!unsubbed) {
          intervalId = setInterval(() => {
            currentRequest = tradeshowApi.getRewardsList(this.tradeshowService.tradeShowId).subscribe(rewards => {
              this.updateRewards(rewards);
            });
          }, 5000);
          this.updateRewards(r);
        }
      });
      return () => {
        unsubbed = true;
        if (currentRequest) {
          currentRequest.unsubscribe();
        }
        clearInterval(intervalId);
      };
    }).pipe(share());
  }

  private _loadRewardPoints() {

  }

  public get points(): Observable<IRewardPointsModel> {
    return new Observable((substriber: Subscriber<IRewardPointsModel>) => {
      const updatesub = this._rewardPointChangeObs.subscribe();
      const valuesub = this._rewardPointSubject.subscribe(p => {
        substriber.next(p);
      });
      return () => {
        updatesub.unsubscribe();
        valuesub.unsubscribe();
      };
    });
  }

  public get rewards(): Observable<IRewardItem[]> {
    return new Observable((substriber: Subscriber<IRewardItem[]>) => {
      const updatesub = this._rewardsChangeObs.subscribe();
      const valuesub = this._rewardsSubject.subscribe(p => {
        substriber.next(p);
      });
      return () => {
        updatesub.unsubscribe();
        valuesub.unsubscribe();
      };
    });
  }

  public updateRewardSelection(item: IRewardItem): Observable<IRewardItem[]> {
    const update = this.tradeshowApi.updateRewardSelection(item, this.tradeshowService.tradeShowId);
    update.subscribe(result => {
      this.updateRewards(result);
    });
    return update;
  }

  private updatePoints(points: IRewardPointsModel) {
    if (this._rewardPointSubject.value !== points) {
      this._rewardPointSubject.next(points);
    }
  }

  private updateRewards(rewards: IRewardItem[]) {
    const currentRewards = this._rewardsSubject.value;
    let changed = false;
    if (currentRewards?.length !== rewards?.length) {
      changed = true;
    } else if (!currentRewards || !rewards) {
      changed = true;
    } else {
      for (let i = 0; i < currentRewards.length; i++) {
        const cr = currentRewards[i];
        const r = rewards[i];
        if (cr.id !== r.id || cr.selected !== r.selected || cr.wishQuantity !== r.wishQuantity) {
          changed = true;
        }
      }
    }
    if (changed) {
      this._rewardsSubject.next(rewards);
    }
  }

}
