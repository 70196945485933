import { Component, Input, ContentChild, TemplateRef, Optional, HostBinding } from '@angular/core';
import { PageComponent } from '../page/page.component';
import { WebSiteComponent } from '../web-site/web-site.component';
import { ConstraintAlignment } from '../base/containerbase.component';
import { BooleanValue } from '../../tscore/type-conversion/string/decorators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'section, ngwc-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class.ngwc-section]': 'true'
  }
})
export class SectionComponent {

  private _color: string;
  private _fullWidth: boolean = false;

  @Input()
  public headerLabel: string;

  @Input()
  public headerIcon: string;

  @Input()
  @HostBinding('attr.color')
  public get color(): string {
    return this._color;
  }
  public set color(value: string) {
    this._color = value;
  }

  @Input()
  @HostBinding('attr.fullWidth')
  @BooleanValue(true)
  public get fullWidth(): boolean {
    return this._fullWidth;
  }
  public set fullWidth(value: boolean) {
    this._fullWidth = value;
  }

  @ContentChild('header', { read: TemplateRef })
  public headerTemplate: TemplateRef<any>;

  @ContentChild('footer', { read: TemplateRef })
  public footerTemplate: TemplateRef<any>;

  public get constrain(): ConstraintAlignment {
    if (this.fullWidth === true) {
      return 'none';
    }
    if (this.parentPage) {
      return this.parentPage.constraintAlign;
    }
    if (this.parentSite) {
      return this.parentSite.constraintAlign;
    }
    return 'default';
  }

  constructor(
    @Optional() private parentPage: PageComponent,
    @Optional() private parentSite: WebSiteComponent
  ) {

  }
}
