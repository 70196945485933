import { InjectionToken } from '@angular/core';
import { CancelablePromise, CancelationToken } from '@nfc-authority/ts-core';
import { IAuthenticatedUser } from './model/IAuthenticatedUser';

export const AUTHENTICATION_PROVIDERS = new InjectionToken<IAuthenticationProvider<IAuthenticatedUser>>('Authentication Provider');
export const DEFAULT_AUTHENTICATION_PROVIDER_ID = new InjectionToken<string>('Default Authentication Provider Id');
export const AUTHENTICATION_SCREEN_ROUTE = new InjectionToken<string>('Authentication Screen Route', {
    factory: () => {
        return '/login';
    },
    providedIn: 'root'
});

export interface IAuthenticationProvider<TUser extends IAuthenticatedUser> {
    readonly providerId: string;
    readonly providerName: string;
    readonly providerIcon?: string;
    readonly type: string;
    readonly authenticatedUser: TUser;
    readonly config: any;
    authenticate(cancelationToken: CancelationToken): CancelablePromise<void>;
    logout(): Promise<void>;

}

export interface IExternalAuthenticationProvider<TUser extends IAuthenticatedUser> extends IAuthenticationProvider<TUser> {
    readonly type: 'external';

}

export interface ILoginConfig {
    readonly usernameLabel?: string;
    readonly usernamePlaceholder?: string;
    readonly passwordLabel?: string;
    readonly passwordPlaceholder?: string;
    readonly supportsPasswordRecovery?: boolean;
    readonly supportsAccountCreation?: boolean;
    readonly supportsPersistantLogin?: boolean;
}
export interface ILoginAuthenticationProvider<TUser extends IAuthenticatedUser, TCredentials> extends IAuthenticationProvider<TUser> {
    readonly type: 'login';
    readonly config: ILoginConfig;
    login(cred?: TCredentials): Promise<TUser>;
}

export function isExternalAuthenticationProvider(obj: any): obj is IExternalAuthenticationProvider<IAuthenticatedUser> {
    return obj && obj.type === 'external';
}

export function isLoginAuthenticationProvider(obj: any): obj is ILoginAuthenticationProvider<IAuthenticatedUser, any> {
    return obj && obj.type === 'login';
}
