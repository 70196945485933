import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { IShipToAddressModel } from "../models/IShipToAddressModel";
import { ApiBase } from "./api-base";


@Injectable({
  providedIn: 'root'
})
export class ShippingAddressApi extends ApiBase {
  /**
   * Get a list of all ship to addresses for the current user
   * @return A list of all ship to addresses for the current user
   */
  public getShipToAddresses(): Observable<IShipToAddressModel[]>;

  /**
   * Get a list of all ship to addresses for the current user
   * @param returnResponse Response type. Use "true" to return the entire response object, or "false" (default) to return just the response data.
   * @return A list of all ship to addresses for the current user
   */
  public getShipToAddresses(returnResponse: true): Observable<HttpResponse<IShipToAddressModel[]>>;

  public getShipToAddresses(returnResponse: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    const path = `shipto`;
    return this.http.request<IShipToAddressModel[]>('get', `${this.basePath}/${path}`, {
      headers: headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }
}
