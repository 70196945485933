<mat-card appElementOnscreen (screenposChange)="screenPosChange($event)">
  <mat-card-content #header class="header">
    <ng-content select="[header]"></ng-content>
  </mat-card-content>
  <mat-card-content class="before">
    <ng-content select="[before]"></ng-content>
  </mat-card-content>
  <mat-card-content class="image">
    <img #img [attr.data-src]="imageUrl" xngIf="imageUrl" />
  </mat-card-content>
  <mat-card-content class="content">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-content class="after">
    <ng-content select="[after]"></ng-content>
  </mat-card-content>
  <mat-card-footer class="footer">
    <ng-content select="[footer]"></ng-content>
  </mat-card-footer>
</mat-card>
