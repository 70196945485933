import { IIdentityProvider } from './IIdentityProvider';
import { IUserModel, ANON_USER } from './model/index';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CancelablePromise, CancelationToken } from '@nfc-authority/ts-core';
import { EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';


export abstract class BaseIdentityProvider<TUser extends IUserModel> implements IIdentityProvider<TUser> {

    private _onUserChanged: EventEmitter<TUser> = new EventEmitter();
    private _user: TUser;
    private _userLoaded: Promise<TUser>;


    // private _userSubject: BehaviorSubject<TUser> = new BehaviorSubject(undefined);




    public get userChange(): EventEmitter<TUser> {
        return this._onUserChanged;
    }

    public get isLoading(): Promise<TUser> {
        return this._userLoaded;
    }
    /*
        public get currentUser(): Observable<TUser> {
            console.log('IP currentUser');
            if (!this._userObs) {
                this._userObs = this._userSubject.pipe(filter(_ => {
                    console.log('FILTER', _, _ !== undefined);
                    return _ !== undefined;
                }));
                this.getUser().subscribe(
                    user => {
                        console.log('asdf', user);
                        this._userSubject.next(user);
                    }, err => {
                        console.log('qwerty', err);
                        this._userSubject.next(null);
                    }
                );
            }
            return this._userObs;
        }
        */

    public get currentUser(): TUser {
        this._loadUser();
        return this._user;
    }

    public logout(): void {
        this.setUser(undefined);
    }

    private async _loadUser() {
        if (!this._user) {
            try {
                this._userLoaded = this.getUser().toPromise();
                const user = await this._userLoaded;
                this.setUser(user);
            } catch (e) {
                if (e instanceof HttpErrorResponse) {
                    console.log('HTTP ERROR', e, e instanceof HttpErrorResponse);
                    if (e.status === 401) {
                        this.setUser(<TUser>ANON_USER);
                        return;
                    }
                }
                console.log('OTHER ERROR', e, e instanceof HttpErrorResponse);
                throw e;
            }
        }
    }

    protected setUser(user: TUser) {
        this._user = user;
        this._onUserChanged.emit(user);
    }

    public update(cancelationToken: CancelationToken): CancelablePromise<void> {
        return new CancelablePromise((res, rej) => {
            try {
                const sub = this.getUser().pipe(first()).subscribe(
                    user => {
                        this.setUser(user);
                        res();
                    }, err => {
                        this.setUser(<TUser>ANON_USER);
                        res();
                        // rej(err);
                    }
                );

                return () => {
                    sub.unsubscribe();
                };
            } catch (e) {
                this.setUser(<TUser>ANON_USER);
                res();
            }

        }, cancelationToken);

    }

    protected abstract getUser(): Observable<TUser>;

}
