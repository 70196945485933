import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { AuthenticationService } from '@nfc-authority/angular-auth-core';
import { Subscription } from 'rxjs';

export const BASE_URL = new InjectionToken('Chatwoot base url', {
  providedIn: 'root',
  factory: () => 'http://localhost:3000'
});

export const GLOBAL_TOKEN = new InjectionToken('Chatwoot global token', {
  providedIn: 'root',
  factory: () => 'zBm5Ap8QpGfXPnmPrcC31jWi'
});


declare global {
  export interface Window {
    chatwootSettings: any;
    chatwootSDK: any;
    $chatwoot: any;
  }
}

export class ChatWindowRef {
  private _opened: boolean = false;
  private _iframe: HTMLIFrameElement | null = null;
  private _chatChannel: BroadcastChannel;
  private _subs: Subscription = new Subscription();
  public readonly initialized: Promise<void>;
  constructor(
    public readonly title: string,
    public readonly baseUrl: string,
    public readonly token: string,
    private readonly authService: AuthenticationService,
    private readonly onDestroy: (self: ChatWindowRef) => void
  ) {
    // this._chatChannel = new BroadcastChannel('chat-channel-' + token);
    // this.initialized = this.create();
  }

  public get opened(): boolean {
    return this._opened;
  }

  public toggle() {
    this.opened ? this.close() : this.open();
  }

  private create(): Promise<void> {
    return new Promise(res => {
      this._chatChannel.addEventListener('message', ev => {
        if (ev.data === 'initialized') {
          res();
          this._chatChannel.postMessage({
            type: 'user',
            data: this.authService.currentUser
          });
        } else {
          this.handleChatCom(ev);
        }
      });
      this.deleteCwCookies();
      const iframe = this._iframe = document.createElement('iframe');
      iframe.classList.add('chat-window');
      iframe.style.display = 'none';
      iframe.src = `./assets/chat.html?baseUrl=${encodeURIComponent(this.baseUrl)}&token=${encodeURIComponent(this.token)}`;
      document.body.querySelector('.chat-window-container').appendChild(iframe);
      this._subs.add(this.authService.userChange.subscribe(u => {
        this._chatChannel.postMessage({
          type: 'user',
          data: u
        });
      }));
    });
  }

  private deleteCwCookies() {
    const pairs = document.cookie.split(';');
    for (const pstr of pairs) {
      const kv = pstr.split('=');
      const key = kv.shift().trim();
      if (key.startsWith('cw_user_')) {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  }

  private handleChatCom(ev: MessageEvent) {
    switch (ev.data) {
      case 'closed':
        this._opened = false;
        this._iframe.style.display = 'none';

        break;
      case 'opened':
        this._opened = true;
        this._iframe.style.display = 'block';
        break;
    }
  }

  public destroy() {
    this._subs.unsubscribe();
    this._chatChannel.close();
    this._chatChannel = null;
    this.deleteCwCookies();
    if (this._iframe) {
      this._iframe.remove();
      this._iframe = null;
    }
    this.onDestroy(this);
  }

  public open() {
    if (!this.opened) {
      this._opened = true;
      this._chatChannel.postMessage('open');
      this._iframe.style.display = 'block';
    }
  }

  public close() {
    if (this.opened) {
      this._opened = false;
      this._chatChannel.postMessage('close');
      this._iframe.style.display = 'none';
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatwootService {
  private _chatwindowContainer: HTMLDivElement;

  private _chatRefs: ChatWindowRef[] = [];

  private _chatTokens: string[] = [];

  private _globalRef: ChatWindowRef;



  public currentChat: EventEmitter<ChatWindowRef> = new EventEmitter();

  constructor(
    @Inject(BASE_URL) private readonly baseUrl: string,
    @Inject(GLOBAL_TOKEN) private readonly globalToken: string,
    private readonly authService: AuthenticationService
  ) {
    this._chatwindowContainer = document.createElement('div');
    this._chatwindowContainer.classList.add('chat-window-container');
    document.body.appendChild(this._chatwindowContainer);
    this.addChatWindow(this.globalToken);
  }



  public async addChatWindow(token: string, title?: string): Promise<ChatWindowRef> {
    const ref = new ChatWindowRef(title, this.baseUrl, token, this.authService, _ => {
      if (this._chatRefs.length > 1) {
        this._chatRefs.pop();
        const prev = this._chatRefs.pop();
        this.addChatWindow(prev.token, prev.title);
      }
    });
    const current = this._chatRefs[this._chatRefs.length - 1];
    current?.destroy();
    this._chatRefs.push(ref);
    await this.initChat(ref);
    return ref;
  }

  private async initChat(ref: ChatWindowRef) {
    await ref.initialized;
    this.currentChat.emit(ref);
    return ref;
  }
}
