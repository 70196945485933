
import {
  Component, Input, ChangeDetectionStrategy, Renderer2,
  Output, EventEmitter, ViewChildren, AfterViewInit,
  ElementRef, ViewChild, SecurityContext, ChangeDetectorRef,
  OnChanges, SimpleChanges, HostBinding, QueryList, TemplateRef, NgZone
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContainerComponentBase } from '../base/containerbase.component';
import { MatSidenav } from '@angular/material/sidenav';

import {
  Router,
  NavigationStart,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  GuardsCheckStart,
  GuardsCheckEnd,
  NavigationEnd,
  NavigationError,
  NavigationCancel
} from '@angular/router';
import { BreakpointService } from './breakpoints/breakpoint.service';
import { FocusOrigin } from '@angular/cdk/a11y';
import { DialogDirective } from '../dialog/dialog.directive';
import { StickyDirective, StickyLocation } from '../../layout/sticky/sticky.directive';
import { ElementResizeObservable } from '../../layout/resize/ElementResizeObservable';
import { Subscription } from 'rxjs';
import { websiteStateTransition } from './websiteStateAnimations';
// export declare type MenuOrientation = 'horizontal' | 'vertical' | undefined;
export declare type SiteLayout = 'page' | 'app';
import { PageComponent } from '../page/page.component';
import { HttpErrorResponse } from '@angular/common/http';

export enum WebsiteState {
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
  REQUEST = 'request'
}

export enum WebsiteTemplateLocations {
  HEAD_TOOLS_LEFT,
  HEAD_TOOLS_CENTER,
  HEAD_TOOLS_RIGHT,
  HEAD_LEFT,
  HEAD_CENTER,
  HEAD_RIGHT,
  FOOT_TOOLS_LEFT,
  FOOT_TOOLS_CENTER,
  FOOT_TOOLS_RIGHT,
  FOOT_LEFT,
  FOOT_CENTER,
  FOOT_RIGHT,
  MENU_LEFT,
  MENU_RIGHT
}

export enum WebsiteMenuMode {
  SIDE_DIALOG,
  SIDE_DIALOG_FULL,
  SIDE,
  SIDE_ALWAYS,
  COLLAPSE,
  DIALOG,
}

export interface WebsiteBreakpointMenuMode extends Array<any> {
  0: string;
  1: WebsiteMenuMode;
}

export declare type AdaptiveWebsiteMenuMode = WebsiteMenuMode | Array<WebsiteBreakpointMenuMode | WebsiteMenuMode>;

export enum WebsiteNavLocation {
  LEFT_MENU = 'left',
  RIGHT_MENU = 'right',
  HEADER = 'header'
}

export interface WebsiteBreakpointNavLocation extends Array<any> {
  0: string;
  1: WebsiteNavLocation;
}

export declare type AdaptiveWebsiteNavLocation = WebsiteNavLocation | Array<WebsiteBreakpointNavLocation | WebsiteNavLocation>;

@Component({
  selector: 'ngwc-web-site',
  templateUrl: './web-site.component.html',
  styleUrls: ['./web-site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [websiteStateTransition]
})
export class WebSiteComponent extends ContainerComponentBase implements OnChanges, AfterViewInit {
  public TPLLocations = WebsiteTemplateLocations;
  public State = WebsiteState;
  public MenuModes = WebsiteMenuMode;
  public NavLocation = WebsiteNavLocation;
  private _logo: SafeResourceUrl;
  private _layout: SiteLayout = 'page';
  private _isSmall: boolean;
  private _templateMap: Map<WebsiteTemplateLocations, Map<any, TemplateRef<any>[]>> = new Map();
  private _templatesForMap: Map<WebsiteTemplateLocations, TemplateRef<any>[]> = new Map();

  private _bodySizeListener: ElementResizeObservable;
  private _bodySizeSub: Subscription;

  private pageComponent: PageComponent;

  @Input()
  public siteHeaderToolsTemplate: TemplateRef<any>;
  @Input()
  public siteFooterToolsTemplate: TemplateRef<any>;
  @Input()
  public siteHeaderTemplate: TemplateRef<any>;
  @Input()
  public siteNavTemplate: TemplateRef<any>;
  @Input()
  public siteFooterTemplate: TemplateRef<any>;
  @Input()
  public leftMenuButtonTemplate: TemplateRef<any>;
  @Input()
  public rightMenuButtonTemplate: TemplateRef<any>;
  @Input()
  public siteLogoTemplate: TemplateRef<any>;
  @Input()
  public siteContentTemplate: TemplateRef<any>;
  @Input()
  public loadingIndicatorTemplate: TemplateRef<any>;
  @Input()
  public siteErrorTemplate: TemplateRef<any>;
  @Input()
  public siteRequestTemplate: TemplateRef<any>;

  public siteErrorTitle: string;
  public siteErrorMessage: string;
  public siteErrorStack: string;


  @Input()
  public leftMenuButtonLabel: string;
  @Input()
  public leftMenuButtonIcon: string;
  @Input()
  public leftMenuMode: AdaptiveWebsiteMenuMode = [['>=Large', WebsiteMenuMode.SIDE_ALWAYS], WebsiteMenuMode.SIDE_DIALOG];
  private _leftMenuMode: WebsiteMenuMode;
  private _leftMenuOpen: boolean;
  public get currentLeftMenuMode(): WebsiteMenuMode {
    return this._leftMenuMode;
  }

  @Input()
  public rightMenuButtonLabel: string;
  @Input()
  public rightMenuButtonIcon: string;
  @Input()
  public rightMenuMode: AdaptiveWebsiteMenuMode =
    WebsiteMenuMode.DIALOG;
  // [['>=Large', WebsiteMenuMode.SIDE_ALWAYS], WebsiteMenuMode.SIDE_DIALOG];
  private _rightMenuMode: WebsiteMenuMode;
  private _rightMenuOpen: boolean;
  public get currentRightMenuMode(): WebsiteMenuMode {
    return this._rightMenuMode;
  }

  @Input()
  public navLocation: AdaptiveWebsiteNavLocation = WebsiteNavLocation.RIGHT_MENU;
  private _navLocation: WebsiteNavLocation;
  public get currentNavLocation(): WebsiteNavLocation {
    return this._navLocation;
  }

  @ViewChild('sideMenuLeft')
  private sideMenuLeft: MatSidenav;
  @ViewChild('sideMenuLeft', { read: ElementRef })
  private sideMenuLeftElm: ElementRef<HTMLElement>;
  @ViewChild('LeftMenuContentTemplate')
  private leftMenuContentTemplate: TemplateRef<any>;
  @ViewChildren('leftMenuContent')
  private leftMenuContent: QueryList<ElementRef<HTMLElement>>;
  private _leftMenuHasContent: boolean = false;
  @ViewChildren('leftMenuHeader')
  private leftMenuHeaderContent: QueryList<ElementRef<HTMLElement>>;

  @ViewChild('leftMenuDialog')
  private _leftMenuDialog: DialogDirective;
  @ViewChild('leftMenuButtonWrapper')
  public leftMenuButtonWrapper: ElementRef;

  @ViewChild('EmptyTemplate')
  private emptyTemplate: TemplateRef<any>;

  private get leftMenuSideNavTemplate(): TemplateRef<any> {
    if (this.usesSideNavLeft) {
      return this.leftMenuContentTemplate;
    } else {
      return this.emptyTemplate;
    }
  }
  private get leftMenuDialogTemplate(): TemplateRef<any> {
    if (!this.usesSideNavLeft) {
      return this.leftMenuContentTemplate;
    } else {
      return this.emptyTemplate;
    }
  }

  private get rightMenuSideNavTemplate(): TemplateRef<any> {
    if (this.usesSideNavRight) {
      return this.rightMenuContentTemplate;
    } else {
      return this.emptyTemplate;
    }
  }
  private get rightMenuDialogTemplate(): TemplateRef<any> {
    if (!this.usesSideNavRight) {
      return this.rightMenuContentTemplate;
    } else {
      return this.emptyTemplate;
    }
  }
  private get navHeaderTemplate(): TemplateRef<any> {
    if (this.currentNavLocation === WebsiteNavLocation.HEADER) {
      return this.navTemplate;
    } else {
      return this.emptyTemplate;
    }
  }
  private get navLeftTemplate(): TemplateRef<any> {
    if (this.currentNavLocation === WebsiteNavLocation.LEFT_MENU) {
      return this.navTemplate;
    } else {
      return this.emptyTemplate;
    }
  }
  private get navRightTemplate(): TemplateRef<any> {
    if (this.currentNavLocation === WebsiteNavLocation.RIGHT_MENU) {
      return this.navTemplate;
    } else {
      return this.emptyTemplate;
    }
  }




  @ViewChild('sideMenuRight')
  private sideMenuRight: MatSidenav;
  @ViewChild('sideMenuRight', { read: ElementRef })
  private sideMenuRightElm: ElementRef<HTMLElement>;
  @ViewChild('RightMenuContentTemplate')
  private rightMenuContentTemplate: TemplateRef<any>;
  @ViewChildren('rightMenuContent')
  private rightMenuContent: QueryList<ElementRef<HTMLElement>>;
  private _rightMenuHasContent: boolean = false;
  @ViewChildren('rightMenuHeader')
  private rightMenuHeaderContent: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('rightMenuDialog')
  private _rightMenuDialog: DialogDirective;
  @ViewChild('rightMenuButtonWrapper')
  public rightMenuButtonWrapper: ElementRef;


  @ViewChild('NavTemplate')
  private navTemplate: TemplateRef<any>;
  @ViewChild('siteToolSticky')
  public siteToolSticky: StickyDirective;
  @ViewChild('siteHeaderSticky')
  public siteHeaderSticky: StickyDirective;
  @ViewChild('siteNavSticky')
  public siteNavSticky: StickyDirective;
  @ViewChild('siteContent')
  public siteContent: ElementRef<HTMLElement>;
  @ViewChild('headerConstraintWrapper')
  public headerConstraintWrapper: ElementRef<HTMLElement>;

  @ViewChild('siteHeader')
  public siteHeader: ElementRef<HTMLElement>;


  private _state: WebsiteState = WebsiteState.LOADING;

  @HostBinding('attr.state')
  public get state(): WebsiteState {
    return this._state;
  }

  public set state(value: WebsiteState) {
    if (this._state !== value) {
      this._state = value;
      this.changeDetectorRef.markForCheck();
    }

  }

  public loadingState: string = '';
  public loadingName: string = '';

  private _siteTitle: string;
  @Input()
  public get siteTitle(): string {
    return this._siteTitle;
  }

  public set siteTitle(value: string) {
    this._siteTitle = value;
    document.title = value;
  }


  @Input()
  public get logo(): string | SafeResourceUrl {
    return this._logo;
  }

  public set logo(value: string | SafeResourceUrl) {
    if (typeof value === 'string') {
      value = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
    this._logo = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
  }

  @Output()
  public layoutChange: EventEmitter<SiteLayout> = new EventEmitter();

  @Input()
  @HostBinding('attr.layout')
  public get layout(): SiteLayout {
    return this._layout;
  }
  public set layout(value: SiteLayout) {
    value = value === 'app' ? 'app' : 'page';
    if (this._layout !== value) {
      this._layout = value;
      this.layoutChange.emit(value);
      this.changeDetectorRef.markForCheck();
    }
  }

  @HostBinding('attr.fullViewPage')
  public get fullViewPage(): string {
    if (this.pageComponent) {
      return this.pageComponent.useFullView ? 'true' : 'false';
    }
    return undefined;
  }
  public set fullViewPage(value: string) {
  }

  private displayModeUsesSideNav(mode: WebsiteMenuMode) {
    switch (mode) {
      case WebsiteMenuMode.DIALOG:
        return false;
    }
    return true;
  }
  public get usesSideNavLeft(): boolean {
    return this.displayModeUsesSideNav(this._leftMenuMode);
  }
  public get sideNavModeLeft(): 'side' | 'over' {
    switch (this._leftMenuMode) {
      case WebsiteMenuMode.SIDE:
      case WebsiteMenuMode.SIDE_ALWAYS:
      case WebsiteMenuMode.COLLAPSE:
        return 'side';
    }
    return 'over';
  }
  public get sideNavFullLeft(): boolean {
    switch (this._leftMenuMode) {
      case WebsiteMenuMode.SIDE_DIALOG_FULL:
        return true;
    }
    return false;
  }

  public get usesSideNavRight(): boolean {
    return this.displayModeUsesSideNav(this._rightMenuMode);
  }
  public get sideNavModeRight(): 'side' | 'over' {
    switch (this._rightMenuMode) {
      case WebsiteMenuMode.SIDE:
      case WebsiteMenuMode.SIDE_ALWAYS:
      case WebsiteMenuMode.COLLAPSE:
        return 'side';
    }
    return 'over';
  }

  public get sideNavFullRight(): boolean {
    switch (this._rightMenuMode) {
      case WebsiteMenuMode.SIDE_DIALOG_FULL:
        return true;
    }
    return false;
  }
  @Input()
  public get menuLeftOpen(): boolean {
    return this._leftMenuOpen;
  }
  public set menuLeftOpen(value: boolean) {
    if (value) {
      this.openMenuLeft();
    } else {
      this.closeMenuLeft()
    }
    this._leftMenuOpen = value;
  }

  @Input()
  public get menuRightOpen(): boolean {
    return this._rightMenuOpen;
  }

  public set menuRightOpen(value: boolean) {
    if (value) {
      this.openMenuRight();
    } else {
      this.closeMenuRight()
    }
    this._rightMenuOpen = value;
  }

  public get useFullMenuBackdrop(): boolean {
    const leftFull = this._leftMenuOpen && this.sideNavFullLeft;
    const rightFull = this._rightMenuOpen && this.sideNavFullRight;
    return leftFull || rightFull;
  }

  public get usesMenuLeft(): boolean {
    return this._leftMenuHasContent;
  }
  public get usesMenuRight(): boolean {
    return this._rightMenuHasContent;
  }

  public get menuLeftShouldBeOpen(): boolean {
    switch (this._leftMenuMode) {
      // case WebsiteMenuMode.SIDE:
      case WebsiteMenuMode.SIDE_ALWAYS:
      case WebsiteMenuMode.COLLAPSE:
        return this.usesMenuLeft;
    }
    return false;
  }
  public get menuRightShouldBeOpen(): boolean {
    switch (this._rightMenuMode) {
      // case WebsiteMenuMode.SIDE:
      case WebsiteMenuMode.SIDE_ALWAYS:
      case WebsiteMenuMode.COLLAPSE:
        return this.usesMenuRight;
    }
    return false;
  }

  public addTemplates(where: WebsiteTemplateLocations, templates: TemplateRef<any>[], addedBy: any) {
    let locTpls = this._templateMap.get(where);
    if (!locTpls) {
      locTpls = new Map();
      this._templateMap.set(where, locTpls);
    }
    locTpls.set(addedBy, templates);
    this.computeTemplatesFor(where);
  }

  public removeTemplates(addedBy: any, where?: WebsiteTemplateLocations) {
    if (where === undefined) {
      this.removeAllTemplatesAddedBy(addedBy);
    } else {
      const locTpls = this._templateMap.get(where);
      if (locTpls) {
        locTpls.delete(addedBy);
        this.computeTemplatesFor(where);
      }
    }
  }

  private removeAllTemplatesAddedBy(addedBy: any) {
    this._templateMap.forEach((_, k) => {
      this.removeTemplates(addedBy, k);
    });
  }

  private computeTemplatesFor(where: WebsiteTemplateLocations) {
    const locTpls = this._templateMap.get(where);
    const tpls: TemplateRef<any>[] = [];
    locTpls.forEach(_ => {
      tpls.push(..._);
    });
    this._templatesForMap.set(where, tpls);

    requestAnimationFrame(() => {
      if (where === WebsiteTemplateLocations.MENU_LEFT) {
        this.checkLeftMenuContent();
      }
      if (where === WebsiteTemplateLocations.MENU_RIGHT) {
        this.checkRightMenuContent();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  public getTemplatesFor(where: WebsiteTemplateLocations) {
    return this._templatesForMap.get(where);
  }

  public getTemplate(location: string): TemplateRef<any> {
    switch (location) {
      case 'leftMenuSideNavTemplate':
        return this.leftMenuSideNavTemplate;
      case 'leftMenuDialogTemplate':
        return this.leftMenuDialogTemplate;
      case 'rightMenuSideNavTemplate':
        return this.rightMenuSideNavTemplate;
      case 'rightMenuDialogTemplate':
        return this.rightMenuDialogTemplate;
      case 'navHeaderTemplate':
        return this.navHeaderTemplate;
      case 'navLeftTemplate':
        return this.navLeftTemplate;
      case 'navRightTemplate':
        return this.navRightTemplate;

    }
    return this.emptyTemplate;
  }

  private eventToFocusOrigin(event?: MouseEvent | TouchEvent | KeyboardEvent) {
    let fo: FocusOrigin;
    if (event instanceof MouseEvent) {
      fo = 'mouse';
    } else if (window.TouchEvent && event instanceof TouchEvent) {
      fo = 'touch';
    } else if (event instanceof KeyboardEvent) {
      fo = 'keyboard';
    } else {
      fo = 'program';
    }
    return fo;
  }

  public openMenuLeft(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._leftMenuDialog) {
      this._leftMenuDialog.open();
    } else if (this.sideMenuLeft) {
      this.sideMenuLeft.open(this.eventToFocusOrigin(event));
    }
  }

  public openMenuRight(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._rightMenuDialog) {
      this._rightMenuDialog.open();
    } else if (this.sideMenuRight) {
      this.sideMenuRight.open(this.eventToFocusOrigin(event));
    }
  }

  public closeMenuLeft(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._leftMenuDialog) {
      this._leftMenuDialog.close();
    } else if (this.sideMenuLeft && this.canToggleLeftMenu) {
      this.sideMenuLeft.close();
    }
  }

  public closeMenuRight(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._rightMenuDialog) {
      this._rightMenuDialog.close();
    } else if (this.sideMenuRight && this.canToggleRightMenu) {
      this.sideMenuRight.close();
    }
  }

  public toggleMenuLeft(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._leftMenuOpen) {
      this.closeMenuLeft(event);
    } else {
      this.openMenuLeft(event);
    }
  }

  public toggleMenuRight(event?: MouseEvent | TouchEvent | KeyboardEvent): void {
    if (this._rightMenuOpen) {
      this.closeMenuRight(event);
    } else {
      this.openMenuRight(event);
    }
  }

  public get canToggleLeftMenu(): boolean {
    return this._leftMenuMode !== WebsiteMenuMode.SIDE_ALWAYS;
  }
  public get canToggleRightMenu(): boolean {
    return this._rightMenuMode !== WebsiteMenuMode.SIDE_ALWAYS;
  }

  public leftMenuOpenedChange(open) {
    this._leftMenuOpen = open;
    console.log('left open', open);
  }

  public rightMenuOpenedChange(open) {
    this._rightMenuOpen = open;
    console.log('right open', open);
  }

  private checkLeftMenuContent() {

    let hasContent =
      this.leftMenuHeaderContent &&
        this.leftMenuHeaderContent.first &&
        this.leftMenuHeaderContent.first.nativeElement
        ? this.leftMenuHeaderContent.first.nativeElement.childElementCount > 0
        : false;
    if (!hasContent) {
      hasContent =
        this.leftMenuContent &&
          this.leftMenuContent.first &&
          this.leftMenuContent.first.nativeElement
          ? this.leftMenuContent.first.nativeElement.childElementCount > 0
          : false;
    }
    if (this._leftMenuHasContent !== hasContent) {
      this._leftMenuHasContent = hasContent;
      setTimeout(() => {
        this.changeDetectorRef.markForCheck();
      }, 0);
    }
  }

  private checkRightMenuContent() {
    let hasContent =
      this.rightMenuHeaderContent &&
        this.rightMenuHeaderContent.first &&
        this.rightMenuHeaderContent.first.nativeElement
        ? this.rightMenuHeaderContent.first.nativeElement.childElementCount > 0
        : false;
    if (!hasContent) {
      hasContent =
        this.rightMenuContent &&
          this.rightMenuContent.first &&
          this.rightMenuContent.first.nativeElement
          ? this.rightMenuContent.first.nativeElement.childElementCount > 0
          : false;
    }
    if (this._rightMenuHasContent !== hasContent) {
      this._rightMenuHasContent = hasContent;
      setTimeout(() => {
        this.changeDetectorRef.markForCheck();
      }, 0);
    }
  }


  constructor(
    renderer: Renderer2,
    elm: ElementRef<HTMLElement>,
    private domSanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private breakpointService: BreakpointService,
    private zone: NgZone
  ) {
    super(elm, renderer, null);
    this.constraintAlign = 'default';

    this.breakpointService.change.subscribe(() => {

      if (this._isSmall !== this.breakpointService.isLTESmall) {
        this._isSmall = this.breakpointService.isLTESmall;
        this.changeDetectorRef.markForCheck();
      }

      this.applyLeftMenuMode();
      this.applyRightMenuMode();
      this.applyNavLocation();
    });

    this.router.events.subscribe(event => {
      console.log(event);
      if (event instanceof NavigationStart) {
        this.state = WebsiteState.LOADING;
        this.loadingState = '';
        this.loadingName = event.url.split('/')[1];
        this.loadingName = this.loadingName.split('?')[0];
        this.changeDetectorRef.markForCheck();
      } else if (event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd) {
        this.renderer.setStyle(this.siteContent.nativeElement, 'display', 'none');
        this.state = WebsiteState.LOADING;
        this.loadingState = 'Initializing';
        if (event.route.data && event.route.data.name) {
          this.loadingName = event.route.data.name;
        }
        this.changeDetectorRef.markForCheck();

      } else if (event instanceof GuardsCheckStart) {
        this.renderer.setStyle(this.siteContent.nativeElement, 'display', 'none');
        this.state = WebsiteState.LOADING;
        this.loadingState = 'Checking Access';
        this.changeDetectorRef.markForCheck();

      } else if (event instanceof GuardsCheckEnd) {
        this.state = WebsiteState.LOADING;
        this.loadingState = 'Loading';
        this.changeDetectorRef.markForCheck();

      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingState = 'Loaded';
        this.renderer.setStyle(this.siteContent.nativeElement, 'display', 'flex');
        this.changeDetectorRef.markForCheck();
        setTimeout(() => {
          this.state = WebsiteState.READY;
          this.loadingName = '';
          this.changeDetectorRef.markForCheck();
        }, 1);
      } else if (event instanceof NavigationError) {
        this.state = WebsiteState.ERROR;
        this.siteErrorTitle = 'Error: ';
        this.siteErrorMessage = 'Unable to load ' + event.url + '.\n';
        if (event.error instanceof HttpErrorResponse) {
          this.siteErrorTitle += event.error.statusText;
          this.siteErrorMessage += event.error.message;
        } else if (event.error instanceof Error) {
          this.siteErrorTitle += event.error.name;
          this.siteErrorMessage += event.error.message;
        }

        this.changeDetectorRef.markForCheck();
        console.log('Nav Error', event);
      }
      setTimeout(() => {
        console.log('nav event!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private getModeForBreakPoint(modes: AdaptiveWebsiteNavLocation): WebsiteNavLocation;
  private getModeForBreakPoint(modes: AdaptiveWebsiteMenuMode): WebsiteMenuMode;
  private getModeForBreakPoint(modes: AdaptiveWebsiteMenuMode | AdaptiveWebsiteNavLocation): WebsiteMenuMode | WebsiteNavLocation {
    if (!Array.isArray(modes)) {
      return modes;
    }
    for (let i = 0; i < modes.length; i++) {
      const mode = modes[i];
      if (!Array.isArray(mode)) {
        return mode;
      }
      if (this.breakpointService.is(mode[0])) {
        return mode[1];
      }
    }
    return undefined;
  }


  @HostBinding('attr.small')
  public get isSmall(): boolean {
    return this._isSmall;
  }

  private applyLeftMenuMode() {
    const mm = this.getModeForBreakPoint(this.leftMenuMode);
    if (this._leftMenuMode !== mm) {
      if (this.displayModeUsesSideNav(this._leftMenuMode) !== this.displayModeUsesSideNav(mm)) {
        this._leftMenuOpen = false;
      }
      this._leftMenuMode = mm;
      this.changeDetectorRef.markForCheck();
    }
  }
  private applyRightMenuMode() {
    const mm = this.getModeForBreakPoint(this.rightMenuMode);
    if (this._rightMenuMode !== mm) {
      if (this.displayModeUsesSideNav(this._rightMenuMode) !== this.displayModeUsesSideNav(mm)) {
        this._rightMenuOpen = false;
      }
      this._rightMenuMode = mm;
      this.changeDetectorRef.markForCheck();
    }
  }

  private applyNavLocation() {
    const nl = this.getModeForBreakPoint(this.navLocation);
    if (this._navLocation !== nl) {
      this._navLocation = nl;
      this.changeDetectorRef.markForCheck();

    }
  }


  public onPageInit(page: PageComponent) {
    console.log('onPageInit');
    this.pageComponent = page;
    if (page) {
      page.useFullViewChange.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  public onPageViewInit() {
    console.log('onPageViewInit');
  }

  public onPageDestroy() {
    console.log('onPageDestroy');
    if (this.pageComponent) {
      this.removeTemplates(this.pageComponent);
    }
    this.pageComponent = undefined;
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.leftMenuContent.changes.subscribe(() => {
      this.checkLeftMenuContent();
    });
    this.leftMenuHeaderContent.changes.subscribe(() => {
      this.checkLeftMenuContent();
    });
    this.rightMenuContent.changes.subscribe(() => {
      this.checkRightMenuContent();
    });
    this.rightMenuHeaderContent.changes.subscribe(() => {
      this.checkRightMenuContent();
    });

    this.siteToolSticky.stickySizeChange.subscribe(() => {
      this.updateSideNavPadding();
    });
    this.siteHeaderSticky.stickySizeChange.subscribe(() => {
      this.updateSideNavPadding();
    });
    this.siteNavSticky.stickySizeChange.subscribe(() => {
      this.updateSideNavPadding();
    });

    this._bodySizeListener = new ElementResizeObservable(this.siteContent);
    this.checkRequiresAlignmentAdjust();
  }

  private checkRequiresAlignmentAdjust() {
    this.removeAlignmentAdjust();
    if (this.constrainHeader) {
      if (this.sideMenuLeftElm || this.sideMenuRightElm) {
        this._bodySizeListener.subscribe(() => {
          if (this.sideMenuLeftElm) {
            this.siteHeader.nativeElement.style.paddingLeft = this.sideNavLeftOffset;
          }
          if (this.sideMenuRightElm) {
            this.siteHeader.nativeElement.style.paddingRight = this.sideNavRightOffset;
          }
        });
        if (this.sideMenuLeftElm) {
          this.siteHeader.nativeElement.style.paddingLeft = this.sideNavLeftOffset;
        }
        if (this.sideMenuRightElm) {
          this.siteHeader.nativeElement.style.paddingRight = this.sideNavRightOffset;
        }
      }
    }
  }

  private get sideNavRightOffset(): string {
    if (this.sideMenuRightElm && this.sideNavModeRight === 'side') {
      return this.sideMenuRightElm.nativeElement.offsetWidth + 'px';
    }
    return '0px';
  }
  private get sideNavLeftOffset(): string {
    if (this.sideMenuLeftElm && this.sideNavModeLeft === 'side') {
      return this.sideMenuLeftElm.nativeElement.offsetWidth + 'px';
    }
    return '0px';
  }

  private removeAlignmentAdjust() {
    if (this._bodySizeSub) {
      this._bodySizeSub.unsubscribe();
      this._bodySizeSub = undefined;
    }
  }
  private updateSideNavPadding() {
    let height = 0;
    if (this.siteToolSticky && this.siteToolSticky.ngwcSticky === StickyLocation.TOP) {
      height += this.siteToolSticky.size.height;
    }
    if (this.siteHeaderSticky && this.siteHeaderSticky.ngwcSticky === StickyLocation.TOP) {
      height += this.siteHeaderSticky.size.height;
    }
    if (this.siteNavSticky && this.siteNavSticky.ngwcSticky === StickyLocation.TOP) {
      height += this.siteNavSticky.size.height;
    }

    if (this.sideMenuLeftElm && !this.sideNavFullLeft) {
      this.renderer.setStyle(this.sideMenuLeftElm.nativeElement, 'padding-top', height + 'px');
    } else if (this.sideMenuLeftElm) {
      this.renderer.setStyle(this.sideMenuLeftElm.nativeElement, 'padding-top', 0 + 'px');
    }
    if (this.sideMenuRightElm) {
      this.renderer.setStyle(this.sideMenuRightElm.nativeElement, 'padding-top', height + 'px');
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.leftMenuMode) {
      this.applyLeftMenuMode();
    }
    if (changes.rightMenuMode) {
      this.applyRightMenuMode();
    }
    if (changes.navLocation) {
      this.applyNavLocation();
    }
    if (changes.constrainHeader || changes.constraintAlign) {
      if (this._bodySizeListener) {
        this.checkRequiresAlignmentAdjust();
      }
    }
  }
}
