import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFabMenuComponent } from './user-fab-menu/user-fab-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { AuthenticationModule } from '../authentication/index';
import { DialogModule } from '@nfc-authority/angular-web-components';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    AuthenticationModule,
    DialogModule
  ],
  declarations: [UserFabMenuComponent],
  exports: [UserFabMenuComponent]
})
export class UserModule { }
