import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { async } from 'rxjs/internal/scheduler/async';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'img[inline]'
})
export class InlineSvgDirective {
  private _src: string;
  @Input()
  public get src(): string {
    return this._src;
  }
  public set src(value: string) {
    this._src = value;


    if (value.endsWith('.svg')) {
      const load = async () => {
        const result = await fetch(value);
        const div = <HTMLElement>this.renderer.createElement('div');
        this.renderer.setProperty(div, 'innerHTML', await result.text());

       
        for (let i = 0; i < this.elm.nativeElement.attributes.length; i++) {
          const attr = this.elm.nativeElement.attributes.item(i);
          this.renderer.setAttribute(div.firstElementChild, attr.nodeName, attr.nodeValue);
        }

   // this.renderer.setAttribute(div.firstElementChild, 'class', div.getAttribute('class'));

        this.renderer.insertBefore(
          this.renderer.parentNode(this.elm.nativeElement),
          div.firstElementChild,
          this.elm.nativeElement
        );
        this.renderer.removeChild(
          this.renderer.parentNode(this.elm.nativeElement),
          this.elm.nativeElement
        );
        
      };
      load();
      /* const xhr = new XMLHttpRequest();
       xhr.open('GET', value, false);
       xhr.overrideMimeType('image/svg+xml');
       xhr.send('');
       xhr.onload = () => {
         
         this.renderer.insertBefore(
           this.renderer.parentNode(this.elm.nativeElement),
           xhr.responseXML.documentElement,
           this.elm.nativeElement
         );
       };
       */

    } else {
      this.renderer.setAttribute(this.elm.nativeElement, 'src', value);
    }


  }

  constructor(private elm: ElementRef<HTMLImageElement>, private renderer: Renderer2) { }

}
