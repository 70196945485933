<div class="button">
  <span>{{ cartService.cartCount | number }}</span>
  <button mat-icon-button>
    <mat-icon>shopping_cart</mat-icon>
  </button>
</div>
<div class="info">
  <ng-container *ngIf="cartService.cartCount">
    <div class="left">
      <div class="label">
        {{ cartService.cartCount | i18nPlural: ITEMS }} in Cart
      </div>
      <div class="count">{{ cartService.cartCount | number }}</div>
    </div>
    <div class="center">
      <div class="subtotal">
        <label>Subtotal:</label>&nbsp;<span>{{
          cartService.subtotal | currency
        }}</span>
      </div>
      <div class="savings">
        <label>Savings:</label>&nbsp;<span>{{
          cartService.savings | currency
        }}</span>
      </div>
      <div class="point" *ngIf="cartService.adPoints">
        <label>{{ 2 | i18nPlural: AD_POINTS }} Earned:</label>&nbsp;<span>{{
          cartService.adPoints | number
        }}</span>
      </div>
      <ng-container *ngIf="!(isApprentice$ | async)">
        <div class="point">
          <label
            >Total {{ points?.wishlistPoints | i18nPlural: TRADESHOW_BUCKS }} on
            Wishlist:</label
          >&nbsp;<span>{{ points?.wishlistPoints | number }}</span>
        </div>
        <div class="point">
          <label
            >Order
            {{ cartService.points | i18nPlural: TRADESHOW_BUCKS }}:</label
          >&nbsp;<span>{{ cartService.points | number }}</span>
        </div>
        <div class="point">
          <label
            >Remaining {{ neededPoints | i18nPlural: TRADESHOW_BUCKS }}:</label
          >&nbsp;<span>{{ neededPoints | number }}</span>
        </div>
      </ng-container>
    </div>
    <div class="right"></div>
  </ng-container>
  <ng-container *ngIf="!cartService.cartCount">
    <div class="no-count">No Items in Cart</div>
  </ng-container>
</div>
