import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IVendorModel, IVendorProductListItemModel } from '../../../../data';
import { IShoppingCartItem } from '../../../../data/models/IShoppingCartItem';
import { ShoppingCartService } from '../../shopping-cart.service';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: ['./add-to-cart-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToCartButtonComponent {
  @ViewChild('q')
  private inputField: ElementRef<HTMLInputElement>;

  @Input()
  public vendor: IVendorModel;
  @Input()
  public product: IVendorProductListItemModel;


  public get effectiveSellMultiple() {
    if (this.cartItem.iscCode === 'I') {
      return 1;
    }
    return this.cartItem.sellMultiple || 1;
  }


  public get cartItem(): IShoppingCartItem & { [key: string]: unknown } {
    if (this.vendor.id && this.product) {
      return this.cartService.getItem(this.product, this.vendor.id) as any;
    }
  }
  public get hasCartItem(): boolean {
    if (this.vendor.id && this.product) {
      return this.cartService.hasItem(this.product, this.vendor.id);
    }
  }

  public setCartItemQuantity(quantity: number, focus: boolean = false): void {
    if (this.vendor.id && this.product) {
      this.cartService.setItemQuantity(this.product, quantity);
      if (focus) {
        setTimeout(() => {
          this.focus();
        });
      }
    }
  }

  constructor(
    private readonly cartService: ShoppingCartService,
    private readonly changeRef: ChangeDetectorRef
  ) {
    cartService.updated.subscribe(() => {
      changeRef.markForCheck();
    });
  }

  public focus() {
    this.inputField?.nativeElement.focus();
    this.inputField?.nativeElement.select();
  }

}
