import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiBase } from "./api-base";
import { ILoginRequestModel } from "../models/ILoginRequestModel";
import { ILoginResponseModel } from "../models/ILoginResponseModel";
import { HttpResponse } from "@angular/common/http";

@Injectable(
  { providedIn: 'root' }
)
export class TradeShowAuthApi extends ApiBase {

  /**
   * Gets the current user
   * @return An observable of LoginResponseModel
   */
  public getCurrentUser(): Observable<ILoginResponseModel>;
  /**
   * Gets the current user
   * @param returnResponse: Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be an instance of LoginResponseModel
   */
  public getCurrentUser(returnResponse: true): Observable<HttpResponse<ILoginResponseModel>>;
  public getCurrentUser(returnResponse: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    const path = `current`;
    return this.http.request<ILoginResponseModel>('get', `${this.basePath}/${path}`, {
      headers: headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Logs in a user
   * @param loginRequestModel Login request model
   * @return An observable of LoginResponseModel
   */
  public login(loginRequestModel: ILoginRequestModel): Observable<ILoginResponseModel>;
  /**
   * Logs in a user
   * @param loginRequestModel Login request model
   * @param returnResponse: Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be an instance of LoginResponseModel
   */
  public login(loginRequestModel: ILoginRequestModel, returnResponse: true): Observable<HttpResponse<ILoginResponseModel>>;
  public login(loginRequestModel: ILoginRequestModel, returnResponse: boolean = false): Observable<any> {
    if (loginRequestModel === null || loginRequestModel === undefined) {
      throw new Error('Required parameter loginRequestModel was null or undefined when calling login.');
    }
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    const path = `login`;
    return this.http.request<ILoginResponseModel>('post', `${this.basePath}/${path}`, {
      body: loginRequestModel,
      headers: headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Logs out the current user
   * @return An observable
   */
  public logout(): Observable<void>;
  /**
   * Logs out the current user
   * @param returnResponse: Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response.
   * The body will be an empty object if the response status is 204 (No Content)
    */
  public logout(returnResponse: true): Observable<HttpResponse<void>>;
  public logout(returnResponse: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    const path = `logout`;
    return this.http.request<void>('get', `${this.basePath}/${path}`, {
      headers: headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }


}
