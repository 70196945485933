import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { WebSiteComponent } from './web-site.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SectionModule } from '../section/section.module';
import { SvgModule } from '../../svg/svg.module';
import { StickyModule } from '../../layout/sticky/sticky.module';
import { DialogModule } from '../dialog/dialog.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    SectionModule,
    PortalModule,
    SvgModule,
    StickyModule,
    MatProgressBarModule,
    DialogModule
  ],
  declarations: [WebSiteComponent],
  exports: [WebSiteComponent, SectionModule, StickyModule]
})
export class WebSiteModule { }
