import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { PageModule, SvgModule } from '@nfc-authority/angular-web-components';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SimpleLoginFormComponent } from './login/forms/simple-login-form/simple-login-form.component';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    PageModule,
    SvgModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    MatExpansionModule
  ],
  declarations: [LoginComponent, SimpleLoginFormComponent]
})
export class AuthenticationModule { }
