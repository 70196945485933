import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../service/index';
import { IAuthenticationProvider, IUserModel, ILoginConfig, isLoginAuthenticationProvider } from '../../../provider/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public hidePassword = true;

  private _authProvider: IAuthenticationProvider<IUserModel>;


  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (!authService.isAuthenticating) {
      authService.authenticate(this.authRedirect, this.authProviderId);
    }
    this.route.queryParams.subscribe(params => {
      if (params.provider !== authService.currentProviderId || params.redirect !== authService.currentRedirect) {
        authService.authenticate(this.authRedirect, this.authProviderId);
        changeDetectorRef.markForCheck();
      }
    });
  }

  public get authProviderId(): string {
    return this.route.snapshot.queryParams.provider;
  }
  public get authProviderType(): string {
    return this.authProvider.type;
  }
  public get authProvider(): IAuthenticationProvider<IUserModel> {
    return this.authService.getAuthProvider(this.authProviderId);
  }

  public get authRedirect(): string {
    return this.route.snapshot.queryParams.redirect;
  }

  public get authProviderConfig(): any {
      return this.authProvider?.config;
  }

  private getConfigOption<T>(name: string, defaultValue: T): T {
    if (this.authProviderConfig) {
      return this.authProviderConfig[name] || defaultValue;
    }
    return undefined;
  }

  public get usesLoginScreen(): boolean {
    return isLoginAuthenticationProvider(this.authProvider);
  }
  public get providers(): IAuthenticationProvider<IUserModel>[] {
    return this.authService.providers;
  }

  public get usernameLabel(): string {
    return this.getConfigOption<string>('usernameLabel', 'Username');
  }
  public get usernamePlaceholder(): string {
    return this.getConfigOption<string>('usernamePlaceholder', 'Enter your username');
  }
  public get passwordLabel(): string {
    return this.getConfigOption<string>('passwordLabel', 'Password');
  }
  public get passwordPlaceholder(): string {
    return this.getConfigOption<string>('passwordPlaceholder', 'Enter your password');
  }

  ngOnInit() {
  }

}
