import { IAlertsOptions } from './IAlertsOptions';

// tslint:disable-next-line:no-empty-interface
export interface IPopupOptions extends IAlertsOptions {
}

export const DefaultPopupOptions: IPopupOptions = {
    buttons: [
        { id: true, label: 'OK', color: 'primary', ariaLabel: 'Ok' },
        { id: undefined, label: 'Cancel', color: 'primary', ariaLabel: 'Cancel' }
    ],
    disableClose: true
};
