import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@nfc-authority/angular-auth-core';


@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf('etradeshow/current') !== -1 ||
      req.url.indexOf('etradeshow/login') !== -1 ||
      req.url.indexOf('etradeshow/cart') !== -1 ||
      req.url.indexOf('etradeshow/rewards/points') !== -1
      ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(tap(() => { }, err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
          return;
        }
        this.authService.authenticate(this.router.getCurrentNavigation()?.finalUrl?.toString());
      }
    }));

  }
}
