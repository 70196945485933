import { Injectable, Inject, Injector, EventEmitter } from '@angular/core';
import {
  IAuthenticationProvider,
  AUTHENTICATION_PROVIDERS,
  DEFAULT_AUTHENTICATION_PROVIDER_ID,
  AUTHENTICATION_SCREEN_ROUTE
} from '../provider/authentication/IAuthenicationProvider';
import { IUserModel } from '../provider/identity/model/IUserModel';
import { IDENTITY_PROVIDER, IIdentityProvider } from '../provider/identity/IIdentityProvider';
import { CancelationToken, CancelationError } from '@nfc-authority/ts-core';
import {
  Router, ActivatedRoute, ActivatedRouteSnapshot, CanLoad,
  CanActivate, CanActivateChild, UrlSegment, UrlSegmentGroup, PRIMARY_OUTLET
} from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _userChange: EventEmitter<IUserModel> = new EventEmitter();


  private _authUserPromise: Promise<IUserModel>;
  private _authPromise: Promise<void>;
  private _authPromiseResolve: () => void;
  private _authPromiseReject: (err: any) => void;
  private _authProviderCancelAuth: CancelationToken;

  private _currentProviderId: string;
  private _currentRedirect: string;

  constructor(
    @Inject(DEFAULT_AUTHENTICATION_PROVIDER_ID) private defaultAuthenticationServiceName: string,
    @Inject(AUTHENTICATION_PROVIDERS) private authenticationServices: IAuthenticationProvider<IUserModel>[],
    @Inject(IDENTITY_PROVIDER) private identityService: IIdentityProvider<IUserModel>,
    @Inject(AUTHENTICATION_SCREEN_ROUTE) private authScreenRoute: string,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector
  ) {
    this.identityService.userChange.subscribe(() => {
      this._userChange.emit(this.currentUser);
    });
  }

  public get userChange(): EventEmitter<IUserModel> {
    return this._userChange;
  }

  public async isAuthenticated(): Promise<boolean> {
    console.log('-  isAuthenticated');
    if (this.isAuthenticating) {
      await this._authPromise;
    }
    let user = this.currentUser;
    if (this.identityService.isLoading) {
      try {
        user = await this.identityService.isLoading;
      } catch (e) {
        user = this.currentUser;
      }

    }
    console.log('isAuthenticated - ', user && user.id !== null);
    return user && user.id !== null;
    /*console.log('isAuthenticated');
    return new Promise<boolean>((res, rej) => {
      const sub = this.currentUser.subscribe(user => {
        console.log('user', user);
        res(!!user);
        if (sub) {
          sub.unsubscribe();
        }
      }, err => {
        console.log('err', err);
        res(false);
        if (sub) {
          sub.unsubscribe();
        }
      });
    });*/
  }

  public get currentUser(): IUserModel {
    return this.identityService.currentUser;
  }


  public get isAuthenticating(): boolean {
    return !!this._authPromise;
  }

  public get currentProviderId(): string {
    return this._currentProviderId;
  }

  public get currentRedirect(): string {
    return this._currentRedirect;
  }

  public authenticate(redirect: string, authProviderId?: string): Promise<void> {
    if (!this.isAuthenticating) {
      this._authPromise = new Promise((res, rej) => {
        this._authPromiseResolve = res;
        this._authPromiseReject = rej;
      });
    }
    if (this._authProviderCancelAuth) {
      this._authProviderCancelAuth.cancel();
    }

    const authProvider = this.getAuthProvider(authProviderId);
    this._currentProviderId = authProvider.providerId;
    this._currentRedirect = redirect;

    this.router.navigate([this.authScreenRoute], {
      queryParams: {
        provider: authProvider.providerId,
        redirect: redirect
      }
    }).then(() => {
      this._authProviderCancelAuth = new CancelationToken();
      authProvider.authenticate(this._authProviderCancelAuth)
        .then(() => {
          this._authPromiseResolve();
          this._authPromise = undefined;
          this._currentProviderId = undefined;
          this._currentRedirect = undefined;
          this.router.navigateByUrl(redirect, {
            replaceUrl: true
          });
        })
        .catch((e) => {
          if (!(e instanceof CancelationError)) {
            this._currentProviderId = undefined;
            this._currentRedirect = undefined;
            this._authPromiseReject(e);
          }
        });
    });

    return this._authPromise;
  }

  public async logout(): Promise<void> {
    const authProvider = this.getAuthProvider(this.currentProviderId);
    await authProvider.logout();
    this.recheckAuthGuards();
  }

  private _chackHasAnyRole(user, roles: string[]): boolean {
    if (!user || !user.roles) {
      return false;
    }

    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      if (user.roles.indexOf(role) !== -1) {
        return true;
      }
    }
    return false;
  }

  public hasAnyRole(roles: string[]): Promise<boolean> {

    return new Promise(async (res, rej) => {
      await this.isAuthenticated();
      const user = this.currentUser;
      console.log('!@!@!@!', user);
      // if (user) {
      res(this._chackHasAnyRole(user, roles));
      // }else{
      // res(false)
      // }
    });
  }

  public async hasAllRoles(roles: string[]): Promise<boolean> {
    /*
        const user = this.identityService.user || await this.currentUser.toPromise();

        if (!user || !user.roles) {
          return false;
        }
        for (let i = 0; i < roles.length; i++) {
          const role = roles[i];
          if (user.roles.indexOf(role) === -1) {
            return false;
          }
        }*/
    return true;
  }

  public redirect(): void {
    console.log('redirect', this._currentRedirect);
  }

  public get providers(): IAuthenticationProvider<IUserModel>[] {
    return this.authenticationServices;
  }

  public getAuthProvider(authProviderId?: string): IAuthenticationProvider<IUserModel> {
    authProviderId = authProviderId || this.defaultAuthenticationServiceName;
    const authProvider = this.authenticationServices.find(_ => _.providerId === authProviderId);
    if (!authProvider) {
      throw new Error(`No Authentication Provider with name "${authProviderId}" was found.`);
    }
    return authProvider;
  }

  private async recheckAuthGuards(route?: ActivatedRouteSnapshot): Promise<boolean> {
    route = route || this.route.root.snapshot;
    console.log('^^^^^^^^^^^^', route);
    if (route.routeConfig) {
      console.log('!!@@', route.routeConfig);
      if (route.routeConfig.canActivate) {
        console.log('CHECK canActivate!!!');
        for (let i = 0; i < route.routeConfig.canActivate.length; i++) {
          const canActivateClass = route.routeConfig.canActivate[i];
          const canActivateInst = <CanActivate>this.injector.get(canActivateClass);
          const canActivate = await canActivateInst.canActivate(route, this.router.routerState.snapshot);
          if (!canActivate) {
            return false;
          }
        }
      }

      if (route.routeConfig.canLoad) {
        console.log('CHECK canLoad!!!');
        for (let i = 0; i < route.routeConfig.canLoad.length; i++) {
          const canLoadClass = route.routeConfig.canLoad[i];
          const canLoadInst = <CanLoad>this.injector.get(canLoadClass);
          const tree = this.router.parseUrl(this.router.url);
          const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
          const canLoad = await canLoadInst.canLoad(route.routeConfig, g ? g.segments : []);
          if (!canLoad) {
            return false;
          }
        }
      }

      if (route.routeConfig.canActivateChild) {
        console.log('CHECK canActivateChild!!!');
        for (let i = 0; i < route.routeConfig.canActivateChild.length; i++) {
          const canActivateClass = route.routeConfig.canActivateChild[i];
          const canActivateInst = <CanActivateChild>this.injector.get(canActivateClass);
          const canActivate = await canActivateInst.canActivateChild(route, this.router.routerState.snapshot);
          if (!canActivate) {
            return false;
          }
        }
      }
    }

    if (route.children) {
      for (let i = 0; i < route.children.length; i++) {
        const child = route.children[i];
        const passed = this.recheckAuthGuards(child);
        if (!passed) {
          return false;
        }
      }
    }
    return true;

  }


}
