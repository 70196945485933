import { EventEmitter, Injectable } from '@angular/core';
import {
  ILoginResponseModel,
  IOrderResponseModel,
  IVendorOrderModel,
  IVendorProductListItemModel,
  TradeShowApi
} from '../../data';



import {
  AuthenticationService,
  IUserModel
} from '@nfc-authority/angular-auth-core';
import linq from 'linq';
import { MatomoTracker } from 'ngx-matomo';
import { shareReplay, tap } from 'rxjs/operators';
import { TradeShowService } from 'src/app/shared/trade-show.service';
import { ShippingAddressApi } from '../../data/api/shipping-api.service';
import { IPlaceOrderResult } from '../../data/models/IPlaceOrderResult';
import { IShoppingCartItem } from '../../data/models/IShoppingCartItem';
import { ShoppingCartItem } from '../../data/models/ShoppingCartItem';
import { combineLatest } from 'rxjs';
const CART_STORAGE_KEY = `__ideal_vistual_tradeshow_cart____`;

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private static _nextPromoGrpId = 0;
  private _currentUserId: string;
  private _currentUser: IUserModel | undefined;
  private _cart: ShoppingCartItem[] = [];
  private _promoRewards: IVendorProductListItemModel[] = [];
  public updated: EventEmitter<void> = new EventEmitter();
  public updatedPromoRewards: EventEmitter<void> = new EventEmitter();

  public readonly shippingAddresses$ = this.shippingAddressApi.getShipToAddresses().pipe(
    shareReplay(1)
  );

  private readonly _loadTrigger$ = combineLatest([
    this.authService.userChange,
    this.tradeShowService.tradeShow$
  ]).pipe(
    tap(([u, tradeShow]) => {
      if (u && tradeShow) {
        const user = this.authService.currentUser;
        const userId = user?.id;
        this._currentUser = user;
        this._currentUserId = userId;
        this.loadUserCart(userId);

      }
    })
  );

  constructor(
    private readonly tradeshowApi: TradeShowApi,
    private readonly tradeShowService: TradeShowService,
    private readonly shippingAddressApi: ShippingAddressApi,
    private readonly authService: AuthenticationService,
    private readonly matomoTracker: MatomoTracker
  ) {
    this._loadTrigger$.subscribe();
    this.updated.subscribe(() => {
      this.saveUserCart();
    });
  }

  private async loadUserCart(userId: string) {
    if (!userId) {
      return;
    }
    for (const item of this._cart) {
      this.deleteTrackCartItem(item.productLine + item.partNumber);
    }
    //const key = `${CART_STORAGE_KEY}${userId}`;
    const cartStr = await this.loadCart();
    let cart: ShoppingCartItem[];
    if (cartStr) {
      try {
        cart = JSON.parse(cartStr);
        if (cart) {
          if (!Array.isArray(cart)) {
            cart = [];
          }
          if (cart[0] && !cart[0].vendor) {
            throw new Error('Old Data');
          }
          for (let i = 0; i < cart.length; i++) {
            cart[i] = new ShoppingCartItem(cart[i]);
            this.matomoTracker.addEcommerceItem(
              cart[i].productLine + cart[i].partNumber,
              cart[i].description,
              cart[i].vendor.number.toString(),
              cart[i].tradeshowPrice,
              cart[i].quantity
            );
          }
        }
      } catch (e) {
        console.warn(e);
        cart = [];
      }
    }
    this._cart = cart || [];
    this.updated.emit();
    if (this._cart.length) {
      this.matomoTracker.trackEcommerceCartUpdate(this.subtotal);
    } else {
      this.clearCart();
    }
    this.checkPromos();
  }

  private saveUserCart() {
    const userId = this.authService.currentUser?.id;
    if (userId) {
      //const key = `${CART_STORAGE_KEY}${userId}`;
      //localStorage.setItem(key, JSON.stringify(this._cart));
      this.saveCart(JSON.stringify(JSON.stringify(this._cart)));
    }
  }

  private async saveCart(cart: string) {
    try {
      await this.tradeshowApi.saveCart(cart, this.tradeShowService.tradeShowId).toPromise();
    } catch (e) {
      console.error(e);
    }
  }
  private async loadCart() {
    try {
      return await this.tradeshowApi.getCart(this.tradeShowService.tradeShowId).toPromise();
    } catch (e) {
      console.error(e);
    }
    return '[]';
  }

  public hasItem(item: IVendorProductListItemModel, vendorId: number): boolean {
    return !!this.getItem(item, vendorId);
  }

  public getItem(
    item: IVendorProductListItemModel,
    vendorId: number
  ): IShoppingCartItem {
    const idx = this.getItemIndex(item, vendorId);
    return idx === -1 ? undefined : this._cart[idx];
  }

  private getItemIndex(
    item: IVendorProductListItemModel,
    vendorId: number
  ): number {
    return this._cart.findIndex(
      (_) =>
        !!_.quantity &&
        vendorId === _.vendor.id &&
        item.productLine === _.productLine &&
        item.partNumber === _.partNumber
    );
  }

  public setItemQuantity(
    item: IVendorProductListItemModel,
    quantity: number
  ): void {
    let cartItem = this.getItem(item, item.vendor.id);
    if (!quantity || quantity < 1) {
      if (cartItem) {
        const idx = this.getItemIndex(item, item.vendor.id);
        this._cart.splice(idx, 1);
        this.updated.emit();
        this.deleteTrackCartItem(item.productLine + item.partNumber);
      }
    } else {
      if (!cartItem) {
        cartItem = Object.assign({}, item, {
          vendor: item.vendor,
          quantity,
        });
        this._cart.push(new ShoppingCartItem(cartItem));
        this.updated.emit();
      } else if (cartItem.quantity !== quantity) {
        cartItem.quantity = quantity;
        this.updated.emit();
      }
      this.matomoTracker.addEcommerceItem(
        cartItem.productLine + cartItem.partNumber,
        cartItem.description,
        item.vendor.number.toString(),
        cartItem.tradeshowPrice,
        cartItem.quantity
      );
    }
    this.matomoTracker.trackEcommerceCartUpdate(this.subtotal);
    this.checkPromos();
  }

  private async checkPromos() {
    const r = linq
      .from(this.cartItems)
      .where((_) => _.promoThreshold !== null)
      .select((_) => {
        if (_.promoGroup === null) {
          _.promoGroup = '___' + ShoppingCartService._nextPromoGrpId++;
        }
        return _;
      })
      .groupBy(
        (_) => _.promoGroup,
        (_) => _,
        (k, v) => {
          return {
            group: k,
            item: v.first(),
            itemCount: v.sum((i) => i.quantity),
            threshold: v.first().promoThreshold,
          };
        }
      )
      .where((_) => _.itemCount >= _.threshold)
      .select((_) => {
        if (_.group.startsWith('___')) {
          return {
            productLine: _.item.productLine,
            partNumber: _.item.partNumber,
            quantity: _.itemCount,
          };
        }
        return {
          group: _.group,
          quantity: _.itemCount,
        };
      })
      .toArray();

    if (r.length) {
      this._promoRewards = await this.tradeshowApi
        .getPromoRewards(r, this.tradeShowService.tradeShowId)
        .toPromise();
    } else {
      this._promoRewards = [];
    }
    this.updatedPromoRewards.emit();
  }

  private deleteTrackCartItem(sku: string) {
    // tslint:disable-next-line: no-string-literal
    const arr: any[][] = window['_paq'] || [];
    arr.push(['removeEcommerceItem', sku]);
  }

  private clearTrackCart() {
    // tslint:disable-next-line: no-string-literal
    const arr: any[][] = window['_paq'] || [];
    arr.push(['clearEcommerceCart']);
  }

  public get promoRewards(): readonly IVendorProductListItemModel[] {
    return this._promoRewards;
  }

  public get cartItems(): readonly ShoppingCartItem[] {
    return this._cart;
  }

  public get cartCount(): number {
    return this._cart.length;
  }

  public get cartQuantity(): number {
    return linq.from(this._cart).sum((_) => _.quantity || 0);
  }

  public get totalQuantity(): number {
    return linq
      .from(this._cart)
      .sum((_) => (_.quantity || 0) * ((_.iscCode === 'I' ? 1 : _.sellMultiple) || 1));
  }

  public get userInfo(): ILoginResponseModel {
    if (!this._currentUserId) {
      return undefined;
    }
    return (this.authService.currentUser as any)
      ?.properties as ILoginResponseModel;
  }

  public get requiresPONNumber(): boolean {
    return this.userInfo?.poRequired || false;
  }

  public async placeOrder(
    comment?: string,
    poNumber?: string,
    shippingId?: number
  ): Promise<IPlaceOrderResult> {
    const subtotal = this.subtotal;
    const orders: IVendorOrderModel[] = linq
      .from(this._cart)
      .where((_) => !!_.quantity)
      .groupBy(
        (_) => _.vendor.id,
        (_) => ({
          productLine: _.productLine,
          partNumber: _.partNumber,
          quantity: _.quantity * (_.iscCode === 'I' ? 1 : _.sellMultiple)
        }),
        (vendorId, items) => {
          return {
            vendor: {
              id: vendorId,
            },
            poNumber,
            comment,
            shippingId,
            products: items.toArray(),
          };
        }
      )
      .toArray();

    const orderProms: Array<Promise<IOrderResponseModel>> = [];
    for (const order of orders) {
      orderProms.push(this.tradeshowApi.placeVendorOrder(order, this.tradeShowService.tradeShowId).toPromise());
    }

    const orderStatus: IPlaceOrderResult = {
      points: 0,
      ordersSuccess: [],
      ordersFail: [],
    };

    let failon = -1;
    if (poNumber === 'fail one') {
      failon = Math.round(Math.random() * (orderProms.length - 1));
    }
    for (let i = 0; i < orderProms.length; i++) {
      try {
        if (poNumber === 'epic fail') {
          throw Error('You asked for it `epically`');
        }
        if (failon === i) {
          throw Error('You asked for it `randomly`');
        }
        const order = await orderProms[i];
        orderStatus.ordersSuccess.push(order);
        orderStatus.points += order.points;
      } catch (e) {
        const oOrder = orders[i];
        const failedOrder = {
          vendor: await this.tradeshowApi
            .getVendor(oOrder.vendor.id, this.tradeShowService.tradeShowId)
            .toPromise(),
          products: oOrder.products,
          error: e,
        };
        orderStatus.ordersFail.push(failedOrder);
      }
    }

    const cartLen = this._cart.length;
    this._cart = this._cart.filter((cartItem) => {
      const cartKey = `${cartItem.vendor.id}|${cartItem.productLine}${cartItem.partNumber}`;
      return (
        linq
          .from(orderStatus.ordersSuccess)
          .selectMany((_) =>
            linq.from(_.products).select((p) => ({
              ...p,
              vendorId: _.vendor.id,
            }))
          )
          .where((product) => {
            const key = `${product.vendorId}|${product.productLine}${product.partNumber}`;
            return key === cartKey;
          })
          .count() === 0
      );
    });
    if (cartLen !== this._cart.length) {
      this.updated.emit();
    }
    this.updateCartOrderTrackerSuccess(orderStatus, subtotal);
    this.checkPromos();
    return orderStatus;
  }

  private updateCartOrderTrackerSuccess(
    orderStatus: IPlaceOrderResult,
    subtotal: number
  ) {
    const currentSubtotal = this.subtotal;
    if (this._cart.length) {
      for (const item of this._cart) {
        this.deleteTrackCartItem(item.productLine + item.partNumber);
      }
      this.matomoTracker.trackEcommerceCartUpdate(subtotal - currentSubtotal);
    }
    const orderId = linq
      .from(orderStatus.ordersSuccess)
      .select((_) => _.id)
      .toJoinedString(',');
    this.matomoTracker.trackEcommerceOrder(orderId, subtotal - currentSubtotal);
    setTimeout(() => {
      if (this._cart.length) {
        for (const item of this._cart) {
          this.matomoTracker.addEcommerceItem(
            item.productLine + item.partNumber,
            item.description,
            item.vendor.number.toString(),
            item.tradeshowPrice,
            item.quantity
          );
        }
        this.matomoTracker.trackEcommerceCartUpdate(currentSubtotal);
      }
    }, 100);
  }
  public clearCart() {
    this._cart = [];
    this.saveUserCart();
    this.updated.emit();
    this.clearTrackCart();
    this.checkPromos();
    this.matomoTracker.trackEcommerceCartUpdate(this.subtotal);
  }

  public get subtotal(): number {
    const total = linq.from(this._cart).sum((_) => {
      const price = Math.round(_.tradeshowPrice * 10000);
      const sellMultiple = _.iscCode === 'I' ? 1 : _.sellMultiple;
      return price * _.quantity * sellMultiple;
    });
    return total / 10000;
  }

  public get subtotalBeforeDiscount(): number {
    const total = linq.from(this._cart).sum((_) => {
      const price = Math.round(_.customerPrice * 10000);
      const sellMultiple = _.iscCode === 'I' ? 1 : _.sellMultiple;
      return price * _.quantity * sellMultiple;
    });
    return total / 10000;
  }

  public get savings(): number {
    return this.subtotalBeforeDiscount - this.subtotal;
  }

  public get points(): number {
    const pointTotal = Math.floor(
      linq
        .from(this._cart)
        .where((_) => (_ as any).isPointsEligable)
        .sum(this.calcPrice)
      * (this.userInfo?.['pointsFactor'] || 1)
    );
    return pointTotal * (this.tradeShowService.tradeShow.rewardsRatio || 1);
  }

  public get adPoints(): number {

    const user = this._currentUser as IUserModel & {
      properties: any;
    };
    if (!user?.properties?.isAdEligible) {
      return 0;
    }
    const pointTotal = Math.floor(
      linq
        .from(this._cart)
        .where((_) => _.isAdEligible)
        .sum(this.calcPrice)
    );

    return pointTotal * (this.tradeShowService.tradeShow.rewardsRatio || 1);
  }


  private calcPrice(cartItem: ShoppingCartItem) {
    const price = cartItem.tradeshowPrice;
    const sellMultiple = cartItem.iscCode === 'I' ? 1 : cartItem.sellMultiple;
    return Math.round(price * cartItem.quantity * sellMultiple);
  };

}


