import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef, HostBinding, Renderer2 } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { searchStateTransition } from './search-bar-animations';
import { AnimationEvent } from '@angular/animations';
@Component({
  selector: 'ngwc-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [searchStateTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnDestroy {
  private _subject: Subject<string> = new Subject();
  private _debounced: Subscription;
  private _debounce: number;
  private _value: string;
  private _opened: boolean;
  /**
   * @Private
   */
  @HostBinding('class')
  public classes: string;

  public openState: string;

  @ViewChild('searchField')
  private searchField: ElementRef<HTMLInputElement>;
  @Input()
  public mode: 'over' | 'side' | 'push' = 'over';

  @Input()
  @HostBinding('attr.color')
  public color: undefined | '' | 'primary' | 'accent' | 'warn';

  @Input()
  public placeholder: string = 'Search';


  @Output()
  public openedChange: EventEmitter<boolean> = new EventEmitter();
  @Input()
  public get opened(): boolean {
    return this._opened;
  }
  public set opened(value: boolean) {
    value = this.mode === 'side' ? true : value;
    if (this._opened !== value) {
      this._opened = value;
      this.openState = this.opened ? 'open' : 'closed';
      this.openedChange.emit(value);
    }
  }
  /*
    private get stateClass(): string {
      return this._stateClass;
    }
    private set stateClass(value: string) {
      this._stateClass = value;
      this.renderer.se
    }*/

  @Output()
  public change: EventEmitter<string> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private elm: ElementRef<HTMLElement>
  ) { }


  ngOnInit() {
    if (this.debounce === undefined) {
      this.debounce = 250;
    }
    if (this.mode === 'side' && this.opened === undefined) {
      this.opened = true;
    } else if (this.mode !== 'side' && this.opened === undefined) {
      this.opened = false;
    }
    this.openState = this.opened ? 'open' : 'closed';
    this.classes = this.opened ? 'open' : 'closed';
  }

  @Input()
  public get debounce(): number {
    return this._debounce;
  }



  public set debounce(value: number) {
    if (this._debounce !== value) {
      this._debounce = value;
      if (this._debounced) {
        this._debounced.unsubscribe();
      }
      this._debounced = this._subject.pipe(debounceTime(value)).subscribe(txt => {
        if (this._value !== txt) {
          this._value = txt;
          this.change.emit(txt);
        }
      });
    }
  }

  public get value(): string {
    return this._value;
  }

  public onValueChanged(value: string) {
    this._subject.next(value);
  }

  public open() {
    this.opened = true;
  }

  public close() {
    this.opened = false;
    if (this.mode === 'side') {
      this.clear();
    }
  }

  public toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  public clear() {
    if (this.value) {
      this.searchField.nativeElement.value = '';
      this.change.emit('');
    }
  }

  ngOnDestroy() {
    if (this._debounced) {
      this._debounced.unsubscribe();
    }
  }

  public onAnimationChanged(event: AnimationEvent) {
    if (event.phaseName === 'start') {
      if (event.fromState === 'open' && event.toState === 'closed') {
        this.classes = 'closing';
      } else if (event.fromState === 'closed' && event.toState === 'open') {
        this.classes = 'opening';
        this.clear();
      }
    } else if (event.phaseName === 'done') {
      if (event.fromState === 'open' && event.toState === 'closed') {
        this.classes = 'closed';
        this.clear();
      } else if (event.fromState === 'closed' && event.toState === 'open') {
        this.classes = 'open';
        this.searchField.nativeElement.focus();
      }
    }

  }
}
