<ng-container [ngSwitch]="state$ | async">
  <ng-container *ngSwitchCase="'closesoon'">
    The Ideal Supply Virtual Trade show will close in {{timeToCloseString$ | async}}
  </ng-container>
  <ng-container *ngSwitchCase="'before'">
    The Ideal Supply Virtual Trade show will open in {{timeToOpenString$ | async}}
  </ng-container>
  <ng-container *ngSwitchCase="'closed'">
    The Ideal Supply Virtual Trade show is closed
  </ng-container>
</ng-container>
