import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IAlertOptions, DefaultAlertOptions, IPopupOptions, DefaultPopupOptions } from './options/index';
import { AlertDialogComponent, PopupDialogComponent } from './components/index';
import { IAlertData, IPopupData, IPopupResult } from './data/index';

@Injectable()
export class AlertsService {

    public constructor(
        private matDialogService: MatDialog
    ) {

    }

    public async alert(message: string, title?: string, options?: IAlertOptions): Promise<any> {
        const opts: IAlertOptions = Object.assign({}, DefaultAlertOptions, options);

        const dialogConfig: MatDialogConfig<IAlertData> = {
            ariaDescribedBy: opts.ariaDescribedBy,
            ariaLabel: opts.ariaLabel,
            ariaLabelledBy: opts.ariaLabelledBy,
            disableClose: opts.disableClose === false ? false : true,
            panelClass: opts.panelClass,
            autoFocus: true,
            closeOnNavigation: true,
            hasBackdrop: true,
            restoreFocus: true,
            data: {
                type: 'alert',
                title,
                message,
                options: opts
            }

        };
        const ref = this.matDialogService.open(AlertDialogComponent, dialogConfig);
        return await ref.afterClosed().toPromise();
    }

    public async popup<TModel>(
        template: TemplateRef<any>, model?: TModel, title?: string, options?: IPopupOptions
    ): Promise<IPopupResult<TModel>> {
        const opts: IPopupOptions = Object.assign({}, DefaultPopupOptions, options);
        const dialogConfig: MatDialogConfig<IPopupData<TModel>> = {
            ariaDescribedBy: opts.ariaDescribedBy,
            ariaLabel: opts.ariaLabel,
            ariaLabelledBy: opts.ariaLabelledBy,
            disableClose: opts.disableClose === false ? false : true,
            panelClass: opts.panelClass,
            autoFocus: true,
            closeOnNavigation: true,
            hasBackdrop: true,
            restoreFocus: true,
            data: {
                type: 'custom',
                title,
                template,
                model,
                options: opts
            }
        };
        const ref = this.matDialogService.open(PopupDialogComponent, dialogConfig);
        return await ref.afterClosed().toPromise();
    }

}
