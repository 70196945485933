import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InlineSvgDirective } from './inline-svg.directive';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InlineSvgDirective],
  exports: [InlineSvgDirective]
})
export class SvgModule { }
