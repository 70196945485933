import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PageComponent } from './page.component';
import { SectionModule } from '../section/section.module';
import { MatButtonModule } from '@angular/material/button';
import { StickyModule } from '../../layout/sticky/sticky.module';
import { HeroModule } from '../hero/hero.module';
import { SiteTplDirective } from './site-tpl.directive';

@NgModule({
  imports: [
    CommonModule,
    SectionModule,
    MatSidenavModule,
    MatButtonModule,
    StickyModule,
    HeroModule
  ],
  declarations: [PageComponent, SiteTplDirective],
  exports: [SectionModule, PageComponent, StickyModule, SiteTplDirective]
})
export class PageModule { }
