
export class CancelationError extends Error {
    private _cancelError: CancelationError;
    constructor(reasonOrError?: string | CancelationError) {
        super((typeof reasonOrError === 'string' ? reasonOrError : (reasonOrError ? reasonOrError.message : null)) || 'Canceled');
        this._cancelError = typeof reasonOrError === 'string' ? null : reasonOrError;
        if (this._cancelError) {
            const reThrowStack = this.stack.split('\n');
            const oStack = this._cancelError.stack.split('\n');
            this.stack = reThrowStack[0];
            this.stack += '\n' + reThrowStack.slice(2).join('\n');
            this.stack += '\nCaused by CancelationToken';
            this.stack += '\n' + oStack.slice(3).join('\n');
        }
    }

    public get canceledAt(): CancelationError {
        return this._cancelError;
    }
}

export class CancelationToken extends Promise<CancelationError> {
    private _cancelFn: (reason?: string) => void;
    private _canceled: CancelationError;
    constructor() {
        let cancelFn;
        // tslint:disable-next-line:only-arrow-functions
        super(function(res) {
            cancelFn = function(reason?: string) {
                this._canceled = new CancelationError(reason);
                res(this._canceled);
            };
        });
        this._cancelFn = cancelFn.bind(this);
    }

    public get cancel(): (reason?: string) => void {
        return this._cancelFn;
    }

    public get canceled(): boolean {
        return !!this._canceled;
    }

    public get reason(): string {
        return this.canceled ? this._canceled.message : undefined;
    }

    public throwIfCanceled(): void {
        if (this.canceled) {
            throw this._canceled;
        }
    }
}

export class CancelablePromise<T> extends Promise<T> {

    public constructor(
        executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void)
            => ((cancelationError: CancelationError) => void),
        cancelation: CancelationToken
    ) {
        super(executor ? (res, rej) => {
            const cancelationToken = cancelation;
            console.log(1);
            try {
                cancelationToken?.throwIfCanceled();
                const onCancel = executor((value: T) => {
                    try {
                        cancelationToken?.throwIfCanceled();
                        res(value);
                    } catch (e) {
                        rej(new CancelationError(e));
                    }
                }, (reason?: any) => {
                    try {
                        console.log(3);
                        cancelationToken?.throwIfCanceled();
                        rej(reason);
                    } catch (e) {
                        rej(new CancelationError(e));
                    }
                });
                cancelationToken?.then(c => {
                    try {
                        onCancel(c);
                        cancelationToken?.throwIfCanceled();
                    } catch (e) {
                        rej(new CancelationError(e));
                    }
                });
            } catch (e) {
                rej(new CancelationError(e));
            }
        } : null);
    }


}
