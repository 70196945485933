import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Route,
    UrlTree,
    Router,
    UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/index';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) { }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this.checkRoles(childRoute.data.permissions, '/' + childRoute.url.toString());
    }
    async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        const result = await this.checkRoles(route.data.permissions, '/' + segments.join('/'));
        if (result instanceof UrlTree) {
            this.router.navigateByUrl(result.toString());
            return false;
        }
        return result;
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this.checkRoles(next.data.permissions, '/' + next.url.toString());
    }

    private async checkRoles(routePermConfig: { anyRole?: string[], allRoles?: string[] }, targetUrl: string): Promise<boolean | UrlTree> {
        if (!routePermConfig) {
            return true;
        }
        if (routePermConfig.allRoles) {
            const r = await this.authService.hasAllRoles(routePermConfig.allRoles);
            if (!r) {
                return this.router.parseUrl('/permissionserror?for=' + targetUrl);
            }
        }
        if (routePermConfig.anyRole) {
            const r = await this.authService.hasAnyRole(routePermConfig.anyRole);
            if (!r) {
                return this.router.parseUrl('/permissionserror?for=' + targetUrl);
            }
        }
        return true;
    }
}


