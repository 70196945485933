import { ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ResizeSensor } from 'css-element-queries';

export class ElementResizeObservable extends Observable<{ width: number, height: number }> {

    public constructor(private element: ElementRef<HTMLElement>) {
        super((sub) => {
            const rs = new ResizeSensor(element.nativeElement, () => {
                sub.next(this.size);
            });
            return () => {
                rs.detach();
            };
        });
    }

    public get width(): number {
        return this.element.nativeElement.offsetWidth;
    }
    public get height(): number {
        return this.element.nativeElement.offsetHeight;
    }

    public get size(): { width: number, height: number } {
        return {
            width: this.width,
            height: this.height
        };
    }
}
