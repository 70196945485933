import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '@nfc-authority/angular-web-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IVendorProductListItemModel } from '../../../../data';
import { ITEM_REMAINS_ERROR, TRADESHOW_BUCKS } from '../../../../shared/PluralizeMaps';
import { TradeshowHoursService } from '../../../../shared/tradeshow-hours.service';
import { IPlaceOrderResult } from '../../../../data/models/IPlaceOrderResult';
import { IPlaceOrderResultFailedOrder } from '../../../../data/models/IPlaceOrderResultFailedOrder';
import { ShoppingCartItem } from '../../../../data/models/ShoppingCartItem';
import { IShipToAddressModel } from "../../../../data/models/IShipToAddressModel";
import { ShoppingCartService } from '../../shopping-cart.service';

type ORDER_MODE = 'summary' | 'checkout' | 'ordering' | 'confirm' | 'ordererror';

@Component({
  selector: 'app-shopping-cart-item-list',
  templateUrl: './shopping-cart-item-list.component.html',
  styleUrls: ['./shopping-cart-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartItemListComponent implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  ITEM_REMAINS_ERROR = ITEM_REMAINS_ERROR;
  TRADESHOW_BUCKS = TRADESHOW_BUCKS;
  public status$ = this.tradeshowHoursService.showState$.pipe(
    takeUntil(this._destroyed$),
  );
  private _mode: ORDER_MODE = 'summary';
  private _mode2: ORDER_MODE = 'summary';
  public orderResult: IPlaceOrderResult;
  private _isUpdatingOrder = false;
  public commentControl = new FormControl('');
  public poNumberControl = new FormControl('');
  public shipToAddressControl = new FormControl(undefined);

  public promoRewards: readonly IVendorProductListItemModel[] = [];

  public orderForm: FormGroup = new FormGroup({
    comment: this.commentControl,
    poNumber: this.poNumberControl,
    shipToAddress: this.shipToAddressControl
  });

  private setMode(mode: ORDER_MODE) {
    if (this._mode !== mode) {
      this._mode = mode;
      setTimeout(() => {
        this._mode2 = this._mode;
        this.changeRef.markForCheck();
      }, 500);
    }
  }

  public get mode() {
    return this._mode;
  }

  public get swappingMode() {
    return this._mode !== this._mode2;
  }

  public readonly shippingAddresses$ = this.cartService.shippingAddresses$;

  constructor(
    private readonly cartService: ShoppingCartService,
    private readonly alerts: AlertsService,
    private readonly changeRef: ChangeDetectorRef,
    public readonly tradeshowHoursService: TradeshowHoursService
  ) {
    cartService.updated.subscribe(() => {
      if (this.requirePO) {
        this.poNumberControl.setValidators([Validators.required, Validators.maxLength(20)]);
      } else {
        this.poNumberControl.setValidators([Validators.maxLength(20)]);
      }
      changeRef.markForCheck();
      if (!this._isUpdatingOrder) {
        this.setMode('summary');
      }
    });
    cartService.updatedPromoRewards.subscribe(() => {
      this.promoRewards = cartService.promoRewards;
      changeRef.markForCheck();
    });
  }

  public get items() {
    return this.cartService.cartItems;
  }

  public get requirePO() {
    return this.cartService.requiresPONNumber;
  }

  public async clearCart() {
    const clearCart = await this.alerts.alert(`Are you sure you wish to clear the cart of ${this.cartService.cartQuantity} item(s)?`, 'Clear Cart?', {
      buttons: [{ id: true, label: 'Yes', ariaLabel: 'Yes', color: 'warn' }, { id: false, label: 'No', ariaLabel: 'No' }]
    });
    if (clearCart) {
      this.cartService.clearCart();
    }
  }

  public checkout(): void {
    this.setMode('checkout');
  }

  public async submit() {
    this.setMode('ordering');
    const formData = this.orderForm.getRawValue();
    try {
      this._isUpdatingOrder = true;
      this.orderResult = await this.cartService.placeOrder(formData.comment, formData.poNumber || undefined, +formData.shipToAddress || undefined);
      if (this.orderResult.ordersSuccess.length === 0 && this.orderResult.ordersFail.length > 0) {
        this.setMode('ordererror');
      } else {
        this.setMode('confirm');
      }
    } catch {
      this.setMode('ordererror');
    } finally {
      setTimeout(() => {
        this._isUpdatingOrder = false;
      }, 100);
    }
  }

  public cancel(): void {
    this.setMode('summary');
  }

  public getErrorMessage(order: IPlaceOrderResultFailedOrder): string {
    return `We were unable to place your order with ${order.vendor.name} at this time.`;
  }

  public getErrorReason(order: IPlaceOrderResultFailedOrder): string {
    const err = order.error;
    if (err instanceof HttpErrorResponse) {
      return `${err.status} ${err.statusText} - ${JSON.stringify(err.error)}`;
    } else {
      return err.message;
    }
  }

  public trackProductBy(index: number, item: ShoppingCartItem): string {
    return item.vendor.id + '|' + item.productLine + item.partNumber;
  }

  public trackProductRewardBy(index: number, item: ShoppingCartItem): string {
    return item.productLine + item.partNumber;
  }

  public shipToAddressModelToAddressString(shipToAddressModels: IShipToAddressModel[]): string {
    const shipToAddressModel = shipToAddressModels.find((shipToAddressModel) => shipToAddressModel.shippingId === this.shipToAddressControl.value);
    if (!shipToAddressModel) {
      return '';
    }
    return `${shipToAddressModel.name} ${shipToAddressModel.company || ''} ${shipToAddressModel.line1} ${shipToAddressModel.line2 || ''} ${shipToAddressModel.postalCode || ''} ${shipToAddressModel.locality || ''} ${shipToAddressModel.province || ''} ${shipToAddressModel.country || ''}`;
  }

  public ngOnDestroy() {
    this._destroyed$.next();
  }
}
