
export declare type ConverterFunction<TFrom, TTo> = (value: TFrom) => TTo;

function shouldDefault(val: any): boolean {
    return val === undefined || val === '';
}

export function fromProperty<TFrom, TTo>(converterFn: ConverterFunction<TFrom, TTo>, defaultValue?: TTo): TypedPropertyDescriptor<TTo> {
    let value: TTo;
    return {
        get: (): TTo => {
            return value;
        },
        set: (val: any) => {
            val = shouldDefault(val) ? defaultValue : val;
            value = converterFn(val);
        },
        configurable: true,
        enumerable: true
    };
}

export function fromDescriptor<TFrom, TTo>(
    descriptor: TypedPropertyDescriptor<TTo>,
    converterFn: ConverterFunction<TFrom, TTo>,
    defaultValue?: TTo
): TypedPropertyDescriptor<TTo> {
    const oGetter = descriptor.get;
    const oSetter = descriptor.set;
    return {
        get: function (): TTo {
            return oGetter.call(this);
        },
        set: function (val: any) {
            val = shouldDefault(val) ? defaultValue : val;
            oSetter.call(this, converterFn(val));
        },
        configurable: descriptor.configurable,
        enumerable: descriptor.enumerable
    };
}
