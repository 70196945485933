import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbNavComponent } from './bread-crumb-nav.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [BreadCrumbNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    BreadCrumbNavComponent
  ]
})
export class BreadCrumbNavModule { }
