
import { IVendorModel } from '..';
import { IShoppingCartItem } from './IShoppingCartItem';
export class ShoppingCartItem implements IShoppingCartItem {
  quantity: number;
  productLine: string;
  partNumber: string;
  description: string;
  customerPrice: number;
  discountApplied: boolean;
  discountedItem: boolean;
  group?: unknown;
  imageURL: string;
  iscCode?: unknown;
  mixMatch: boolean;
  salePrice: number;
  sellMultiple: number;
  sellUnit: string;
  threshold: number;
  tradeshowPrice: number;
  valid: boolean;
  vendorSpecifiedCost: number;
  vendor: IVendorModel;
  isAdEligible: boolean;
  specSheetURL: string;
  cartonQty: number;
  promo: string;
  promoGroup: string;
  promoQuantity: number;
  promoThreshold: number;

  constructor(item: IShoppingCartItem) {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        this[key] = item[key];
      }
    }
  }

  public get effectiveSellMultiple() {
    if (this.iscCode === 'I') {
      return 1;
    }
    return this.sellMultiple || 1;
  }

  public get tradeshowCost(): number {
    const price = Math.round(this.tradeshowPrice * 10000);
    return price * this.effectiveSellMultiple / 10000;
  }
  public get tradeshowTotal(): number {
    const price = Math.round(this.tradeshowPrice * 10000);
    return price * this.effectiveSellMultiple * this.quantity / 10000;
  }
  public get saleCost(): number {
    const price = Math.round(this.salePrice * 10000);
    return price * this.effectiveSellMultiple / 10000;
  }
  public get saleTotal(): number {
    const price = Math.round(this.salePrice * 10000);
    return price * this.effectiveSellMultiple * this.quantity / 10000;
  }
  public get customerCost(): number {
    const price = Math.round(this.customerPrice * 10000);
    return price * this.effectiveSellMultiple / 10000;
  }
  public get customerTotal(): number {
    const price = Math.round(this.customerPrice * 10000);
    return price * this.effectiveSellMultiple * this.quantity / 10000;
  }
  public get savings(): number {
    return this.customerPrice - this.tradeshowPrice;
  }
  public get savingCost(): number {
    return this.customerCost - this.tradeshowCost;
  }
  public get savingsTotal(): number {
    return this.customerTotal - this.tradeshowTotal;
  }
}
