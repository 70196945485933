import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ShoppingCartButtonComponent } from './components/shopping-cart-button/shopping-cart-button.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AddToCartButtonComponent } from './components/add-to-cart-button/add-to-cart-button.component'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ShoppingCartItemListComponent } from './components/shopping-cart-item-list/shopping-cart-item-list.component';
import { AlertsModule } from '@nfc-authority/angular-web-components';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [ShoppingCartButtonComponent, AddToCartButtonComponent, ShoppingCartItemListComponent],
  imports: [
    SharedModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    AlertsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatTableModule
  ],
  exports: [ShoppingCartButtonComponent, AddToCartButtonComponent, ShoppingCartItemListComponent]
})
export class ShoppingCartModule { }
