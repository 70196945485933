import {
  Component, ElementRef, Input, AfterViewInit,
  ChangeDetectorRef, ChangeDetectionStrategy,
  TemplateRef, Renderer2, ViewChildren, QueryList,
  ContentChildren, AfterContentInit, OnDestroy, OnInit, Output, EventEmitter
} from '@angular/core';
import { Location } from '@angular/common';
import { BooleanValue } from '../../tscore/type-conversion/string/decorators';
import { ContainerComponentBase } from '../base/containerbase.component';
import { WebSiteComponent } from '../web-site/web-site.component';
import { SiteTplDirective } from './site-tpl.directive';
import * as linq from 'linq';
import { Router, ActivatedRoute } from '@angular/router';

export enum PageLayout {
  SIMPLE,
  BOTHSIDEBARS
}

@Component({
  selector: 'ngwc-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent extends ContainerComponentBase implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  private _useFullView: boolean = false;

  @Input()
  public pageToolbarTemplate: TemplateRef<any>;
  @Input()
  public pageFooterTemplate: TemplateRef<any>;
  @Input()
  public heroTemplate: TemplateRef<any>;
  @Input()
  public heroContentTemplate: TemplateRef<any>;
  @Input()
  public heroBackdropTemplate: TemplateRef<any>;
  @Input()
  public heroBackgroundImage: string;

  @Output()
  public useFullViewChange: EventEmitter<boolean> = new EventEmitter();
  @Input()
  @BooleanValue(true)
  public get useFullView(): boolean {
    return this._useFullView;
  }
  public set useFullView(value: boolean) {
    if (this._useFullView !== value) {
      this._useFullView = value;
      this.useFullViewChange.emit(value);
    }
  }

  @Input()
  public pageTitle: string;
  @Input()
  public pageSubtitle: string;

  @Input()
  @BooleanValue(true)
  public showHero: boolean = false;

  @ContentChildren(SiteTplDirective)
  private siteTemplates: QueryList<SiteTplDirective>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    containerDirective: WebSiteComponent,
    renderer: Renderer2,
    elm: ElementRef<HTMLElement>,
    private locationService: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(elm, renderer, containerDirective);
  }

  public navigateBack() {
    this.locationService.back();
  }

  public navigateBackTo(commands: string[], relative = true) {
    this.navigateBack();
    setTimeout(() => {
      this.router.navigate(commands, {
        replaceUrl: true,
        relativeTo: relative ? this.route : undefined,
      });
    }, 1);
  }
  ngOnInit() {
    this.website.onPageInit(this);
  }

  ngAfterViewInit() {
    this.website.onPageViewInit();
  }
  ngAfterContentInit() {
    this.applyTemplateInsertions();
    this.siteTemplates.changes.subscribe(() => {
      this.applyTemplateInsertions();
    });
  }

  ngOnDestroy() {
    this.website.onPageDestroy();
  }
  private get website(): WebSiteComponent {
    return <WebSiteComponent>this.parentContainer;
  }

  private applyTemplateInsertions() {
    const insert = linq.from(this.siteTemplates.toArray()).groupBy(_ => _.templateLocation, _ => _, (key, list) => {
      return {
        location: key,
        templates: list.select(_ => _.templateRef).toArray()
      };
    }).toArray();

    insert.forEach(item => {
      this.website.addTemplates(item.location, item.templates, this);
    });
  }
}
