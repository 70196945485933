import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthorizationRoutingModule } from './authorization-routing.module';
import { PermissionserrorComponent } from './permissionserror/permissionserror.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { PageModule } from '@nfc-authority/angular-web-components';
@NgModule({
  declarations: [PermissionserrorComponent],
  imports: [
    CommonModule,
    PageModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    AuthorizationRoutingModule
  ]
})
export class AuthorizationModule { }
