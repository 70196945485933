import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseAuthenticationProvider,
  IAuthenticatedUser, IDENTITY_PROVIDER,
  IIdentityProvider,
  ILoginAuthenticationProvider,
  ILoginConfig,
  ISimpleLoginCredentials,
  IUserModel
} from '@nfc-authority/angular-auth-core';
import { CancelablePromise } from '@nfc-authority/ts-core';
import { TradeShowAuthApi } from '../api/trade-show-auth.api';


@Injectable({
  providedIn: 'root'
})
export class IdealTradeshowAuthProvider extends BaseAuthenticationProvider<IAuthenticatedUser> implements
  ILoginAuthenticationProvider<IAuthenticatedUser, ISimpleLoginCredentials> {
  public static readonly PROVIDER_ID = 'ideal-tradeshow-auth';
  public readonly providerId = IdealTradeshowAuthProvider.PROVIDER_ID;
  public readonly providerName = 'Ideal Tradeshow Authentication';
  public readonly type: 'login' = 'login';

  public get config(): ILoginConfig {
    return {
      supportsPasswordRecovery: false
    };
  }


  constructor(
    private authApi: TradeShowAuthApi,
    @Inject(IDENTITY_PROVIDER) private identityServices: IIdentityProvider<IUserModel>,
    private route: ActivatedRoute
  ) {
    super();
  }

  public async logout(): Promise<void> {
    await this.authApi.logout().toPromise();
    this.setAuthenticatedUser(null);
    this.identityServices.logout();
  }

  public login(cred?: ISimpleLoginCredentials): Promise<IAuthenticatedUser> {
    return new CancelablePromise<IAuthenticatedUser>((res, rej) => {
      const sub = this.authApi.login(cred, true).subscribe(response => {
        if (response.ok) {
          this.identityServices.update(this.authCancelToken).then(
            () => {
              this.setAuthenticatedUser(this.identityServices.currentUser);
              res(this.identityServices.currentUser);
            }, err => {
              rej(err);
            });
        } else {
          rej(new Error(response.statusText));
        }
      }, err => {
        rej(err);
      });
      return () => {
        sub.unsubscribe();
      };
    }, this.authCancelToken);
  }
}
