import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertsService } from './alerts.service';
import { AlertDialogComponent, PopupDialogComponent } from './components/index';
import { CommonModule } from '@angular/common';
import {  FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule
    ],
    providers: [AlertsService],
    declarations: [AlertDialogComponent, PopupDialogComponent],
    entryComponents: [AlertDialogComponent, PopupDialogComponent]
})
export class AlertsModule {

}
