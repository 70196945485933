import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AUTHENTICATION_PROVIDERS, DEFAULT_AUTHENTICATION_PROVIDER_ID, DEFAULT_PAGE, IDENTITY_PROVIDER, UserModule } from '@nfc-authority/angular-auth-core';
import { MenuModule, WebSiteModule } from '@nfc-authority/angular-web-components';
import { MatomoModule } from 'ngx-matomo';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IdealTradeshowAuthProvider, IdealTradeshowIdentityProvider } from './data';
import { ShoppingCartModule } from './modules/shopping-cart/shopping-cart.module';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedInterceptor } from './shared/unauth.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WebSiteModule,
    MenuModule,
    ShoppingCartModule,
    HttpClientModule,
    UserModule,
    MatomoModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_PAGE,
      useValue: '/home'
    },
    {
      provide: AUTHENTICATION_PROVIDERS,
      multi: true,
      useExisting: IdealTradeshowAuthProvider
    },
    {
      provide: DEFAULT_AUTHENTICATION_PROVIDER_ID,
      useValue: IdealTradeshowAuthProvider.PROVIDER_ID
    },
    {
      provide: IDENTITY_PROVIDER,
      useClass: IdealTradeshowIdentityProvider
    },
    environment.appProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
