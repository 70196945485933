import { Directive, Input, TemplateRef } from '@angular/core';
import { WebsiteTemplateLocations } from '../web-site/web-site.component';
@Directive({
  selector: '[ngwcSiteTpl]'
})
export class SiteTplDirective {


  // tslint:disable-next-line:no-input-rename
  @Input('ngwcSiteTpl')
  public templateLocation: WebsiteTemplateLocations;

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}
