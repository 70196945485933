<div class="time-display" *ngIf="(status$ | async) !== 'open'">
  <app-tradeshow-status></app-tradeshow-status>
</div>
<div class="actions">
  <ng-container *ngIf="(status$ | async) as state">
    <ng-container [ngSwitch]="mode">
      <ng-container *ngSwitchCase="'summary'">
        <button mat-stroked-button color="warn" (click)="clearCart()"
          [disabled]="swappingMode || !items?.length">Clear</button>
        <button mat-raised-button color="accent"
          [disabled]="(state !== 'open' && state !== 'closing' ) || swappingMode || !items?.length"
          (click)="checkout()">Checkout</button>
      </ng-container>
      <ng-container *ngSwitchCase="'checkout'">
        <button mat-stroked-button color="accent" (click)="cancel()" [disabled]="swappingMode || !items?.length">
          <mat-icon>navigate_before</mat-icon>Back
        </button>
        <button mat-raised-button color="accent"
          [disabled]="(state !== 'open' && state !== 'closing' ) || swappingMode || orderForm.invalid || !items?.length"
          (click)="submit()">Submit
          Order</button>
      </ng-container>

      <ng-container *ngSwitchCase="'ordering'">
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
      </ng-container>
      <ng-container *ngSwitchCase="'confirm'">
        <button mat-stroked-button color="accent" (click)="cancel()" [disabled]="swappingMode">
          <mat-icon>navigate_before</mat-icon>Back
        </button>
        <div>
          Order Submitted
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'ordererror'">
        <button mat-stroked-button color="accent" (click)="cancel()" [disabled]="swappingMode">
          <mat-icon>navigate_before</mat-icon>Back
        </button>
        <div>
          Failed to submit Order
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<div class="scroll-container">
  <ng-container [ngSwitch]="mode">
    <div *ngSwitchDefault class="items">
      <div class="msg-no-items" *ngIf="!items?.length">
        You do not have any items in the cart.
      </div>

      <app-image-card *ngFor="let product of items; trackBy:trackProductBy"
        [imageUrl]="product.imageURL || product.vendor?.logoUrl" [class.no-image]="!product.imageURL"
        (click)="editItem.focus()" class="flat collapsable ">
        <div class="product-details">
          <b>{{product.description}}</b>
          <div class="line">{{product.productLine}}{{product.partNumber}} x{{product.quantity | number}}
          </div>
          <div class="line promo" *ngIf="product.promo">
            {{product.promo}}
          </div>
          <div class="line">
            <span class="price">{{product.tradeshowTotal | currency}}</span>
            <ng-container *ngIf="product.tradeshowPrice !== product.customerPrice">
              <label>&nbsp;was</label>&nbsp;<span class="old-price">{{product.customerTotal | currency}}</span>
            </ng-container>
          </div>
          <div class="line edit-item">
            <app-add-to-cart-button #editItem [product]="product" [vendor]="product.vendor">
            </app-add-to-cart-button>
          </div>
        </div>
      </app-image-card>
      <h3 *ngIf="promoRewards?.length">You Have Qualified For These Promo Add-Ons</h3>
      <app-image-card *ngFor="let product of promoRewards; trackBy:trackProductRewardBy"
        [imageUrl]="product.imageURL || product.vendor?.logoUrl" [class.no-image]="!product.imageURL"
        class="flat promo-reward">
        <div class="product-details">
          <b>{{product.description}}</b>
          <div class="line">{{product.productLine}}{{product.partNumber}} x {{product.quantity | number}}
          </div>
        </div>
      </app-image-card>

    </div>
    <div *ngSwitchCase="'ordering'" class="processing-order">
      Proccessing Your Order...
    </div>
    <div *ngSwitchCase="'confirm'" class="order-info">

      <div class="points-earned">
        <div class="points">{{orderResult.points | number}}</div>
        <div>{{ orderResult.points | i18nPlural:TRADESHOW_BUCKS}} Earned!</div>
      </div>

      <div class="successfull-orders">
        <div>The following orders have been placed:</div>

        <table #summary mat-table [dataSource]="orderResult.ordersSuccess" class="mat-elevation-z8">
          <ng-container matColumnDef="vendor">
            <th mat-header-cell *matHeaderCellDef>Vendor</th>
            <td mat-cell *matCellDef="let order">
              {{order.vendor.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="orderNo">
            <th mat-header-cell *matHeaderCellDef>Order Number</th>
            <td mat-cell *matCellDef="let order">
              {{order.branch}}-{{order.id}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['vendor', 'orderNo']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['vendor', 'orderNo'];"></tr>
        </table>
      </div>
      <div class="failed-orders" *ngIf="orderResult?.ordersFail?.length">
        <h3 class="error">The following orders could not be completed at this time</h3>
        <p>INFO_ON_WHAT_TO_DO</p>
        <div class="errors">
          <ng-container *ngFor="let order of orderResult.ordersFail; let i = index">
            <ng-container *ngTemplateOutlet="OrderErrorMessageTemplate; context: {$implicit: order, index: i}">
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'ordererror'" class="order-errors">
      <h3 class="error">Your request could not be completed at this time</h3>
      <p>INFO_ON_WHAT_TO_DO</p>
      <div class="errors">
        <ng-container *ngFor="let order of orderResult.ordersFail; let i = index">
          <ng-container *ngTemplateOutlet="OrderErrorMessageTemplate; context: {$implicit: order, index: i}">
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="'checkout'" class="order-info">
      <form [formGroup]="orderForm">

        <mat-form-field color="accent">
          <input name="poNumber" [formControl]="poNumberControl" matInput placeholder="Enter PO Number" />
          <mat-error *ngIf="poNumberControl.hasError('required')">Required</mat-error>
        </mat-form-field>

        <mat-form-field color="accent">
          <textarea name="comment" [formControl]="commentControl" matInput placeholder="Enter Comment"></textarea>
        </mat-form-field>

        <ng-container *ngIf="shippingAddresses$ | async as shippingAddresses">
          <mat-form-field color="accent">
            <mat-label *ngIf="shipToAddressControl.value">Ship to</mat-label>
            <mat-label *ngIf="!shipToAddressControl.value">Ship to default address</mat-label>
            <mat-select [formControl]="shipToAddressControl">
               <mat-select-trigger>
                {{ shipToAddressModelToAddressString(shippingAddresses) }}
              </mat-select-trigger>
              <mat-option [value]="undefined">
                <div class="title">Ship to default address</div>
              </mat-option>
              <mat-option *ngFor="let address of shippingAddresses" [value]="address.shippingId">
                <div class="title">{{address.name}}</div>
                <div *ngIf="address.company">{{address.company}}</div>
                <div>{{address.line1}}</div>
                <div *ngIf="address.line2">{{address.line2}}</div>
                <div>{{address.locality}}, {{address.province}} {{address.postalCode}}</div>
                <div>{{address.country}}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </form>
    </div>
  </ng-container>
</div>

<ng-template #OrderErrorMessageTemplate let-order let-i="index">
  <div class="order-error" [attr.index]="i+1">
    <div class="messages">
      <div class="message">
        {{getErrorMessage(order)}}
      </div>
      <div class="reason">
        Reason: {{getErrorReason(order)}}
      </div>
      <div class="num-products">{{ order.products.length | i18nPlural:ITEM_REMAINS_ERROR}}</div>
    </div>
  </div>
</ng-template>
