import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { ITradeShowModel, TradeShowApi, TradeShowFeature } from "../data";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class TradeShowService {
  private _tradeShow$$ = new BehaviorSubject<ITradeShowModel | undefined>(undefined);
  private _loadingId$$ = new BehaviorSubject<number | undefined>(undefined);
  private _tradeShow$ = this._loadingId$$.pipe(
    switchMap(id => this.tradeShowApi.getTradeShow(id)),
    tap((tradeShow) => this._tradeShow$$.next(tradeShow)),
  );

  public readonly currentId$ = this._tradeShow$$.pipe(map(ts => ts?.id));

  public readonly loading$ = combineLatest([this.currentId$.pipe(startWith(undefined)), this._loadingId$$]).pipe(
    map(([currentId, loadingId]) => currentId !== loadingId),
    distinctUntilChanged()
  );

  public tradeShow$ = this._tradeShow$$.asObservable();


  public loadTradeShow(tradeShowId: number | undefined): Observable<ITradeShowModel> {
    this._loadingId$$.next(tradeShowId);
    return this._tradeShow$;
  }

  public get loading(): boolean {
    return this._tradeShow$$.value?.id !== this._loadingId$$.value;
  }

  public get tradeShow(): ITradeShowModel | undefined {
    return this._tradeShow$$.value;
  }

  public get tradeShowId(): number | undefined {
    return this.tradeShow?.id;
  }

  public get tradeShowName(): string | undefined {
    return this.tradeShow?.name;
  }

  public getFeatureById(featureId: number): TradeShowFeature | undefined {
    return this.tradeShow?.siteFeatures.find(feature => feature.id === featureId);
  }

  constructor(
    private readonly tradeShowApi: TradeShowApi,
    //  private readonly authService: AuthenticationService
  ) { }

}
