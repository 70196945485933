import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../service/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'auth-permissionserror',
  templateUrl: './permissionserror.component.html',
  styleUrls: ['./permissionserror.component.scss']
})
export class PermissionserrorComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
  }


  public async changeUser() {
    try {
      await this.authService.logout();
    } catch (e) { }
    this.authService.authenticate(this.route.snapshot.queryParams.for);
  }

}
