import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { AuthenticationService, IUserModel } from '@nfc-authority/angular-auth-core';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RewardsService } from '../../../../sections/rewards/rewards.service';
import { AD_POINTS, ITEMS, TRADESHOW_BUCKS } from '../../../../shared/PluralizeMaps';
import { IRewardPointsModel } from '../../../../data';
import { ShoppingCartService } from '../../shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-button',
  templateUrl: './shopping-cart-button.component.html',
  styleUrls: ['./shopping-cart-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartButtonComponent implements OnDestroy {
  private pointsSub: Subscription;
  private subs = new Subscription();
  AD_POINTS = AD_POINTS;
  TRADESHOW_BUCKS = TRADESHOW_BUCKS;
  ITEMS = ITEMS;
  public points: IRewardPointsModel;


  public isApprentice$ = this.authService.userChange.pipe(
    startWith(this.authService.currentUser),
    map(u => ((u as any)?.properties?.isApprentice ?? false) as boolean),
  )

  constructor(
    public readonly cartService: ShoppingCartService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly rewardsService: RewardsService,
    private readonly authService: AuthenticationService
  ) {
    this.subs.add(cartService.updated.subscribe(() => {
      changeRef.markForCheck();
    }));

    this.subs.add(this.authService.userChange.subscribe((user: IUserModel) => {
      this.unsub();
      if (user?.id) {
        this.pointsSub = rewardsService.points.subscribe(p => {
          this.points = p;
          changeRef.markForCheck();
        });
      }
    }));
  }

  public get neededPoints() {
    return Math.max((this.points?.wishlistPoints || 0) - (this.points?.earned || 0) - (this.cartService?.points || 0), 0);
  }

  private unsub() {
    if (this.pointsSub) {
      this.pointsSub.unsubscribe();
      this.pointsSub = undefined;
    }
  }

  ngOnDestroy() {
    this.unsub();
    this.subs.unsubscribe();
  }

}
