import {
  Component, OnInit, Input, ChangeDetectionStrategy,
  TemplateRef, SecurityContext
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngwc-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements OnInit {
  private _backgroundImage: SafeResourceUrl;

  @Input()
  public heroContentTemplate: TemplateRef<any>;
  @Input()
  public heroBackdropTemplate: TemplateRef<any>;

  @Input()
  public heroTitle: string;
  @Input()
  public heroSubtitle: string;

  @Input()
  public get backgroundImage(): string | SafeResourceUrl {
    return this._backgroundImage;
  }

  public set backgroundImage(value: string | SafeResourceUrl) {
    if (typeof value === 'string') {
      value = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
    this._backgroundImage = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
  }

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

}
