import { Injectable } from '@angular/core';
import { BaseIdentityProvider, IUserModel } from '@nfc-authority/angular-auth-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TradeShowAuthApi } from '../api/trade-show-auth.api';
import { ILoginResponseModel } from '../models/ILoginResponseModel';
import { UserProfile } from '../models/UserProfile';

/**
 * @dynamic
 */
@Injectable({
  providedIn: 'root'
})
export class IdealTradeshowIdentityProvider extends BaseIdentityProvider<IUserModel> {
  private static _profile: UserProfile;

  constructor(private authApi: TradeShowAuthApi) {
    super();
  }

  protected getUser(): Observable<IUserModel> {
    return this.authApi.getCurrentUser().pipe(map<ILoginResponseModel, IUserModel>((usr, index) => {
      IdealTradeshowIdentityProvider._profile = usr as UserProfile;
      const user = {
        id: usr.customerNumber,
        username: usr.username,
        displayName: `${usr.name} (${usr.companyName})`,
        roles: [],
        properties: { ...usr }
      };
      return user;
    }));
  }

  public get profile(): UserProfile {
    return IdealTradeshowIdentityProvider._profile;
  }
}
