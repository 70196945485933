import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPopupData, IPopupResult } from '../../data/index';
import { IPopupOptions } from '../../options/IPopupOptions';


@Component({
    selector: 'ngwc-popup-dialog',
    templateUrl: './popup-dialog.component.html',
    styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent {
    public constructor(
        private dialogRef: MatDialogRef<PopupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPopupData
    ) {

    }

    public get title(): string {
        return this.data.title;
    }

    public get template(): TemplateRef<any> {
        return this.data.template;
    }

    public get model(): any {
        return this.data.model;
    }

    public get options(): IPopupOptions {
        return this.data.options;
    }

    public get buttons() {
        return this.options.buttons;
    }

    public close(button: any) {
        this.dialogRef.close({
            button,
            model: this.model
        } as IPopupResult);
    }

}
