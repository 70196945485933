export interface IUserModel {
    id: string;
    username: string;
    displayName: string;
    roles?: string[];
}

export const ANON_USER: IUserModel = {
    id: null,
    username: null,
    displayName: 'Anonymous',
    roles: []
};
