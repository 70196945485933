import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';

const bpIndecies: { [key: string]: number } = {
  [Breakpoints.XSmall]: 0,
  [Breakpoints.Small]: 1,
  [Breakpoints.Medium]: 2,
  [Breakpoints.Large]: 3,
  [Breakpoints.XLarge]: 4,
};

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  private _bpSubject: BehaviorSubject<string>;

  // XSmall
  public get isXSmall(): boolean {
    return this._bpSubject.value === Breakpoints.XSmall;
  }

  public get isGTXSmall(): boolean {
    const cmp = this.compareBp(Breakpoints.XSmall);
    return cmp === 1;
  }
  public get isGTEXSmall(): boolean {
    const cmp = this.compareBp(Breakpoints.XSmall);
    return cmp === 1 || cmp === 0;
  }

  // Small
  public get isLTSmall(): boolean {
    const cmp = this.compareBp(Breakpoints.Small);
    return cmp === -1;
  }
  public get isLTESmall(): boolean {
    const cmp = this.compareBp(Breakpoints.Small);
    return cmp === -1 || cmp === 0;
  }
  public get isSmall(): boolean {
    return this._bpSubject.value === Breakpoints.Small;
  }
  public get isGTSmall(): boolean {
    const cmp = this.compareBp(Breakpoints.Small);
    return cmp === 1;
  }
  public get isGTESmall(): boolean {
    const cmp = this.compareBp(Breakpoints.Small);
    return cmp === 1 || cmp === 0;
  }

  // Medium
  public get isLTMedium(): boolean {
    const cmp = this.compareBp(Breakpoints.Medium);
    return cmp === -1;
  }
  public get isLTEMedium(): boolean {
    const cmp = this.compareBp(Breakpoints.Medium);
    return cmp === -1 || cmp === 0;
  }
  public get isMedium(): boolean {
    return this._bpSubject.value === Breakpoints.Medium;
  }
  public get isGTMedium(): boolean {
    const cmp = this.compareBp(Breakpoints.Medium);
    return cmp === 1;
  }
  public get isGTEMedium(): boolean {
    const cmp = this.compareBp(Breakpoints.Medium);
    return cmp === 1 || cmp === 0;
  }

  // Large
  public get isLTLarge(): boolean {
    const cmp = this.compareBp(Breakpoints.Large);
    return cmp === -1;
  }
  public get isLTELarge(): boolean {
    const cmp = this.compareBp(Breakpoints.Large);
    return cmp === -1 || cmp === 0;
  }
  public get isLarge(): boolean {
    return this._bpSubject.value === Breakpoints.Large;
  }
  public get isGTLarge(): boolean {
    const cmp = this.compareBp(Breakpoints.Large);
    return cmp === 1;
  }
  public get isGTELarge(): boolean {
    const cmp = this.compareBp(Breakpoints.Large);
    return cmp === 1 || cmp === 0;
  }

  // XLarge
  public get isLTXLarge(): boolean {
    const cmp = this.compareBp(Breakpoints.XLarge);
    return cmp === -1;
  }
  public get isLTEXLarge(): boolean {
    const cmp = this.compareBp(Breakpoints.XLarge);
    return cmp === -1 || cmp === 0;
  }
  public get isXLarge(): boolean {
    return this._bpSubject.value === Breakpoints.XLarge;
  }




  public get change(): Observable<string> {
    return this._bpSubject;
  }

  constructor(private bpObs: BreakpointObserver, private mediaMatcher: MediaMatcher) {
    const breakpoints: string[] = [
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ];
    for (let i = 0; i < breakpoints.length; i++) {
      const bp = breakpoints[i];
      const result = mediaMatcher.matchMedia(bp);
      if (result.matches) {
        this._bpSubject = new BehaviorSubject(bp);
      }
    }
    bpObs.observe(breakpoints).subscribe(result => {
      if (result.matches === true) {
        let match: string = null;
        for (const key in result.breakpoints) {
          if (result.breakpoints.hasOwnProperty(key)) {
            const bp = result.breakpoints[key];
            if (bp === true) {
              if (match) {
                break;
              }
              match = key;
            }
          }
        }
        if (this._bpSubject.value !== match) {
          this._bpSubject.next(match);
        }
      }
    });
  }

  public is(bp: string): boolean {
    switch (bp.toLowerCase()) {
      case 'xsmall':
      case '=xsmall':
        return this.isXSmall;
      case 'gtxsmall':
      case '>xsmall':
        return this.isGTXSmall;
      case 'gtexsmall':
      case '>=xsmall':
        return this.isGTEXSmall;

      case 'ltsmall':
      case '<small':
        return this.isLTSmall;
      case 'ltesmall':
      case '<=small':
        return this.isLTESmall;
      case 'small':
      case '=small':
        return this.isSmall;
      case 'gtsmall':
      case '>small':
        return this.isGTSmall;
      case 'gtesmall':
      case '>=small':
        return this.isGTESmall;

      case 'ltmedium':
      case '<medium':
        return this.isLTMedium;
      case 'ltemedium':
      case '<=medium':
        return this.isLTEMedium;
      case 'medium':
      case '=medium':
        return this.isMedium;
      case 'gtmedium':
      case '>medium':
        return this.isGTMedium;
      case 'gtemedium':
      case '>=medium':
        return this.isGTEMedium;

      case 'ltlarge':
      case '<large':
        return this.isLTLarge;
      case 'ltelarge':
      case '<=large':
        return this.isLTELarge;
      case 'large':
      case '=large':
        return this.isLarge;
      case 'gtlarge':
      case '>large':
        return this.isGTLarge;
      case 'gtelarge':
      case '>=large':
        return this.isGTELarge;

      case 'ltxlarge':
      case '<xlarge':
        return this.isLTXLarge;
      case 'ltexlarge':
      case '<=xlarge':
        return this.isLTEXLarge;
      case 'xlarge':
      case '=xlarge':
        return this.isXLarge;
    }
    return false;
  }

  private compareBp(bp: string): number {
    const curIndx = bpIndecies[this._bpSubject.value];
    const idx = bpIndecies[bp];
    if (idx > curIndx) {
      return -1;
    }
    if (idx < curIndx) {
      return 1;
    }
    return 0;
  }
}
