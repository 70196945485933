import { IUserModel } from './model/IUserModel';
import { InjectionToken, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelablePromise, CancelationToken } from '@nfc-authority/ts-core';
export const IDENTITY_PROVIDER = new InjectionToken<IIdentityProvider<IUserModel>>('Identity Provider');


export interface IIdentityProvider<TUser extends IUserModel> {
    // readonly currentUser: Observable<TUser>;
    readonly currentUser: TUser;
    readonly isLoading: Promise<TUser>;
    readonly userChange: EventEmitter<TUser>;
    logout(): void;
    update(cancelationToken: CancelationToken): CancelablePromise<void>;
}

