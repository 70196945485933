import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@nfc-authority/angular-auth-core';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, first, map, skip, switchMap } from 'rxjs/operators';
import { TradeShowService } from './trade-show.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TradeShowSelectGuard implements CanActivate {
  constructor(
    private readonly tradeShowService: TradeShowService,
    private readonly auth: AuthenticationService,
    private readonly router: Router
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    _: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tradeShowId = next.params.tradeShowId ? +next.params.tradeShowId : undefined;
    if (tradeShowId === undefined || tradeShowId !== this.tradeShowService.tradeShowId) {
      return from(this.auth.isAuthenticated()).pipe(
        filter(isAuthenticated => isAuthenticated),
        first(),
        switchMap(() =>
          this.tradeShowService.loadTradeShow(tradeShowId).pipe(
            map(tradeShow => {
              if (!tradeShow) {
                console.error('TradeShowSelectGuard', 'Trade Show not found');
                return this.router.createUrlTree(['show-not-found'], {
                  queryParams: {
                    id: tradeShowId
                  }
                });
              }
              if (tradeShowId === undefined && tradeShowId !== tradeShow.id) {
                return this.router.createUrlTree([tradeShow.id]);
              }
              return true;
            }),
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (!tradeShowId) {
                  return of(this.router.createUrlTree(['come-back-soon']));
                } else {
                  return of(this.router.createUrlTree(['show-not-found'], {
                    queryParams: {
                      id: tradeShowId
                    }
                  }));
                }
              }
            })
          )
        )
      );
    }
    return true;
  }
}
