import { HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IOrderHistoryListItem } from "../models/IOrderHistoryListItem";
import { IOrderHistoryModel } from "../models/IOrderHistoryModel";
import { IOrderResponseModel } from "../models/IOrderResponseModel";
import { IPromoRequirementMet } from "../models/IPromoRequirementMet";
import { IRewardItem } from "../models/IRewardItem";
import { IRewardPointsModel } from "../models/IRewardPointsModel";
import { ITradeShowModel } from "../models/ITradeShowModel";
import { ITrainingLink } from "../models/ITrainingLink";
import { IVendorListItemModel } from "../models/IVendorListItemModel";
import { IVendorModel } from "../models/IVendorModel";
import { IVendorOrderModel } from "../models/IVendorOrderModel";
import { IVendorProductListItemModel } from "../models/IVendorProductListItemModel";
import { IVendorProductsModel } from "../models/IVendorProductsModel";
import { ApiBase } from "./api-base";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class TradeShowApi extends ApiBase {
  /**
   * Get a list of the vendors for a division.
   * @param division The division to get the vendors for.
   * @return An observable of a list of vendors.
   */
  public getVendorList(division: string): Observable<IVendorListItemModel[]>;

  /**
   * Get a list of the vendors for a division.
   * @param division The division to get the vendors for.
   * @param tradeShowId The trade show to get the vendors for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   */
  public getVendorList(division: string, tradeShowId: number): Observable<IVendorListItemModel[]>;

  /**
   * Get a list of the vendors for a division.
   * @param division The division to get the vendors for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of vendors.
   */
  public getVendorList(division: string, returnResponse: true): Observable<IVendorListItemModel[]>;

  /**
   * Get a list of the vendors for a division.
   * @param division The division to get the vendors for.
   * @param tradeShowId The trade show to get the vendors for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of vendors.
   */
  public getVendorList(division: string, tradeShowId: number, returnResponse: true): Observable<IVendorListItemModel[]>;

  public getVendorList(division: string, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (division === null || division === undefined) {
      throw new Error('Required parameter division was null or undefined when calling getVendorList.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');
    params = this.addToHttpParams(params, division, 'division');

    const path = `vendors`;
    return this.http.request<IVendorListItemModel[]>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get a vendor by id.
   * @param vendorId The id of the vendor to get.
   * @return An observable of a vendor.
   */
  public getVendor(vendorId: number): Observable<IVendorModel>;

  /**
   * Get a vendor by id.
   * @param vendorId The id of the vendor to get.
   * @param tradeShowId The trade show to get the vendor for.
   * @return An observable of a vendor.
   */
  public getVendor(vendorId: number, tradeShowId: number): Observable<IVendorModel>;

  /**
   * Get a vendor by id.
   * @param vendorId The id of the vendor to get.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a vendor.
   */
  public getVendor(vendorId: number, returnResponse: true): Observable<IVendorModel>;

  /**
   * Get a vendor by id.
   * @param vendorId The id of the vendor to get.
   * @param tradeShowId The trade show to get the vendor for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a vendor.
   */
  public getVendor(vendorId: number, tradeShowId: number, returnResponse: true): Observable<IVendorModel>;

  public getVendor(vendorId: number, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (vendorId === null || vendorId === undefined) {
      throw new Error('Required parameter vendorId was null or undefined when calling getVendor.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `vendors/${this.encodePathParam('vendorId', vendorId)}`;
    return this.http.request<IVendorModel>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get a list of products for a vendor.
   * @param vendorId The id of the vendor to get the products for.
   * @return An observable of a list of products.
   */
  public getVendorProductList(vendorId: number): Observable<IVendorProductsModel>;

  /**
   * Get a list of products for a vendor.
   * @param vendorId The id of the vendor to get the products for.
   * @param tradeShowId The trade show to get the products for.
   * @return An observable of a list of products.
   */
  public getVendorProductList(vendorId: number, tradeShowId: number): Observable<IVendorProductsModel>;

  /**
   * Get a list of products for a vendor.
   * @param vendorId The id of the vendor to get the products for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public getVendorProductList(vendorId: number, returnResponse: true): Observable<HttpResponse<IVendorProductsModel>>;

  /** Get a list of products for a vendor.
   * @param vendorId The id of the vendor to get the products for.
   * @param tradeShowId The trade show to get the products for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public getVendorProductList(vendorId: number, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IVendorProductsModel>>;

  public getVendorProductList(vendorId: number, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (vendorId === null || vendorId === undefined) {
      throw new Error('Required parameter vendorId was null or undefined when calling getVendorProductList.');
    }

    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');
    params = this.addToHttpParams(params, vendorId, 'vendor');

    const path = `products`;
    return this.http.request<IVendorProductsModel>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Search for products of a vendor.
   * @param vendorId The id of the vendor to search for products.
   * @param search The search query to use.
   * @return An observable of a list of products.
   */
  public searchVendorProducts(vendorId: number, search: string): Observable<IVendorProductListItemModel[]>;

  /**
   * Search for products of a vendor.
   * @param vendorId The id of the vendor to search for products.
   * @param search The search query to use.
   * @param tradeShowId The trade show to search for products.
   * @return An observable of a list of products.
   */
  public searchVendorProducts(vendorId: number, search: string, tradeShowId: number): Observable<IVendorProductListItemModel[]>;

  /**
   * Search for products of a vendor.
   * @param vendorId The id of the vendor to search for products.
   * @param search The search query to use.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public searchVendorProducts(vendorId: number, search: string, returnResponse: true): Observable<HttpResponse<IVendorProductListItemModel[]>>;

  /**
   * Search for products of a vendor.
   * @param vendorId The id of the vendor to search for products.
   * @param search The search query to use.
   * @param tradeShowId The trade show to search for products.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public searchVendorProducts(vendorId: number, search: string, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IVendorProductListItemModel[]>>;

  public searchVendorProducts(vendorId: number, search: string, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (vendorId === null || vendorId === undefined) {
      throw new Error('Required parameter vendorId was null or undefined when calling searchVendorProducts.');
    }
    if (search === null || search === undefined) {
      throw new Error('Required parameter search was null or undefined when calling searchVendorProducts.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');
    params = this.addToHttpParams(params, vendorId, 'vendor');
    params = this.addToHttpParams(params, search, 'q');

    const path = `products`;
    return this.http.request<IVendorProductsModel>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      responseType: 'json',
      withCredentials: true
    }).pipe(map(_ => _['Found Parts']));
  }

  /**
   * Get a list of trade show rewards. The request is part of a poll.
   * @return An observable of a list of rewards for the current trade show.
   */
  public getRewardsList(): Observable<IRewardItem[]>;

  /**
   * Get a list of trade show rewards.
   * @param isPoll If true, the request is part of a poll.
   * @return An observable of a list of rewards for the current trade show.
   */
  public getRewardsList(isPoll: boolean): Observable<IRewardItem[]>;

  /**
   * Get a list of trade show rewards.
   * @param isPoll If true, the request is part of a poll.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be a list of rewards for the current trade show.
   */
  public getRewardsList(isPoll: boolean, returnResponse: true): Observable<HttpResponse<IRewardItem[]>>;

  /**
   * Get a list of trade show rewards. The request is part of a poll.
   * @param tradeShowId The trade show to get rewards for.
   * @return An observable of a list of rewards for the specified trade show.
   */
  public getRewardsList(tradeShowId: number): Observable<IRewardItem[]>;

  /**
   * Get a list of trade show rewards. The request is part of a poll.
   * @param tradeShowId The trade show to get rewards for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be a list of rewards for the specified trade show.
   */
  public getRewardsList(tradeShowId: number, returnResponse: true): Observable<HttpResponse<IRewardItem[]>>;

  /**
   * Get a list of trade show rewards.
   * @param isPoll If true, the request is part of a poll.
   * @param tradeShowId The trade show to get rewards for.
   * @return An observable of a list of rewards for the specified trade show.
   */
  public getRewardsList(isPoll: boolean, tradeShowId: number): Observable<IRewardItem[]>;

  /**
   * Get a list of trade show rewards marked as a polling request. If no trade show id is provided, the rewards for the current trade show will be returned.
   * @param isPoll If true, the request is part of a poll.
   * @param tradeShowId The trade show to get rewards for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be a list of rewards.
   */
  public getRewardsList(isPoll: boolean, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IRewardItem[]>>;

  public getRewardsList(...rest: [(boolean | number)?, (boolean | number)?, boolean?]): Observable<any> {
    let isPoll: boolean = true;
    let tradeShowId: number | undefined = undefined;
    let returnResponse: boolean = false;

    if (typeof rest[0] === 'boolean') {
      isPoll = rest[0] as boolean;
    } else if (typeof rest[0] === 'number') {
      tradeShowId = rest[0] as number;
    }

    if (typeof rest[1] === 'number') {
      tradeShowId = rest[1] as number;
    } else if (typeof rest[1] === 'boolean') {
      returnResponse = rest[1] as boolean;
    }

    if (typeof rest[2] === 'boolean') {
      returnResponse = rest[2] as boolean;
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');
    params = this.addToHttpParams(params, isPoll, 'ispoll');

    const path = `rewards`;
    return this.http.request<IRewardItem[]>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get the current reward points for the current trade show. The request is part of a poll.
   * @return An observable of the current reward points for the current trade show.
   */
  public getRewardsPoints(): Observable<IRewardPointsModel>;

  /**
   * Get the current reward points for the current trade show.
   * @param isPoll If true, the request is part of a poll.
   * @return An observable of the current reward points for the current trade show.
   */
  public getRewardsPoints(isPoll: boolean): Observable<IRewardPointsModel>;

  /**
   * Get the current reward points for the current trade show.
   * @param isPoll If true, the request is part of a poll.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be the current reward points for the current trade show.
   */
  public getRewardsPoints(isPoll: boolean, returnResponse: true): Observable<HttpResponse<IRewardPointsModel>>;

  /**
   * Get the current reward points for the specified trade show. The request is part of a poll.
   * @param tradeShowId The trade show to get reward points for.
   * @return An observable of the current reward points for the specified trade show.
   */
  public getRewardsPoints(tradeShowId: number): Observable<IRewardPointsModel>;

  /**
   * Get the current reward points for the specified trade show.
   * @param isPoll If true, the request is part of a poll.
   * @param tradeShowId The trade show to get reward points for.
   * @return An observable of the current reward points for the specified trade show.
   */
  public getRewardsPoints(isPoll: boolean, tradeShowId: number): Observable<IRewardPointsModel>;

  /**
   * Get the current reward points for the specified trade show.
   * @param isPoll If true, the request is part of a poll.
   * @param tradeShowId The trade show to get reward points for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   */
  public getRewardsPoints(isPoll: boolean, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IRewardPointsModel>>;

  public getRewardsPoints(...rest: [(boolean | number)?, (boolean | number)?, boolean?]): Observable<any> {

    let isPoll: boolean = true;
    let tradeShowId: number | undefined = undefined;
    let returnResponse: boolean = false;

    if (typeof rest[0] === 'boolean') {
      isPoll = rest[0] as boolean;
    } else if (typeof rest[0] === 'number') {
      tradeShowId = rest[0] as number;
    }

    if (typeof rest[1] === 'number') {
      tradeShowId = rest[1] as number;
    } else if (typeof rest[1] === 'boolean') {
      returnResponse = rest[1] as boolean;
    }

    if (typeof rest[2] === 'boolean') {
      returnResponse = rest[2] as boolean;
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');
    params = this.addToHttpParams(params, isPoll, 'ispoll');

    const path = `rewards/points`;
    return this.http.request<IRewardPointsModel>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get the current reward points for the current trade show.
   * @return An observable of the current reward points for the current trade show.
   */
  public getTrainingLinks(): Observable<ITrainingLink[]>;

  /**
    * Get the current reward points for the specified trade show.
    * @param tradeShowId The trade show to get reward points for.
    * @return An observable of the current reward points for the specified trade show.
   */
  public getTrainingLinks(tradeShowId: number): Observable<ITrainingLink[]>;

  /**
   * Get the current reward points for the current trade show.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be the current reward points for the current trade show.
   */
  public getTrainingLinks(returnResponse: true): Observable<HttpResponse<ITrainingLink[]>>;

  /**
   * Get the current reward points for the specified trade show.
   * @param tradeShowId The trade show to get reward points for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be the current reward points for the specified trade show.
   */
  public getTrainingLinks(tradeShowId: number, returnResponse: true): Observable<HttpResponse<ITrainingLink[]>>;

  public getTrainingLinks(...rest: [(boolean | number)?, boolean?]): Observable<any> {
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `training`;
    return this.http.request<ITrainingLink[]>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Update the user's reward selection for the current trade show.
   * @param rewardItem The reward item to update.
   * @return An observable of a list of rewards for the current trade show.
   */
  public updateRewardSelection(rewardItem: IRewardItem): Observable<IRewardItem[]>;

  /**
   * Update the user's reward selection for the specified trade show.
   * @param rewardItem The reward item to update.
   * @param tradeShowId The trade show to update the reward selection for.
   * @return An observable of a list of rewards for the specified trade show.
   */
  public updateRewardSelection(rewardItem: IRewardItem, tradeShowId: number): Observable<IRewardItem[]>;

  /**
   * Update the user's reward selection for the current trade show.
   * @param rewardItem The reward item to update.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be a list of rewards for the current trade show.
   */
  public updateRewardSelection(rewardItem: IRewardItem, returnResponse: true): Observable<HttpResponse<IRewardItem[]>>;

  /**
   * Update the user's reward selection for the specified trade show.
   * @param rewardItem The reward item to update.
   * @param tradeShowId The trade show to update the reward selection for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return An observable of the full response. The body will be a list of rewards for the specified trade show.
   */
  public updateRewardSelection(rewardItem: IRewardItem, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IRewardItem[]>>;

  public updateRewardSelection(rewardItem: IRewardItem, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (rewardItem === null || rewardItem === undefined) {
      throw new Error('Required parameter rewardItem was null or undefined when calling updateRewardSelection.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `rewards`;
    return this.http.request<IRewardItem[]>('patch', `${this.basePath}/${path}`, {
      params,
      headers,
      body: rewardItem,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Place an order with a specific vendor for the current trade show.
   * @param order The order to place.
   * @return An observable of the order response.
   */
  public placeVendorOrder(order: IVendorOrderModel): Observable<IOrderResponseModel>;

  /**
    * Place an order with a specific vendor for the specified trade show.
    * @param order The order to place.
    * @param tradeShowId The trade show to place the order for.
    * @return An observable of the order response.
   */
  public placeVendorOrder(order: IVendorOrderModel, tradeShowId: number): Observable<IOrderResponseModel>;

  /**
   * Place an order with a specific vendor for the current trade show.
   * @param order The order to place.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the order response.
  */
  public placeVendorOrder(order: IVendorOrderModel, returnResponse: true): Observable<HttpResponse<IOrderResponseModel>>;

  /**
   * Place an order with a specific vendor for the specified trade show.
   * @param order The order to place.
   * @param tradeShowId The trade show to place the order for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the order response.
   */
  public placeVendorOrder(order: IVendorOrderModel, tradeShowId: number, returnResponse: true): Observable<HttpResponse<IOrderResponseModel>>;

  public placeVendorOrder(order: IVendorOrderModel, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (order === null || order === undefined) {
      throw new Error('Required parameter order was null or undefined when calling placeVendorOrder.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `order`;
    return this.http.request<IOrderResponseModel>('post', `${this.basePath}/${path}`, {
      params,
      headers,
      body: order,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get a list of promotional rewards that the user qualifies for, for the current trade show.
   * @param promosMet The list of promotional rewards that the user qualifies for.
   * @return An observable of a list of products.
   */
  public getPromoRewards(promosMet: IPromoRequirementMet[]): Observable<IVendorProductListItemModel[]>;

  /**
   * Get a list of promotional rewards that the user qualifies for, for the specified trade show.
   * @param promosMet The list of promotional rewards that the user qualifies for.
   * @param tradeShowId The trade show to get the promotional rewards for.
   * @return An observable of a list of products.
   */
  public getPromoRewards(promosMet: IPromoRequirementMet[], tradeShowId: number): Observable<IVendorProductListItemModel[]>;

  /**
   * Get a list of promotional rewards that the user qualifies for, for the current trade show.
   * @param promosMet The list of promotional rewards that the user qualifies for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public getPromoRewards(promosMet: IPromoRequirementMet[], returnResponse: true): Observable<HttpResponse<IVendorProductListItemModel[]>>;

  /**
   * Get a list of promotional rewards that the user qualifies for, for the specified trade show.
   * @param promosMet The list of promotional rewards that the user qualifies for.
   * @param tradeShowId The trade show to get the promotional rewards for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of products.
   */
  public getPromoRewards(promosMet: IPromoRequirementMet[], tradeShowId: number, returnResponse: true): Observable<HttpResponse<IVendorProductListItemModel[]>>;

  public getPromoRewards(promosMet: IPromoRequirementMet[], ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (promosMet === null || promosMet === undefined) {
      throw new Error('Required parameter promosMet was null or undefined when calling getPromoRewards.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `/products/promoitems`;
    return this.http.request<IVendorProductListItemModel[]>('post', `${this.basePath}/${path}`, {
      params,
      headers,
      body: promosMet,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get a list of previously placed orders for all trade shows.
   * @return An observable of a list of orders.
   */
  public getOrderHistory(): Observable<IOrderHistoryListItem[]>;

  /**
   * Get a list of previously placed orders for the specified trade show.
   * @param tradeShowId The trade show to get the order history for.
   * @return An observable of a list of orders.
   */

  public getOrderHistory(tradeShowId: number): Observable<IOrderHistoryListItem[]>;

  /**
   * Get a list of previously placed orders for all trade shows.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of orders.
   */
  public getOrderHistory(returnResponse: true): Observable<HttpResponse<IOrderHistoryListItem[]>>;

  /**
   * Get a list of previously placed orders for the specified trade show.
   * @param tradeShowId The trade show to get the order history for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be a list of orders.
   */
  public getOrderHistory(tradeShowId: number, returnResponse: true): Observable<HttpResponse<IOrderHistoryListItem[]>>;

  public getOrderHistory(...rest: [(boolean | number)?, boolean?]): Observable<any> {
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    console.log('getOrderHistory', params);

    const path = `orderHistoryList`;
    return this.http.request<IOrderHistoryListItem[]>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get a specific order.
   * @param orderId The order ID.
   * @return An observable of the order.
   */
  public getOrder(orderId: string): Observable<IOrderHistoryModel>;

  /**
   * Get a specific order.
   * @param orderId The order ID.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the order.
   */
  public getOrder(orderId: string, returnResponse: true): Observable<HttpResponse<IOrderHistoryModel>>;

  public getOrder(orderId: string, returnResponse: boolean = false): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling getOrder.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    const path = `order/${this.encodePathParam('orderId', orderId)}`;
    return this.http.request<IOrderHistoryModel>('get', `${this.basePath}/${path}`, {
      headers: headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get the user's shopping cart for the current trade show.
   * @return An observable of the user's shopping cart.
   */
  public getCart(): Observable<string>;

  /** Get the user's shopping cart for the specified trade show.
   * @param tradeShowId The trade show to get the shopping cart for.
   * @return An observable of the user's shopping cart.
   */
  public getCart(tradeShowId: number): Observable<string>;

  /**
   * Get the user's shopping cart for the current trade show.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the user's shopping cart.
   */
  public getCart(returnResponse: true): Observable<HttpResponse<string>>;

  /**
   * Get the user's shopping cart for the specified trade show.
   * @param tradeShowId The trade show to get the shopping cart for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the user's shopping cart.
   */
  public getCart(tradeShowId: number, returnResponse: true): Observable<HttpResponse<string>>;

  public getCart(...rest: [(boolean | number)?, boolean?]): Observable<any> {
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `cart`;
    return this.http.request<string>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Remove all items from the user's shopping cart for the current trade show.
   * @return An void observable.
   */
  public clearCart(): Observable<void>;

  /**
   * Remove all items from the user's shopping cart for the specified trade show.
   * @param tradeShowId The trade show to clear the shopping cart for.
   * @return An void observable.
   */
  public clearCart(tradeShowId: number): Observable<void>;

  /**
   * Remove all items from the user's shopping cart for the current trade show.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be undefined.
   */
  public clearCart(returnResponse: true): Observable<HttpResponse<void>>;

  /**
   * Remove all items from the user's shopping cart for the specified trade show.
   * @param tradeShowId The trade show to clear the shopping cart for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be undefined.
   */
  public clearCart(tradeShowId: number, returnResponse: true): Observable<HttpResponse<void>>;

  public clearCart(...rest: [(boolean | number)?, boolean?]): Observable<any> {
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `cart`;
    return this.http.request<void>('delete', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Save the user's shopping cart for the current trade show.
   * @param cart The shopping cart to save.
   * @return An void observable.
   */
  public saveCart(cart: string): Observable<void>;

  /**
   * Save the user's shopping cart for the specified trade show.
   * @param cart The shopping cart to save.
   * @param tradeShowId The trade show to save the shopping cart for.
   * @return An void observable.
   */
  public saveCart(cart: string, tradeShowId: number): Observable<void>;

  /**
   * Save the user's shopping cart for the current trade show.
   * @param cart The shopping cart to save.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be undefined.
   */
  public saveCart(cart: string, returnResponse: true): Observable<HttpResponse<void>>;

  /**
   * Save the user's shopping cart for the specified trade show.
   * @param cart The shopping cart to save.
   * @param tradeShowId The trade show to save the shopping cart for.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be undefined.
   */
  public saveCart(cart: string, tradeShowId: number, returnResponse: true): Observable<HttpResponse<void>>;

  public saveCart(cart: string, ...rest: [(boolean | number)?, boolean?]): Observable<any> {
    if (cart === null || cart === undefined) {
      throw new Error('Required parameter cart was null or undefined when calling saveCart.');
    }
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));
    headers = headers.set('Content-Type', this.configuration.selectHeaderContentType(['application/json']));


    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `cart`;
    return this.http.request<void>('post', `${this.basePath}/${path}`, {
      params,
      headers,
      body: cart,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  /**
   * Get the current trade show.
   * @return An observable of the current trade show.
   */
  public getTradeShow(): Observable<ITradeShowModel>;

  /**
   * Get the specified trade show.
   * @param tradeShowId The trade show to get.
   * @return An observable of the specified trade show.
   */
  public getTradeShow(tradeShowId: number): Observable<ITradeShowModel>;

  /**
   * Get the current trade show.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the trade show.
   */
  public getTradeShow(returnResponse: true): Observable<HttpResponse<ITradeShowModel>>;

  /**
   * Get the specified trade show.
   * @param tradeShowId The trade show to get.
   * @param returnResponse Whether to return the full response or just the body. (Default: false)
   * @return  An observable of the full response. The body will be the trade show.
   */
  public getTradeShow(tradeShowId: number, returnResponse: true): Observable<HttpResponse<ITradeShowModel>>;

  public getTradeShow(...rest: [(boolean | number)?, boolean?]): Observable<any> {
    const { tradeShowId, returnResponse } = this._getOptionalArgs(rest);

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', this.configuration.selectHeaderAccept(['application/json']));

    let params = new HttpParams({ encoder: this.encoder });
    params = this.addToHttpParams(params, tradeShowId, 'trade_show_id');

    const path = `show`;
    return this.http.request<ITradeShowModel>('get', `${this.basePath}/${path}`, {
      params,
      headers,
      observe: returnResponse ? 'response' : 'body' as any,
      reportProgress: false,
      responseType: 'json',
      withCredentials: true
    });
  }

  private _getOptionalArgs(rest: [(number | boolean)?, boolean?]) {
    let tradeShowId: number | undefined = undefined;
    let returnResponse: boolean = false;

    if (typeof rest[0] === 'number') {
      tradeShowId = rest[0] as number;
    } else if (typeof rest[0] === 'boolean') {
      returnResponse = rest[0] as boolean;
    }

    if (typeof rest[1] === 'boolean') {
      returnResponse = rest[1] as boolean;
    }
    return { tradeShowId, returnResponse };
  }
}
