import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TradeShowService } from 'src/app/shared/trade-show.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();

  public readonly tradeShow$ = this.tradeShowService.tradeShow$.pipe(
    takeUntil(this._destroyed$)
  );

  public readonly tradeShowFeatures$ = this.tradeShow$.pipe(
    map(tradeShow => tradeShow.siteFeatures),
    takeUntil(this._destroyed$)
  );

  constructor(
    private readonly tradeShowService: TradeShowService
  ) { }

  public ngOnDestroy(): void {
    this._destroyed$.next();

  }
}
