<ngwc-page pageTitle="2020 Virtual Trade Show" [stickyHeader]="true">
  <section *ngIf="tradeShow$ | async as tradeShow">

    <div class="cards">
      <mat-card class="full-width" [routerLink]="['rewards']"
        style="background-image: url(assets/dashboard/rewards.jpg)" *ngIf="tradeShow.showRewards">
        <mat-card-footer> Rewards </mat-card-footer>
      </mat-card>

      <mat-card class="full-width" [routerLink]="['training']"
        style="background-image: url(assets/dashboard/training.jpg)" *ngIf=" tradeShow.showTraining">
        <mat-card-footer> Training </mat-card-footer>
      </mat-card>

      <mat-card *ngFor="let feature of tradeShowFeatures$ | async" [class.full-width]="feature.fullWidth"
        [routerLink]="['vendors', feature.id]" [style.backgroundImage]="'url(' + feature.image + ')'">
        <mat-card-footer> {{ feature.name }} </mat-card-footer>
      </mat-card>

    </div>
  </section>
</ngwc-page>
