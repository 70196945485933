import { Component, Input, SimpleChanges, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ILoginConfig, ILoginAuthenticationProvider } from '../../../../../provider/authentication/IAuthenicationProvider';
import { IAuthenticatedUser, ISimpleLoginCredentials } from '../../../../../provider/authentication/model/index';
import { FormGroup, NgForm, } from '@angular/forms';
import { OnChanges, AfterViewInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'auth-simple-login-form',
  templateUrl: './simple-login-form.component.html',
  styleUrls: ['./simple-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleLoginFormComponent implements OnChanges, AfterViewInit {


  public hidePassword = true;
  public inProgress: boolean = false;
  public errorMessage: string;

  public errorDetail: string;

  public model: ISimpleLoginCredentials = {
    username: '',
    password: ''
  };
  @ViewChild('usernameField', { read: ElementRef, static: true })
  private usernameField: ElementRef<HTMLInputElement>;
  @ViewChild('loginForm', { read: NgForm, static: true })
  private loginForm: NgForm;

  @Input()
  public provider: ILoginAuthenticationProvider<IAuthenticatedUser, ISimpleLoginCredentials>;


  public get config(): ILoginConfig {
    return this.provider?.config;
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public onSubmit(form: FormGroup) {
    console.log(form);
    this.inProgress = true;
    this.errorMessage = '';
    this.errorDetail = '';
    this.provider.login({
      username: this.model.username,
      password: this.model.password
    }).then(() => {

    }).catch(e => {
      console.log('Login form error', e);
      this.inProgress = false;
      if (e instanceof HttpErrorResponse) {
        switch (e.status) {
          case 401:
            this.errorMessage = 'Invalid Username or Password';
            break;
          default:
            this.errorMessage = 'Unknown Server Response: ' + e.statusText;
            this.errorDetail = e.message;
            break;
        }
      } else if (e instanceof Error) {
        this.errorMessage = 'Unknown Error: ' + e.message;
        this.errorDetail = e.stack;
      } else {
        this.errorMessage = 'Unknown Error';
      }
      this.changeDetectorRef.markForCheck();
    });
    this.model.password = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.provider) {
      this.reset();
    }
  }

  ngAfterViewInit(): void {
    this.focusUsername();
  }

  private reset() {
    this.model = {
      username: '',
      password: ''
    };
    this.hidePassword = true;
    this.inProgress = false;
    this.errorMessage = '';
    this.errorDetail = '';
    this.loginForm.resetForm(this.model);
    setTimeout(() => {
      this.loginForm.resetForm(this.model);
      this.focusUsername();
    }, 1);
  }
  private focusUsername(): void {
    this.usernameField?.nativeElement.focus();
    setTimeout(() => {
      this.usernameField?.nativeElement.focus();
    }, 1);
  }
}
