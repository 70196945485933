import { IIdentityProvider } from '../../IIdentityProvider';
import { IUserModel } from '../../model/index';
import { InMemoryAuthenticationProvider } from '../../../authentication/built-in/in-memory/index';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseIdentityProvider } from '../../BaseIdentityProvider';

@Injectable()
export class InMemoryIdentityProvider extends BaseIdentityProvider<IUserModel> {

    constructor(
        private authenticationProvider: InMemoryAuthenticationProvider
    ) {
        super();
    }

    protected getUser(): Observable<IUserModel> {
       return of(<IUserModel>this.authenticationProvider.authenticatedUser);
    }

}
