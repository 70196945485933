import { fromDescriptor, fromProperty, ConverterFunction } from '../decorators/util';
import { SimpleStringTypeConverter } from './SimpleStringTypeConverter';

export function BooleanValue(defaultValue?: boolean): PropertyDecorator {
    return function (target, prop, desc?) {
        if (desc) {
            return fromDescriptor(desc, SimpleStringTypeConverter.toBoolean, defaultValue);
        } else {
            return fromProperty(SimpleStringTypeConverter.toBoolean, defaultValue);
        }
    };
}


export function NumberValue(defaultValue?: number): PropertyDecorator {
    return function (target, prop, desc?) {
        if (desc) {
            return fromDescriptor(desc, SimpleStringTypeConverter.toNumber, defaultValue);
        } else {
            return fromProperty(SimpleStringTypeConverter.toNumber, defaultValue);
        }
    };
}

export function IntegerValue(defaultValue?: number): PropertyDecorator {
    return function (target, prop, desc?) {
        if (desc) {
            return fromDescriptor(desc, SimpleStringTypeConverter.toInteger, defaultValue);
        } else {
            return fromProperty(SimpleStringTypeConverter.toInteger, defaultValue);
        }
    };
}

function lookUpFn(lookupObject: object): ConverterFunction<string, any> {
    return (key: string) => {
        if (typeof key === 'string') {
            return lookupObject[key];
        }
        return key;
    };
}

export function EnumValue(enumType: any, defaultValue?: any): PropertyDecorator {
    return function (target, prop, desc?) {
        if (desc) {
            return fromDescriptor(desc, lookUpFn(enumType), defaultValue);
        } else {
            return fromProperty(lookUpFn(enumType), defaultValue);
        }
    };
}


