import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { Configuration } from "./configuration";
import { CustomHttpParameterCodec } from "./encoder";
import { BASE_PATH } from "./injection-tokens";

@Injectable()
export abstract class ApiBase {
  protected basePath = 'https://webservices-dev.idealsupply.com/vendor/etradeshow';
  protected defaultHeaders = new HttpHeaders();
  protected configuration = new Configuration();
  protected encoder: any = {};

  constructor(
    protected http: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }

    if (typeof this.configuration.basePath === 'string') {
      this.basePath = this.configuration.basePath;
    }

    if(typeof basePath === 'string') {
      this.basePath = basePath;
    }

    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  // @ts-ignore
  protected addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  protected addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  protected encodePathParam(name: string, value: string | number | boolean | Date) {
    return this.configuration.encodeParam({
      name,
      value,
      in: "path",
      style: "simple",
      explode: false,
      dataType: typeof value,
      dataFormat: undefined
    });

  }
}
