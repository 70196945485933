<div *ngIf="!hasCartItem">
  <button mat-raised-button (click)="setCartItemQuantity(1, true)" color="accent">Add to Cart</button>
</div>
<div *ngIf="hasCartItem">
  <div *ngIf="(cartItem?.cartonQty || 0) > 1">Carton Quantity: {{cartItem?.cartonQty | number}}</div>
  <mat-form-field appearance="outline" color="accent">
    <input #q matInput type="text" [value]="cartItem?.quantity || 0" (change)="setCartItemQuantity(+q.value)" />
    <div matSuffix *ngIf="effectiveSellMultiple > 1"> x {{ effectiveSellMultiple | number }}</div>
    <mat-hint>{{((effectiveSellMultiple) * (+q.value || 0)) | number }} total</mat-hint>
  </mat-form-field>
  <button mat-button (click)="setCartItemQuantity(0)" color="warn">Delete</button>
</div>
