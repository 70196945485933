import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot,
  RouterStateSnapshot, CanLoad, CanActivateChild,
  Route, Router, UrlTree, UrlSegment
} from '@angular/router';
import { IsAuthenticatedGuard } from './is-authenticated.guard';
import { AuthenticationService } from '../service/index';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(
    private isAuthenticatedGuard: IsAuthenticatedGuard,
    private authService: AuthenticationService,
    private router: Router
  ) {

  }
  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return await this.authenticate(await this.isAuthenticatedGuard.canActivate(next, state), '/' + next.url.toString());
  }

  public async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return await this.authenticate(await this.isAuthenticatedGuard.canActivateChild(childRoute, state), '/' + childRoute.url.toString());
  }

  public async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const res = await this.authenticate(await this.isAuthenticatedGuard.canLoad(route), '/' + segments.join('/'));
    if (res instanceof UrlTree) {
      this.router.navigateByUrl(res.toString());
      return false;
    } else {
      return res;
    }
  }

  private async authenticate(isAuth: boolean, redirect: string): Promise<boolean | UrlTree> {
    return isAuth || this.router.parseUrl('/login?redirect=' + redirect);
  }

}
