import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from '@nfc-authority/angular-auth-core';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { DashboardModule } from './sections/dashboard/dashboard.module';
import { TradeShowSelectGuard } from './shared/trade-show-select.guard';


const routes: Routes = [
  {
    path: 'login',
    canLoad: [NoAuthGuard],
    loadChildren: () =>
      import('./sections/auth/authentication.module').then(
        (m) => m.AuthenticationFeatureModule
      ),
  },
  {
    path: 'orders',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./sections/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'permissionserror',
    loadChildren: () =>
      import('./sections/auth/authorization.module').then(
        (m) => m.AuthorizationFeatureModule
      ),
  },
  {
    path: 'come-back-soon',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./sections/no-show/no-show.module').then(
        (m) => m.NoShowModule
      ),
  },
  {
    path: 'show-not-found',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./sections/no-show/no-show.module').then(
        (m) => m.NoShowModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard, TradeShowSelectGuard],
        pathMatch: 'full',
        children: []
      },
      {
        path: ':tradeShowId',
        canActivate: [AuthGuard, TradeShowSelectGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: DashboardComponent,
          },
          {
            path: 'rewards',
            loadChildren: () =>
              import('./sections/rewards/rewards.module').then((m) => m.RewardsModule),
          },
          {
            path: 'training',
            loadChildren: () =>
              import('./sections/training/training.module').then(
                (m) => m.TrainingModule
              ),
          },
          {
            path: 'vendors',
            loadChildren: () =>
              import('./sections/vendors/vendors.module').then(
                (m) => m.VendorsModule
              ),
          }
        ]
      },
    ],
  },


  // children: [


  // ]

  // {
  //   path: 'login',
  //   canLoad: [NoAuthGuard],
  //   loadChildren: () =>
  //     import('./sections/auth/authentication.module').then(
  //       (m) => m.AuthenticationFeatureModule
  //     ),
  // },
  // {
  //   path: 'permissionserror',
  //   loadChildren: () =>
  //     import('./sections/auth/authorization.module').then(
  //       (m) => m.AuthorizationFeatureModule
  //     ),
  // },
  // {
  //   path: '',
  //   redirectTo: getRedirect(),
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'rewards',
  //   canLoad: [AuthGuard],
  //   loadChildren: () =>
  //     import('./sections/rewards/rewards.module').then((m) => m.RewardsModule),
  // },
  // {
  //   path: 'vendors',
  //   canLoad: [AuthGuard],
  //   loadChildren: () =>
  //     import('./sections/vendors/vendors.module').then((m) => m.VendorsModule),
  // },
  // {
  //   path: 'training',
  //   canLoad: [AuthGuard],
  //   loadChildren: () =>
  //     import('./sections/training/training.module').then(
  //       (m) => m.TrainingModule
  //     ),
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'ignore',
      urlUpdateStrategy: 'eager',
      scrollOffset: [0, 400],
      enableTracing: !environment.production,
    }),
    DashboardModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
