import { ValueProvider } from '@angular/core';

import { BASE_PATH } from '../app/data';
import { BASE_URL as CHAT_BASE_URL, GLOBAL_TOKEN } from '../app/shared/chatwoot/chatwoot.service';

export const environment = {
  production: true,
  trackingId: 3,
  showTradeshowTimingMockButton: false,
  appProviders: [
    {
      provide: BASE_PATH,
      useValue: 'https://webservices.idealsupply.com/vendor/etradeshow'
    } as ValueProvider,
    {
      provide: BASE_PATH,
      useValue: 'https://webservices.idealsupply.com/vendor/etradeshow'
    } as ValueProvider,
    {
      provide: CHAT_BASE_URL,
      useValue: 'https://chat.idealsupply.com'
    } as ValueProvider,
    {
      provide: GLOBAL_TOKEN,
      useValue: 'vFrBKS7cgiFtXroMEvwEgSc5'
    } as ValueProvider,
  ]
};



