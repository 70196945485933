<ngwc-web-site #site logo="assets/logos/logo.svg" layout="app" menu="vertical" [stickyHeader]="true"
  [stickyFooter]="true" [showHeader]="true" [showFooter]="true" [rightMenuMode]="1"
  [rightMenuButtonTemplate]="RightMenuButtonTemplate">

  <div nav>
    <app-shopping-cart-item-list></app-shopping-cart-item-list>
  </div>

  <div header='right'>
    <ng-container *ngIf="userProfile$ | async as user">
      <button class="user-menu-button" mat-button *ngIf="user" xclickx="showUserPopup = !showUserPopup">
        {{user.name}}
        <mat-icon>keyboard_arrow_down</mat-icon>
        <mat-card #popup xngIf="showUserPopup" (click)="$event.stopImmediatePropagation()">

          <mat-card-header>
            <mat-icon mat-card-avatar>account_circle</mat-icon>
            <mat-card-title-group>
              <mat-card-subtitle>
                <div>{{user.name}}</div>
              </mat-card-subtitle>
            </mat-card-title-group>
          </mat-card-header>

          <mat-card-content>
            <div>{{user.companyName}}</div>
            <div class="test-buttons" *ngIf="showDebugTools">
              <button mat-stroked-button (click)="debugUnlockTimings()">Unlock</button>
              <button mat-stroked-button (click)="debugSetMockTimings()">Test Timer</button>
            </div>
          </mat-card-content>

          <mat-card-actions>
            <button mat-stroked-button color="warn" (click)="logout()">Logout</button>
          </mat-card-actions>

        </mat-card>
      </button>
    </ng-container>
  </div>
</ngwc-web-site>
<button class="chat-btn" color="accent" mat-raised-button (click)="toggleChat()">Chat with {{ chatTitle }}</button>

<ng-template #RightMenuButtonTemplate let-menu>
  <div menuButtons>
    <ng-container *ngIf="userProfile$ | async as user">
      <button mat-stroked-button routerLink="/orders" *ngIf="!user.isApprentice">Order History</button>
    </ng-container>
    <app-shopping-cart-button (click)="menu.toggleMenu()" [class.open]="site.menuRightOpen">
    </app-shopping-cart-button>
  </div>
</ng-template>
