import { IAlertsOptions } from './IAlertsOptions';

// tslint:disable-next-line:no-empty-interface
export interface IAlertOptions extends IAlertsOptions {
}

export const DefaultAlertOptions: IAlertOptions = {
    buttons: [{ id: undefined, label: 'OK', color: 'primary', ariaLabel: 'Ok' }],
    disableClose: true
};
