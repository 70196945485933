import { trigger, animate, style, group, animateChild, query, stagger, transition, AnimationTriggerMetadata } from '@angular/animations';


export const websiteStateTransition: AnimationTriggerMetadata = trigger('stateTransition', [
    transition('* => void', [
        style({ opacity: 1 }),
        animate('0.25s ease-out', style({ opacity: 0 }))
    ]),
    transition('void => *', [
        style({ opacity: 0 }),
        animate('0.25s  ease-in', style({ opacity: 1 }))
    ])
]);
