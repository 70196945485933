import { Injectable } from '@angular/core';
import { StickyDirective, StickyLocation } from './sticky.directive';
import { Subscription } from 'rxjs';
import * as linq from 'linq';


@Injectable({
  providedIn: 'root'
})
export class StickyService {
  private stickiesByLocation: Map<StickyLocation, StickyDirective[]> = new Map();
  private stickyLocation: Map<StickyDirective, StickyLocation> = new Map();
  private stickySubs: Map<StickyDirective, Subscription[]> = new Map();
  private stickiesByLocationByIndex: Map<StickyLocation, StickyDirective[][]> = new Map();

  constructor() { }

  public addSticky(sticky: StickyDirective) {
    let locStickies = this.stickiesByLocation.get(sticky.ngwcSticky);
    const listeners: Subscription[] = [];
    this.stickySubs.set(sticky, listeners);
    this.stickyLocation.set(sticky, sticky.ngwcSticky);
    if (!Array.isArray(locStickies)) {
      locStickies = [];
      this.stickiesByLocation.set(sticky.ngwcSticky, locStickies);
    }
    locStickies.push(sticky);
    const locationChange = sticky.ngwcStickyChange.subscribe(() => {
      this.removeSticky(sticky);
      this.addSticky(sticky);
    });
    listeners.push(locationChange);

    const sizeChange = sticky.stickySizeChange.subscribe(() => {
      this.applyPositions(sticky.ngwcSticky);
    });
    listeners.push(sizeChange);

    const indexChange = sticky.stickyIndexChange.subscribe(() => {
      this.sortStickyLocation(sticky.ngwcSticky);
      this.applyPositions(sticky.ngwcSticky);
    });
    listeners.push(indexChange);

    const offsetChange = sticky.stickyOffsetChange.subscribe(() => {
      this.applyPositions(sticky.ngwcSticky);
    });
    listeners.push(offsetChange);

    this.sortStickyLocation(sticky.ngwcSticky);
    this.applyPositions(sticky.ngwcSticky);
  }

  public removeSticky(sticky: StickyDirective) {
    const listeners = this.stickySubs.get(sticky);
    if (listeners) {
      for (let i = 0; i < listeners.length; i++) {
        listeners[i].unsubscribe();
      }
    }
    this.stickySubs.set(sticky, []);

    const loc = this.stickyLocation.get(sticky);
    this.stickyLocation.delete(sticky);
    const locStickies = this.stickiesByLocation.get(loc);
    if (locStickies) {
      const idx = locStickies.indexOf(sticky);
      if (idx !== -1) {
        locStickies.splice(idx, 1);
      }
    }
  }

  private sortStickyLocation(loc: StickyLocation): void {
    if (loc === StickyLocation.NONE) {
      return;
    }
    const locStickies = this.stickiesByLocation.get(loc);

    locStickies.sort((a, b) => {
      const aIdx = a.stickyIndex || 0;
      const bIdx = b.stickyIndex || 0;
      if (aIdx > bIdx) {
        return 1;
      }
      if (aIdx < bIdx) {
        return -1;
      }
      return 0;
    });


    const sorted = linq.from(locStickies)
      .groupBy(_ => _.stickyIndex, _ => _, (k, e) => e.toArray())
      .orderBy(_ => _[0].stickyIndex)
      .toArray();
    this.stickiesByLocationByIndex.set(loc, sorted);
  }

  private applyPositions(loc: StickyLocation) {
    if (loc === StickyLocation.NONE) {
      return;
    }

    const locStickies = this.stickiesByLocationByIndex.get(loc);
    let sum = 0;

    for (let i = 0; i < locStickies.length; i++) {
      const indexSet = locStickies[i];
      let indexMaxSize = 0;
      switch (loc) {
        case StickyLocation.TOP:
        case StickyLocation.BOTTOM:
          indexMaxSize = linq.from(indexSet).max(_ => _.size.height + _.stickyOffset);
          break;
        case StickyLocation.LEFT:
        case StickyLocation.RIGHT:
          indexMaxSize = linq.from(indexSet).max(_ => _.size.width + _.stickyOffset);
          break;
      }
      for (let j = 0; j < indexSet.length; j++) {
        const sticky = indexSet[j];
        sticky.applyStickyValue(sum + sticky.stickyOffset);
      }
      sum += indexMaxSize;
    }
  }
}
