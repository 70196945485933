import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService, IUserModel } from '@nfc-authority/angular-auth-core';
import { WebSiteComponent } from '@nfc-authority/angular-web-components';
import logRocket from 'logrocket';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IdealTradeshowIdentityProvider } from './data';
import { ChatWindowRef, ChatwootService } from './shared/chatwoot/chatwoot.service';
import { TradeshowHoursService } from './shared/tradeshow-hours.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('site', { static: true })
  private _website: WebSiteComponent;
  private _chatWindowRef: ChatWindowRef;


  public readonly userProfile$ = this._authService.userChange.pipe(
    map(user => user ? this._identity.profile : undefined)
  );

  constructor(
    private readonly _router: Router,
    private readonly _changeRef: ChangeDetectorRef,
    private readonly _authService: AuthenticationService,
    private readonly _identity: IdealTradeshowIdentityProvider,
    private readonly _matomoInjector: MatomoInjector,
    private readonly _matomoTracker: MatomoTracker,
    private readonly _chatwootService: ChatwootService,
    private readonly _tradeShowHoursService: TradeshowHoursService
  ) { }

  public ngOnInit(): void {
    this._initializeTrackers();
    this._initializeChatwoot();
    this._initializeMenu();
  }

  private _initializeTrackers(): void {
    this._initializeTrackerMatomo();
    this._initializeTrackerLogRocket();
  }

  private _initializeTrackerLogRocket(): void {
    logRocket.init('3d2wzn/tradeshow', {
      disableBusyFramesTracker: true,
      uploadTimeInterval: 20000,
    });
    this._authService.userChange.pipe(distinctUntilChanged((a, b) => a.username === b.username)).subscribe((user: IUserModel & { properties: { [key: string]: unknown } }) => {
      logRocket.startNewSession();
      if (user?.id) {
        logRocket.identify(user.username, {
          account: user.id,
          companyName: user.properties.companyName as string,
          email: user.properties.email as string,
          branch: user.properties.branch as number,
          isAdEligible: user.properties.isAdEligible as boolean,
          isApprentice: user.properties.isApprentice as boolean,
        });
      }
    });
  }
  private _initializeTrackerMatomo(): void {
    this._matomoInjector.init('https://piwik.idealsupply.com/piwik/', environment.trackingId);
    this._matomoTracker.enableHeartBeatTimer(60);
    this._authService.userChange.pipe(distinctUntilChanged((a, b) => a.username === b.username)).subscribe((user) => {
      if (user?.id) {
        this._matomoTracker.setUserId(user.username);
      } else {
        this._matomoTracker.resetUserId();
      }
    });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._matomoTracker.setCustomUrl(event.urlAfterRedirects);
        this._matomoTracker.setDocumentTitle(this._website.siteTitle);
        this._matomoTracker.enableLinkTracking(true);
        this._matomoTracker.trackPageView();
      }
    });
  }

  private _initializeChatwoot(): void {
    this._chatwootService.currentChat.subscribe(c => {
      this._chatWindowRef = c;
      this._changeRef.markForCheck();
    });
  }

  private _initializeMenu(): void {

  }

  public async logout() {
    await this._authService.logout();
    const asdf = this._router.url
    window.location.href = window.location.href;
    //setTimeout(() => {
    // this._authService.authenticate("/");

    //this._router.navigate(['/']);
    //}, 1000);

  }

  public toggleChat(): void {
    this._chatWindowRef.toggle();
  }

  public get chatTitle(): string {
    return this._chatWindowRef?.title || 'Ideal Supply';
  }

  public get showDebugTools(): boolean {
    return environment.production !== true;
  }

  public debugUnlockTimings() {
    this._tradeShowHoursService.unlockTimings();
  }

  public debugSetMockTimings() {
    this._tradeShowHoursService.setMockTimings();
  }

}
